import axios from 'axios';
import { isArray, has, get } from 'lodash';
import qs from 'qs';
import { select } from 'redux-saga/effects';
import { getAccessToken } from 'store/selectors/selectors';

/**
 * Configure and export an axios instance.
 *
 * @param {object} settings - additional header options like Bearer Token
 * @exports AxiosInstance
 */
export default (settings?: Record<string, string>) => {
  const headers: Record<string, string> = {
    'content-type': 'application/json',
  };

  if (settings && settings.token)
    headers.Authorization = `Bearer ${settings.token}`;

  return axios.create({
    baseURL: globalThis.config.API_MAIN,
    headers,
  });
};

interface RequestArguments {
  (
    endpoint: string,
    data?: object,
    options?: {
      header?: object;
      query?: object;
      formData?: boolean;
    }
  ): Generator;
}

export const request: RequestArguments = function*(
  endpoint,
  data = {},
  options = { header: {}, query: {}, formData: false }
) {
  const source = axios.CancelToken.source();
  const meta: string|any = yield select(store => store.endpoints[endpoint]);
  const token = yield select(getAccessToken);

  const header = get(options, 'header', {});
  const query = get(options, 'query', {});
  const formData = get(options, 'formData', false);

  if (meta === undefined) throw Error('Endpoint does not exist.');

  const factory = axios.create({
    baseURL: globalThis.config.API_MAIN,
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      ...header,
    },
    cancelToken: source.token,
  });

  // only works on url params
  const matches = meta.path.match(/[^{\}]+(?=})/g);
  const dependencies = isArray(matches) ? matches.length : 0;
  let url = meta.path.replace(/^\/v[0-9]\//gm, '');

  // error handling
  if (dependencies > 0) {
    if (Object.keys(data).length < dependencies) {
      throw Error(
        `${meta.request_method} ${meta.path} needs at least ${dependencies} parameters`
      );
    }

    const hasKeys = matches.every(match => has(data, match));
    if (!hasKeys) {
      throw Error(
        `${meta.request_method} ${
          meta.path
        } you did not provide the correct parameters: ${matches.join(', ')}`
      );
    }

    // apply dependencies to url, and remove from data object, after that, add as query params.
    matches.forEach(match => {
      const reg = RegExp(`{${match}}`, 'gm');
      url = url.replace(reg, data[match]);
      delete data[match];
    });
  }

  if (Object.keys(query).length > 0) {
    url = `${url}?${qs.stringify(query)}`;
  }

  if (formData === true) {
    const formData = new FormData();
    for (const prop in data) {
      formData.set(prop, data[prop]);
    }
    data = formData; // overwrite with serialized form data
  }

  switch (meta.request_method) {
    case 'GET':
      return [factory.get(url), source.cancel];
    case 'POST':
      return [factory.post(url, data), source.cancel];
    case 'PUT':
      return [factory.put(url, data), source.cancel];
    case 'DELETE':
      return [factory.delete(url), source.cancel];
  }
};
