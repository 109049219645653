import React, { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import {
  StyledNavMain,
  StyledLogo,
  StyledItem,
} from 'components/ui/NavBar/styles/NavBarStyles';
import { useFlowIsActive, useGuard } from 'utils/hooks';
import { showNotification } from 'store/notification/actions';

/**
 * Navigation component
 *
 * @returns {ReactNodeArray} StyledNav and navigation entries
 */
const NavMain: FC<{}> = () => {
  const [canSeeDatapoints] = useGuard('GET_PROJECT_DATAPOINTS');
  const projectIDs = useSelector(state =>
    Object.keys(get(state, 'projects.user', []))
  );
  const canSeeDatapointsForAProject = projectIDs.some(id =>
    canSeeDatapoints(id)
  );
  const { t } = useTranslation();
  const isFlowActive = useFlowIsActive();
  const dispatch = useDispatch();
  const handleClick = e => {
    if (isFlowActive) {
      e.preventDefault();
      dispatch(
        showNotification({
          identifier: 'in-flow-action-warning',
          type: 'warning',
          text: `${t('common:flows.in_flow_warning')}`,
          autoClose: 3000,
        })
      );
    }
    return isFlowActive ? e.preventDefault() : null;
  };

  return (
    <StyledNavMain>
      <Link to="/">
        <StyledLogo src="assets/images/aedifion-logo-small.svg" />
      </Link>
      <StyledItem>
        <NavLink exact={true} to={'/'} onClick={handleClick}>
          <FontAwesomeIcon icon={['fal', 'home']} size={'2x'} fixedWidth />
        </NavLink>
      </StyledItem>
      <StyledItem>
        <NavLink to={'/companies'} onClick={handleClick}>
          <FontAwesomeIcon icon={['fal', 'building']} size={'2x'} fixedWidth />
        </NavLink>
      </StyledItem>
      <StyledItem>
        <NavLink to={'/projects'} onClick={handleClick}>
          <FontAwesomeIcon icon={['fal', 'chart-bar']} size={'2x'} fixedWidth />
        </NavLink>
      </StyledItem>
      <StyledItem>
        <NavLink to={'/users'} onClick={handleClick}>
          <FontAwesomeIcon icon={faUser} size={'2x'} fixedWidth />
        </NavLink>
      </StyledItem>
      {/*{canSeeDatapointsForAProject && (
        <StyledItem>
          <NavLink exact={true} to={'/io'}>
            <FontAwesomeIcon
              icon={['fal', 'chart-bar']}
              size={'2x'}
              fixedWidth
            />
          </NavLink>
        </StyledItem>
      )}*/}
    </StyledNavMain>
  );
};

export default NavMain;
