export const CREATE_INITIALIZER = 'CREATE_INITIALIZER'
export const CREATE_INITIALIZER_PENDING = 'CREATE_INITIALIZER_PENDING'
export const CREATE_INITIALIZER_FULFILLED = 'CREATE_INITIALIZER_FULFILLED'
export const CREATE_INITIALIZER_REJECTED = 'CREATE_INITIALIZER_REJECTED'

export enum INITIALIZERS {
    INITIALIZE_ROLES = 'initialize_roles',
    SYNCHRONIZE_DATAPOINTS_WITH_INFLUX = 'synchronize_datapoints_with_influx',
    INITIALIZE_DATAPOINT_HASH_IDS = 'initialize_datapoint_hash_ids',
    INITIALIZE_ANALYTICS = 'initialize_analytics',
    INITIALIZE_CLASSIFICATION = 'start_classification',
}

/**
 * Types
 */
export type TInitializationResponse = {
    message: string;
    operation?: string;
    success?: boolean;
}

/*
 * State Models
 * */
export interface InitializerState {
    readonly loading: boolean;
    readonly initializationResponse?: TInitializationResponse;
  }

/**
 * Actions
 */

export interface CreateInitializerAction {
    type: typeof CREATE_INITIALIZER;
    resource: {
        initializer: INITIALIZERS,
        company_id?: number,
        project_id?: number,
    };
}

export interface CreateInitializerPendingAction {
    type: typeof CREATE_INITIALIZER_PENDING;
    loading: boolean;
}

interface CreateInitializerFulfillAction {
    type: typeof CREATE_INITIALIZER_FULFILLED;
    response: TInitializationResponse;
}

/**
 * Export action types
 */

export type InitializerActionTypes =
    | CreateInitializerAction
    | CreateInitializerPendingAction
    | CreateInitializerFulfillAction;