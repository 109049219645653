import {
  ADProjectsActionTypes,
  QUICK_CREATE_PROJECT,
} from 'store/adprojects/types';
import * as H from 'history';

export const redirectToProjectCreation = (his: H.History): ADProjectsActionTypes => ({
  type: QUICK_CREATE_PROJECT,
  data: {
    history: his,
  }
});
