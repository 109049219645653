import React, { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { reduce } from 'lodash';
import { ModalBody, ModalFooter, ModalHeader } from 'components/ui/Modal/Modal';
import Field, {
  FieldRow,
  FormSeperator,
  StyledReduxFormSelect,
} from 'components/ui/Form/Form';
import { Button, Variant } from 'components/ui/Button/Button';
import { useSelector } from 'react-redux';
import { StateType } from 'store/types';
import { useIsEqualForm, useLoading } from 'utils/hooks';
import { CREATE_PROJECT, SAVE_PROJECT } from 'store/project/types';

// -- TODO --
// Remove the key index signature type. It´s just there to prevent an failure with the
// validate utils function
interface ProjectFormData {
  name: string;
  description?: string;
  [key: string]: string | number;
}

interface ProjectFormProps {
  isEdit?: boolean;
}

type CombinedProjectFormProps = ProjectFormProps &
  InjectedFormProps<ProjectFormData, ProjectFormProps>;

const ProjectForm: FC<CombinedProjectFormProps> = ({
  submitting,
  pristine,
  reset,
  handleSubmit,
  isEdit = false,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state: StateType) => state.user.details);
  const { users } = useSelector((state: StateType) => state.users);
  const form = useSelector((state: StateType) => state.form['project-create']);
  pristine = useIsEqualForm('project-create');
  const [loading, createProjectLoading, saveProjectLoading] = useLoading(
    CREATE_PROJECT,
    SAVE_PROJECT
  );

  const usersOptions = reduce(
    users,
    (acc: object[], value: any) => {
      if (!isEdit) {
        // @ts-ignore ?
        if (user && value['id'] !== user.id) {
          return [
            ...acc,
            {
              value: value['id'],
              label: `${value['firstName']} ${value['lastName']}`,
            },
          ];
        }
      } else {
        return [
          ...acc,
          {
            value: value['id'],
            label: `${value['firstName']} ${value['lastName']}`,
          },
        ];
      }
      return acc;
    },
    []
  );

  const defaultValue =
    isEdit && form && form.values ? form.values.default_users : [];

  const title = isEdit
    ? t('common:modal.edit_project')
    : t('common:modal.create_new_project');
  const button = isEdit
    ? t('common:modal.edit_project')
    : t('common:modal.save_project');

  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader>
        <h1>
          <FontAwesomeIcon icon={['fal', 'building']} fixedWidth />
          {title}
        </h1>
      </ModalHeader>
      <ModalBody>
        <FieldRow>
          <Field name="name" type="text" label={t('common:fields.name')} />
        </FieldRow>
        <FieldRow>
          <Field
            name="description"
            type="text"
            label={t('common:fields.description')}
          />
        </FieldRow>
        <FormSeperator />
        <FieldRow>
          <Field
            label={t('common:modal.assign_to_users')}
            name="users"
            component={StyledReduxFormSelect}
            defaultValue={defaultValue}
            options={usersOptions}
          />
        </FieldRow>
      </ModalBody>
      {!pristine && (
        <ModalFooter>
          <Button
            type="submit"
            variant={Variant.Success}
            disabled={!(!pristine || submitting) && !isEdit}
            loading={createProjectLoading || saveProjectLoading}
          >
            {button}
          </Button>
          <Button
            type="button"
            variant={Variant.Default}
            disabled={pristine || submitting}
            onClick={reset}
          >
            {t('common:button.cancel')}
          </Button>
        </ModalFooter>
      )}
    </form>
  );
};

export default reduxForm<ProjectFormData, ProjectFormProps>({
  form: 'project-create',
  enableReinitialize: true,
})(ProjectForm);
