import { CompanyRole, CREATE_ROLE_FULFILLED } from 'store/roles/types';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_PENDING = 'GET_COMPANY_PENDING';
export const GET_COMPANY_FULFILLED = 'GET_COMPANY_FULFILLED';

export const SAVE_COMPANY = 'SAVE_COMPANY';
export const SAVE_COMPANY_PENDING = 'SAVE_COMPANY_PENDING';
export const SAVE_COMPANY_FULFILLED = 'SAVE_COMPANY_FULFILLED';
export const SAVE_COMPANY_REJECTED = 'SAVE_COMPANY_REJECTED';

export const UPLOAD_COMPANY_AVATAR = 'UPLOAD_COMPANY_AVATAR';
export const UPLOAD_COMPANY_AVATAR_PENDING = 'UPLOAD_COMPANY_AVATAR_PENDING';
export const UPLOAD_COMPANY_AVATAR_FULFILLED = 'UPLOAD_COMPANY_AVATAR_FULFILLED';
export const UPLOAD_COMPANY_AVATAR_REJECTED = 'UPLOAD_COMPANY_AVATAR_REJECTED';

export interface Company {
  id: number;
  name: string;
  description?: string;
  address?: string;
  phone?: string;
  avatar_url?: string;
  roles: CompanyRole[];
}

export interface CompanyState {
  readonly loading: boolean;
  readonly company?: Company;
}

interface UploadUserCompanyResponse {
  src: string;
}

interface GetCompanyAction {
  type: typeof GET_COMPANY;
}

interface SaveCompanyFulfilledAction {
  type: typeof SAVE_COMPANY_FULFILLED;
  data: object;
}

interface CreateRoleFulfilledAction {
  type: typeof CREATE_ROLE_FULFILLED;
}

interface GetCompanyPendingAction {
  type: typeof GET_COMPANY_PENDING;
}

interface GetCompanyFulFilledAction {
  type: typeof GET_COMPANY_FULFILLED;
  data: object;
}

interface UploadCompanyAvatar {
  type: typeof UPLOAD_COMPANY_AVATAR;
  files: object[];
}

interface UploadCompanyAvatarFulfilled {
  type: typeof UPLOAD_COMPANY_AVATAR_FULFILLED;
  data: UploadUserCompanyResponse;
}

export type CompanyActionTypes =
  | GetCompanyAction
  | GetCompanyPendingAction
  | GetCompanyFulFilledAction
  | SaveCompanyFulfilledAction
  | CreateRoleFulfilledAction
  | UploadCompanyAvatar
  | UploadCompanyAvatarFulfilled;
