import Oidc from 'oidc-client';

export const SILENT_RENEW_ERROR = 'SILENT_RENEW_ERROR';
export const SESSION_TERMINATED = 'SESSION_TERMINATED';

export const LOADING_USER = 'LOADING_USER';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';
export const USER_FOUND = 'USER_FOUND';

export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const USER_EXPIRING = 'USER_EXPIRING';
export const USER_EXPIRED = 'USER_EXPIRED';

export interface AuthState {
  readonly user: Oidc.User | null;
  readonly loading: boolean;
}

interface SilentRenewErrorAction {
  type: typeof SILENT_RENEW_ERROR;
}

interface SessionTerminatedAction {
  type: typeof SESSION_TERMINATED;
}

interface LoadingUserAction {
  type: typeof LOADING_USER;
}

interface UserFoundAction {
  type: typeof USER_FOUND;
  payload: Oidc.User;
}

interface LoadUserErrorAction {
  type: typeof LOAD_USER_ERROR;
}

interface UserSignedOutAction {
  type: typeof USER_SIGNED_OUT;
}

interface UserExpiringAction {
  type: typeof USER_EXPIRING;
}

interface UserExpiredAction {
  type: typeof USER_EXPIRED;
}

export type AuthActionTypes =
  | SilentRenewErrorAction
  | SessionTerminatedAction
  | LoadingUserAction
  | LoadUserErrorAction
  | UserFoundAction
  | UserSignedOutAction
  | UserExpiringAction
  | UserExpiredAction;
