import React from 'react';
import ReactDOM from 'react-dom';

import Root from 'container/Root/Root';
import * as serviceWorker from 'serviceWorker';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import packageJson from '../package.json';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    normalizeDepth: 5,
    release: `admin-frontend@${packageJson.version}`,
    environment: process.env.REACT_APP_ENVIRONMENT || 'unknown',
  });
}

Sentry.setTag('realm', process.env.REACT_APP_REALM || 'unknown');
Sentry.setTag('service', packageJson.name);
Sentry.setTag('server_name', window.location.hostname);

console.log(
  `%cRelease: ${packageJson.version}`,
  'font-size: 18px; background-color: white; color: black; font-weight: bold; padding: 5px;'
);

const rootEl = document.getElementById('root');

/**
 * Entry point of the aedifion react app.
 */
ReactDOM.render(<Root />, rootEl);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
  module.hot.accept('container/Root/Root', () => {
    const NextApp = require('container/Root/Root').default;
    ReactDOM.render(<NextApp />, rootEl);
  });
}