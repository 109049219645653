import React, { CSSProperties, FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Badge } from 'components/ui/Badge/Badge';

import { StyledNotifications } from 'components/ui/Notifications/styles/NotificationsStyles';

/**
 *
 */
const Notifications: FC<{ style: CSSProperties }> = ({ style }) => (
  <StyledNotifications style={style}>
    <Badge count={5}>
      <FontAwesomeIcon icon={['fal', 'bookmark']} size={'2x'} fixedWidth />
    </Badge>
    <Badge>
      <FontAwesomeIcon icon={['fal', 'star']} size={'2x'} fixedWidth />
    </Badge>
    <Badge count={2}>
      <FontAwesomeIcon icon={['fal', 'bell']} size={'2x'} fixedWidth />
    </Badge>
  </StyledNotifications>
);

export default Notifications;
