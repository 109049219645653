import * as H from 'history';

/*
 * Action types
 * */
import { ADCompany } from 'store/companies/types';
import { ADProject } from 'store/adprojects/types';

export const GET_ADUSERS = 'GET_ADUSERS';
export const GET_ADUSERS_PENDING = 'GET_ADUSERS_PENDING';
export const GET_ADUSERS_FULFILLED = 'GET_ADUSERS_FULFILLED';
export const GET_ADUSERS_REJECTED = 'GET_ADUSERS_REJECTED';

export const GET_ADUSER = 'GET_ADUSER';
export const GET_ADUSER_PENDING = 'GET_ADUSER_PENDING';
export const GET_ADUSER_FULFILLED = 'GET_ADUSER_FULFILLED';
export const GET_ADUSER_REJECTED = 'GET_ADUSER_REJECTED';

export const DELETE_ADUSER = 'DELETE_ADUSER';
export const DELETE_ADUSER_PENDING = 'DELETE_ADUSER_PENDING';
export const DELETE_ADUSER_FULFILLED = 'DELETE_ADUSER_FULFILLED';
export const DELETE_ADUSER_REJECTED = 'DELETE_ADUSER_REJECTED';

export const CREATE_ADUSER = 'CREATE_ADUSER';
export const CREATE_ADUSER_PENDING = 'CREATE_ADUSER_PENDING';
export const CREATE_ADUSER_FULFILLED = 'CREATE_ADUSER_FULFILLED';
export const CREATE_ADUSER_REJECTED = 'CREATE_ADUSER_REJECTED';

export const SAVE_ADUSER = 'SAVE_ADUSER';
export const SAVE_ADUSER_PENDING = 'SAVE_ADUSER_PENDING';
export const SAVE_ADUSER_FULFILLED = 'SAVE_ADUSER_FULFILLED';
export const SAVE_ADUSER_REJECTED = 'SAVE_ADUSER_REJECTED';

export const EDIT_ADUSER = 'EDIT_ADUSER';
export const EDIT_ADUSER_PENDING = 'EDIT_ADUSER_PENDING';
export const EDIT_ADUSER_FULFILLED = 'EDIT_ADUSER_FULFILLED';
export const EDIT_ADUSER_REJECTED = 'EDIT_ADUSER_REJECTED';

export const QUICK_CREATE_USER = 'QUICK_CREATE_USER';

/*
 * Models
 * */
export interface ADUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  active: boolean;
  address: string;
  avatar_url: string;
  company_id: number;
  company_roles: Array<number>;
  phone: string;
  company: ADCompany;
  projects: Array<ADProject>;
}

export interface ADUserNew {
  active: boolean;
  address: string;
  avatar_url: string;
  company_id: number;
  company_roles: Array<number>;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  project_roles: Array<number>;
}

export interface ADUserUpdate {
  active: boolean;
  address: string;
  avatar_url: string;
  company_id: number;
  company_roles: Array<number>;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  id: number;
  phone: string;
  project_roles: Array<number>;
}

/*
 * State Models
 * */
export interface ADUserState {
  readonly loading: boolean;
  readonly user?: ADUser;
}

export interface ADUsersState {
  readonly loading: boolean;
  readonly users?: Array<ADUser>;
}

/*
 * Action Types
 * */
interface GetADUserActionType {
  type: typeof GET_ADUSER;
  data: number; // user_id
}

interface GetADUserPendingActionType {
  type: typeof GET_ADUSER_PENDING;
}

interface GetADUserFulfilledActionType {
  type: typeof GET_ADUSER_FULFILLED;
  data: ADUser;
}

interface GetADUserRejectedActionType {
  type: typeof GET_ADUSER_REJECTED;
}

interface GetADUsersActionType {
  type: typeof GET_ADUSERS;
  data: number; // company_id
}

interface GetADUsersPendingActionType {
  type: typeof GET_ADUSERS_PENDING;
}

interface GetADUsersFulfilledActionType {
  type: typeof GET_ADUSERS_FULFILLED;
  data: Array<ADUser>;
}

interface GetADUsersRejectedActionType {
  type: typeof GET_ADUSERS_REJECTED;
}

interface AddADUserActionType {
  type: typeof CREATE_ADUSER;
  data: ADUserNew;
}

interface AddADUserPendingActionType {
  type: typeof CREATE_ADUSER_PENDING;
}

interface AddADUserFulfilledActionType {
  type: typeof CREATE_ADUSER_FULFILLED;
  data: ADUser;
}

interface AddADUserRejectedActionType {
  type: typeof CREATE_ADUSER_REJECTED;
}

interface DeleteADUserActionType {
  type: typeof DELETE_ADUSER;
  data: number; // user_id
}

interface DeleteADUserPendingActionType {
  type: typeof DELETE_ADUSER_PENDING;
}

interface DeleteADUserFulfilledActionType {
  type: typeof DELETE_ADUSER_FULFILLED;
  data: number; // user_id
}

interface DeleteADUserRejectedActionType {
  type: typeof DELETE_ADUSER_REJECTED;
}

interface PutADUserActionType {
  type: typeof SAVE_ADUSER;
  data: ADUserUpdate;
}

interface PutADUserPendingActionType {
  type: typeof SAVE_ADUSER_PENDING;
}

interface PutADUserFulfilledActionType {
  type: typeof SAVE_ADUSER_FULFILLED;
  data: ADUser;
}

interface PutADUserRejectedActionType {
  type: typeof SAVE_ADUSER_REJECTED;
}

interface QuickCreateUserAction {
  type: typeof QUICK_CREATE_USER;
  data: {
    history: H.History,
  }
}

/*
 * Export Action Types
 * */
export type ADUserActionTypes =
  | GetADUserActionType
  | GetADUserPendingActionType
  | GetADUserFulfilledActionType
  | GetADUserRejectedActionType
  | PutADUserActionType
  | PutADUserPendingActionType
  | PutADUserFulfilledActionType
  | PutADUserRejectedActionType
  | DeleteADUserActionType
  | DeleteADUserPendingActionType
  | DeleteADUserFulfilledActionType
  | DeleteADUserRejectedActionType
  | AddADUserActionType
  | AddADUserPendingActionType
  | AddADUserFulfilledActionType
  | AddADUserRejectedActionType;

export type ADUsersActionTypes =
  | GetADUsersActionType
  | GetADUsersPendingActionType
  | GetADUsersFulfilledActionType
  | GetADUsersRejectedActionType
  | AddADUserFulfilledActionType
  | PutADUserFulfilledActionType
  | DeleteADUserFulfilledActionType
  | QuickCreateUserAction;
