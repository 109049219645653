import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import Background from 'components/ui/Background/Background';
import { URN } from 'constants/storageKeys';
import { StateType } from 'store/types';

/**
 * Auth route tries to verify if a user is allowed to enter
 * a route which requires valid user credentials
 *
 * @param {React.Component} - react child component
 * @param {object} props - with redux auth state and react router options
 */
const AuthRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
  const auth = useSelector((state: StateType) => state.auth);

  // Check if a user exists
  if (auth.user) return <Route {...rest} component={Component} />;

  // Check if a user loading action is dispatched
  if (auth.loading) return <Background />;

  // Else push the urn to the local storage an redirect to the login page
  const { location } = rest;
  sessionStorage.setItem(
    URN,
    `${location.pathname}${location.search}${location.hash}`
  );
  return <Redirect to="/login" />;
};

export default AuthRoute;
