import React, { FC } from 'react';
import styled from 'styled-components';

const StyledStepDevider = styled.div`
  height: 1px;
  width: 50px;
  background-color: #47486d;
`;

const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0em 1em;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  &:active {
    transform: scale(1.03);
    cursor: pointer;
  }
`;

const StyledStepNumber = styled.div`
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: rgba(191, 193, 224, 0.8);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1em;
  font-size: 10px;
`;

const StyledStepDescription = styled.div`
  color: rgba(191, 193, 224, 0.8);
  text-align: center;
`;

const numberSelected = {
  backgroundColor: '#47486D',
  color: 'white',
  boxShadow: '0px 3px 15px #47486D',
  transform: 'scale(1.2)',
  border: '1px solid rgba(255,255,255,0.5)',
};

const descriptionSelected = {
  color: 'white',
};

const numberFinished = {
  backgroundColor: '#47486D',
  color: 'white',
  border: '1px solid rgba(255,255,255,0.5)',
};

const descriptionFinished = {
  color: 'white',
};

const Step = (props: {
  active: boolean;
  finished: boolean;
  description: string;
  number: number;
  site: string;
  clicked: () => void;
}) => {
  return (
    <StyledStep onClick={props.clicked}>
      <StyledStepNumber
        style={
          props.active ? numberSelected : props.finished ? numberFinished : {}
        }
      >
        {props.number}
      </StyledStepNumber>
      <StyledStepDescription
        style={
          props.active
            ? descriptionSelected
            : props.finished
            ? descriptionFinished
            : {}
        }
      >
        {props.description}
      </StyledStepDescription>
    </StyledStep>
  );
};

const StepDevider = () => {
  return <StyledStepDevider />;
};

export { Step, StepDevider };
