import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import datepickerReducer from 'store/datepicker/reducer';
import userReducer from 'store/user/reducer';
import usersReducer from 'store/users/reducer';
import rolesReducer from 'store/roles/reducer';
import authReducer from 'store/auth/reducer';
import projectReducer from 'store/project/reducer';
import projectsReducer from 'store/projects/reducer';
import dataPointsReducer from 'store/datapoint/reducer';
import companyReducer from 'store/company/reducer';
import modalReducer from 'store/modal/reducer';
import paginationReducer from 'store/pagination/reducer';
import metaReducer from 'store/meta/reducer';
import endpointReducer from 'store/endpoints/reducer';

/**
 * Adding reducers for Admin Dashboard
 * */
import adcompaniesReducer from 'store/companies/reducer';
import adcompanyReducer from 'store/companies/adcompany_reducer';
import adprojectsReducer from 'store/adprojects/projects_reducer';
import adprojectReducer from 'store/adprojects/project_reducer';
import adusersReducer from 'store/adusers/users_reducer';
import aduserReducer from 'store/adusers/user_reducer';
import adcompanyrolesReducer from 'store/adcompanyroles/reducer';
import adprojectrolesReducer from 'store/adprojectroles/reducer';
import currentflowReducer from 'store/flow/reducer';
import initializerReducer from 'store/initializers/reducer';

// Export the combined reducers
const rootReducer = combineReducers({
  datepicker: datepickerReducer,
  dataPoint: dataPointsReducer,
  project: projectReducer,
  projects: projectsReducer,
  user: userReducer,
  users: usersReducer,
  roles: rolesReducer,
  company: companyReducer,
  auth: authReducer,
  form: formReducer,
  modal: modalReducer,
  pagination: paginationReducer,
  meta: metaReducer,
  endpoints: endpointReducer,
  adcompanies: adcompaniesReducer,
  adcompany: adcompanyReducer,
  adprojects: adprojectsReducer,
  adproject: adprojectReducer,
  adusers: adusersReducer,
  aduser: aduserReducer,
  adcompanyroles: adcompanyrolesReducer,
  adprojectroles: adprojectrolesReducer,
  currentFlow: currentflowReducer,
  initializer: initializerReducer,
});

export default rootReducer;
