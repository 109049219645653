import {
  CHANGE_CURRENT_FLOW,
  CHANGE_CURRENT_STEP,
  CurrentFlowActionTypes,
  FINISH_CURRENT_FLOW_STEP,
  SET_CURRENT_FLOW,
  UPDATE_CURRENT_FLOW,
} from 'store/flow/types';

import * as H from 'history';

/*
 * Action Dispatcher
 * */

export const setFlow = (
  flow_id,
  his: H.History
): CurrentFlowActionTypes => ({
  type: SET_CURRENT_FLOW,
  data: flow_id,
  history: his,
});

export const updateFlow = (step_id): CurrentFlowActionTypes => ({
  type: UPDATE_CURRENT_FLOW,
  data: step_id,
});

export const changeFlow = (flow_id): CurrentFlowActionTypes => ({
  type: CHANGE_CURRENT_FLOW,
  data: flow_id,
});

export const finishStep = (
  step_id: number,
  his: H.History
): CurrentFlowActionTypes => ({
  type: FINISH_CURRENT_FLOW_STEP,
  data: step_id,
  history: his,
});

export const changeStep = (
  step_id: number,
  his: H.History
): CurrentFlowActionTypes => ({
  type: CHANGE_CURRENT_STEP,
  data: step_id,
  history: his,
});
