import {
  GET_COMPANY_PENDING,
  GET_COMPANY_FULFILLED,
  SAVE_COMPANY_FULFILLED,
  UPLOAD_COMPANY_AVATAR_FULFILLED,
  CompanyState,
  CompanyActionTypes,
} from 'store/company/types';

const initialState: CompanyState = {
  loading: false,
};

/**
 *
 */
export default (state = initialState, action: CompanyActionTypes) => {
  switch (action.type) {
    case GET_COMPANY_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANY_FULFILLED:
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    case SAVE_COMPANY_FULFILLED:
      const data = action.data;
      return {
        ...state,
        company: Object.assign(state.company, data),
      };
    case UPLOAD_COMPANY_AVATAR_FULFILLED:
      return {
        ...state,
        company: {
          ...state.company,
          avatar_url: action.data.src,
        },
      };
    default:
      return state;
  }
};
