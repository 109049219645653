import React, { FC, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { get, map, startsWith } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { format } from 'date-fns';

import { Section } from 'components/ui/Section/Section';
import Field, {
  FieldGroup,
  FieldRow,
  Form,
  FormControls,
} from 'components/ui/Form/Form';
import { Button, Variant } from 'components/ui/Button/Button';
import { Card, CardBody, CardHeader } from 'components/ui/Card/Card';
import { CardWrapper } from 'components/ui/Card/styles/CardStyles';
import Tag from 'components/ui/Tag/Tag';
import Table from 'components/ui/Table/Table';
import ImageDropzone from 'components/ui/ImageDropzone/ImageDropzone';
import {
  FieldCol,
  FieldFlexWidth,
  AlignPicture,
} from 'container/Forms/styles/UserDetailsFormStyles';

import theme from 'config/themes/default';

import { useTranslation } from 'react-i18next';
import { ProjectWithCompanyId } from 'store/project/types';
import { useIsEqualForm, useLoading } from 'utils/hooks';
import {
  SAVE_USER,
  UPLOAD_USER_AVATAR,
} from 'store/user/types';
import { StateType } from 'src/store/types';

// -- TODO --
// Remove the key index signature type. It´s just there to prevent an failure with the
// validate utils function
interface UserDetailsFormData {
  firstName: string;
  lastName: string;
  email: string;
  address?: string;
  phone?: string;
  [key: string]: string | number;
}

interface UserDetailsFormProps {
  projects: { [id: number]: ProjectWithCompanyId };
  passwordLockedUntil?: boolean | string;
  avatarUrl?: string;
}

type CombinedUserDetailsFormProps = UserDetailsFormProps &
InjectedFormProps<UserDetailsFormData, UserDetailsFormProps>;

const UserDetailsForm: FC<CombinedUserDetailsFormProps> = ({
  projects,
  passwordLockedUntil,
  handleSubmit,
  pristine,
  reset,
  submitting,
  avatarUrl,
}) => {
  const dispatch = useDispatch();
  const userRoles = useSelector((state: StateType) => get(state, 'user.details.roles', []));
  const { t } = useTranslation();
  const [page, setPage] = useState(2);
  pristine = useIsEqualForm('user-details');
  const [loading, saveUserLoading] = useLoading(
    SAVE_USER
  );
  submitting = saveUserLoading;

  return (
    <Form onSubmit={handleSubmit}>
      <Section title={t('user_details:account_details')}>
        <FieldGroup width={100}>
          <FieldCol>
            <FieldFlexWidth growNumber={3} basis={'80%'}>
              <FieldRow>
                <Field
                  name="firstName"
                  type="text"
                  label={t('common:fields.firstName')}
                />
                <Field
                  name="lastName"
                  type="text"
                  label={t('common:fields.lastName')}
                />
              </FieldRow>
              <FieldRow>
                <Field
                  name="email"
                  type="email"
                  label={t('common:fields.email')}
                />
              </FieldRow>
              <FieldRow>
                <Field
                  name="address"
                  type="text"
                  label={t('common:fields.address')}
                  placeholder={'Musterstraße 22, 52066 Aachen'}
                />
              </FieldRow>
              <FieldRow>
                <Field
                  name="phone"
                  type="text"
                  label={t('common:fields.phone')}
                />
              </FieldRow>
            </FieldFlexWidth>
            <FieldFlexWidth growNumber={1} basis={'20%'}>
              <AlignPicture>
                <ImageDropzone
                  imagePath={avatarUrl}
                  dispatch={UPLOAD_USER_AVATAR}
                />
              </AlignPicture>
            </FieldFlexWidth>
          </FieldCol>
        </FieldGroup>
      </Section>

      <Section title={t('user_details:security')}>
        <FieldGroup style={{ opacity: !!passwordLockedUntil ? 0.5 : 1 }}>
          <FieldRow>
            <Field
              name="currentPassword"
              type="password"
              label={t('common:fields.currentPassword')}
              disabled={!!passwordLockedUntil}
            />
          </FieldRow>
          <FieldRow>
            <Field
              name="password"
              type="password"
              label={t('common:fields.password')}
              disabled={!!passwordLockedUntil}
            />
            <Field
              name="passwordConfirmation"
              type="password"
              label={t('common:fields.passwordConfirmation')}
              disabled={!!passwordLockedUntil}
            />
          </FieldRow>
        </FieldGroup>
      </Section>

      <Section title={t('user_details:assigned_projects')}>
        <CardWrapper>
          {projects &&
            Object.values(projects).map((project: ProjectWithCompanyId) => (
              <Card className={'--default-margin'}>
                {project.avatar_url && <CardHeader src={project.avatar_url} />}
                <CardBody>
                  <h3>{project.name}</h3>
                  <p>{project.description}</p>
                  <div className={`tags__wrapper --with-tags`}>
                    {/*
                    // @ts-ignore */}
                    {userRoles
                      .filter(role => role.project_id === project.id)
                      .map(role => (
                        <Tag isRole>{role.name}</Tag>
                      ))}
                  </div>
                </CardBody>
              </Card>
            ))}
        </CardWrapper>
      </Section>

      {!pristine && (
        <FormControls>
          <div>
            <Button
              type="button"
              variant={Variant.Default}
              disabled={pristine || submitting}
              onClick={reset}
            >
              {t('common:button.cancel')}
            </Button>
            <Button
              type="submit"
              variant={Variant.Success}
              disabled={!(!pristine || submitting)}
              loading={submitting}
            >
              {t('common:button.save_changes')}
            </Button>
          </div>
        </FormControls>
      )}
    </Form>
  );
};

const UserDetailsFormContainer = reduxForm<
UserDetailsFormData,
UserDetailsFormProps
>({
  form: 'user-details',
  enableReinitialize: true,
})(UserDetailsForm);

// does not work with ConfigProps?
export default connect<{}, {}, UserDetailsFormProps>(state => ({
  initialValues: {
    firstName: get(state, 'user.details.firstName', null),
    lastName: get(state, 'user.details.lastName', null),
    email: get(state, 'user.details.email', null),
    address: get(state, 'user.details.address', null),
    phone: get(state, 'user.details.phone', null),
  },
}))(UserDetailsFormContainer);
