export const SELECT_PAGE = 'SELECT_PAGE';
export const PREV_PAGE = 'PREV_PAGE';
export const NEXT_PAGE = 'NEXT_PAGE';
export const REMOVE_PAGINATION_CONTEXT = 'REMOVE_PAGINATION_CONTEXT';
export const GET_PAGINATION_CONTEXT_PENDING = 'GET_PAGINATION_CONTEXT_PENDING';
export const GET_PAGINATION_CONTEXT_FULFILLED =
  'GET_PAGINATION_CONTEXT_FULFILLED';

export interface PaginationState {
  readonly currentPage: number;
  readonly totalPages: number;
  readonly loading: boolean;
  readonly contextIdentifier?: string;
}

interface SelectPageAction {
  type: typeof SELECT_PAGE;
  pageNumber: number;
}

interface PrevPageAction {
  type: typeof PREV_PAGE;
  pageNumber: number;
}

interface NextPageAction {
  type: typeof NEXT_PAGE;
  pageNumber: number;
}

interface GetPaginationContextPendingAction {
  type: typeof GET_PAGINATION_CONTEXT_PENDING;
}

interface GetPaginationContextFulfilledAction {
  type: typeof GET_PAGINATION_CONTEXT_FULFILLED;
  currentPage: number;
  totalPages: number;
  contextIdentifier: string;
}

interface RemovePaginationContextAction {
  type: typeof REMOVE_PAGINATION_CONTEXT;
}

export type PaginationActionTypes =
  | SelectPageAction
  | PrevPageAction
  | NextPageAction
  | GetPaginationContextPendingAction
  | GetPaginationContextFulfilledAction
  | RemovePaginationContextAction;
