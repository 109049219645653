import React, { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { ModalBody, ModalFooter, ModalHeader } from 'components/ui/Modal/Modal';
import Field, { FieldRow } from 'components/ui/Form/Form';
import { Button, Variant } from 'components/ui/Button/Button';
import DeleteItemDetails from 'container/Forms/DeleteitemDetails';
import { useLoading } from 'utils/hooks';

const ConfirmProjectModalForm: FC<InjectedFormProps> = ({
  submitting,
  pristine,
  reset,
  handleSubmit,
}) => {
  const { t } = useTranslation();
    const [loading, deleteLoading] = useLoading(/^DELETE_/);

  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader>
        <h1>{t('common:modal.confirm_delete')}</h1>
      </ModalHeader>
      <ModalBody>
        <DeleteItemDetails
          message={t('projects:modal.delete_project_confirm')}
          items={t('projects:modal.itemsDeleted', { joinArrays: '+' })}
        />
        <FieldRow>
          <Field
            name="delete"
            type="text"
            label={t('common:modal.confirm_delete_label')}
          />
        </FieldRow>
      </ModalBody>
      {!pristine && (
        <ModalFooter>
          <Button
            type="submit"
            variant={Variant.Warning}
            disabled={!(!pristine || submitting)}
            loading={deleteLoading}
          >
            {t('common:button.delete')}
          </Button>
          <Button
            type="button"
            variant={Variant.Default}
            disabled={pristine || submitting}
            onClick={reset}
          >
            {t('common:button.cancel')}
          </Button>
        </ModalFooter>
      )}
    </form>
  );
};

export default reduxForm({
  form: 'confirm-delete',
  enableReinitialize: true,
})(ConfirmProjectModalForm);
