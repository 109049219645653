import { triggerInitializer } from 'store/initializers/actions';

export const initializeRequest = (
  loading: boolean|any,
  payload: any,
  dispatch: any,
  setInitializer?: any
) => {
    if (setInitializer) {
        setInitializer()
    }
    loading(true);
    dispatch(triggerInitializer({ ...payload }));
    setTimeout(function() {
      loading(false);
    }, 5000);
};
