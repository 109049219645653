import React, { FC } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { each, get, isArray, reduce } from 'lodash';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map, pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Section } from 'components/ui/Section/Section';
import Field, {
  FieldGroup,
  FieldRow,
  Form,
  FormControls,
} from 'components/ui/Form/Form';
import { Button, Variant } from 'components/ui/Button/Button';
import {
  Card,
  CardActions,
  CardBody,
  CardFooter,
  CardHeader,
  EmptyCard,
} from 'components/ui/Card/Card';
import { CardWrapper } from 'components/ui/Card/styles/CardStyles';
import Table from 'components/ui/Table/Table';
import Tag from 'components/ui/Tag/Tag';
import List, { ListItem } from 'components/ui/List/List';

import { StateType } from 'store/types';
import {
  EDIT_COMPANY_ROLE,
  DELETE_COMPANY_ROLE,
  EDIT_PROJECT_ROLES,
} from 'store/roles/types';

import { DELETE_PROJECT, EDIT_PROJECT } from 'store/project/types';
import { DELETE_USER } from 'store/user/types';
import { useIsEqualForm, useLoading, useRoles } from 'utils/hooks';
import { SAVE_COMPANY, UPLOAD_COMPANY_AVATAR } from 'store/company/types';
import {
  AlignPicture,
  FieldCol,
  FieldFlexWidth,
} from 'container/Forms/styles/UserDetailsFormStyles';
import ImageDropzone from 'components/ui/ImageDropzone/ImageDropzone';

// -- TODO --
// Remove the key index signature type. It´s just there to prevent an failure with the
// validate utils function
interface CompanyDetailsFormData {
  name: string;
  description?: string;
  address?: string;
  phone?: string;
  [key: string]: string | number;
}

interface CompanyDetailsFormProps {
  companyAvatarUrl?: string;
}

type CombinedCompanyDetailsFormProps = CompanyDetailsFormProps &
  InjectedFormProps<CompanyDetailsFormData, CompanyDetailsFormProps>;

const CompanyDetailsForm: FC<CombinedCompanyDetailsFormProps> = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  companyAvatarUrl,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [
    canSeeCompany,
    canSeeCompanyRoles,
    canUpdateCompanyRole,
    canDeleteCompanyRole,
    canCreateCompanyUser,
    canSeeProject,
    canAssignProjectRole,
    canDeleteUser,
    canCreateProject,
    canUpdateProject,
    canDeleteProject,
  ] = useRoles(
    'GET_COMPANY',
    'GET_COMPANY_ROLES',
    'POST_COMPANY_ROLE',
    'PUT_COMPANY_ROLE',
    'DELETE_COMPANY_ROLE',
    'POST_COMPANY_USER',
    'GET_PROJECT',
    'POST_PROJECT_ROLE_USER',
    'DELETE_USER',
    'POST_COMPANY_PROJECT',
    'PUT_PROJECT',
    'DELETE_PROJECT'
  );
  const [loading, saveCompanyLoading] = useLoading(SAVE_COMPANY);

  const { company: companyRoles, project: projectRoles } = useSelector(
    (state: StateType) => state.roles
  );
  const { users } = useSelector((state: StateType) => state.users);
  const { company: companyProjects } = useSelector(
    (state: StateType) => state.projects
  );

  pristine = useIsEqualForm('company-details');

  const flatProjectRoles = reduce(
    companyProjects,
    (acc, project) => {
      each(project.roles, role => {
        role.project_id = project.id;
        role.project = project;
        acc[role.id] = role;
      });

      return acc;
    },
    {}
  );

  // we need users -> projects with combined roles ...
  const usersWithProjects = reduce(
    users,
    (acc, user) => {
      const projects = {};
      each(user.project_roles, projectRoleID => {
        if(!flatProjectRoles[projectRoleID]) return;
        if (!isArray(projects[flatProjectRoles[projectRoleID]['project_id']])) {
          projects[flatProjectRoles[projectRoleID]['project_id']] = [];
        }
        projects[flatProjectRoles[projectRoleID]['project_id']].push(
          flatProjectRoles[projectRoleID]
        );
      });

      // @ts-ignore
      user.projects = projects;

      acc[user.id] = user;

      return acc;
    },
    {}
  );

  const projectsWithUsers = reduce(
    usersWithProjects,
    (acc, user) => {
      // @ts-ignore
      each(user.projects, (roles, projectID) => {
        if (!isArray(acc[projectID])) {
          acc[projectID] = [];
        }
        acc[projectID].push(user);
      });

      return acc;
    },
    {}
  );

  return canSeeCompany ? (
    <Form onSubmit={handleSubmit}>
      <Section title={t('company_details:general_information')}>
        <FieldGroup width={100}>
          <FieldCol>
            <FieldFlexWidth growNumber={3} basis={'80%'}>
              <FieldRow>
                <Field
                  name="name"
                  type="text"
                  label={t('common:fields.name')}
                  readonly
                  disabled
                />
              </FieldRow>
              <FieldRow>
                <Field
                  name="description"
                  type="text"
                  label={t('common:fields.description')}
                />
              </FieldRow>
              <FieldRow>
                <Field
                  name="address"
                  type="text"
                  label={t('common:fields.address')}
                />
              </FieldRow>
              <FieldRow>
                <Field
                  name="phone"
                  type="text"
                  label={t('common:fields.phone')}
                />
              </FieldRow>
            </FieldFlexWidth>
            <FieldFlexWidth growNumber={1} basis={'20%'}>
              <AlignPicture>
                <ImageDropzone
                  imagePath={companyAvatarUrl}
                  dispatch={UPLOAD_COMPANY_AVATAR}
                />
              </AlignPicture>
            </FieldFlexWidth>
          </FieldCol>
        </FieldGroup>
      </Section>

      {canSeeCompanyRoles && (
        <Section title={t('company_details:roles')}>
          <Table
            data={map(companyRoles, obj => {
              const first = pick(obj, 'name', 'description');
              return {
                ...first,
                edit: { id: obj.id, name: obj.name },
                delete: { id: obj.id, name: obj.name },
              };
            })}
            columns={[
              {
                Header: t('common:fields.name'),
                accessor: 'name',
              },
              {
                Header: t('common:fields.description'),
                accessor: 'description',
              },
              {
                Header: '',
                accessor: 'edit',
                maxWidth: 100,
                Cell: row =>
                  row.value.name !== 'admin' &&
                  canUpdateCompanyRole && (
                    <div
                      style={{ color: 'rgba(255, 255, 255, .5)' }}
                      onClick={ev => {
                        dispatch({
                          type: EDIT_COMPANY_ROLE,
                          roleID: row.value.id,
                        });
                        ev.preventDefault();
                      }}
                    >
                      <FontAwesomeIcon icon={['far', 'edit']} fixedWidth />
                    </div>
                  ),
              },
              {
                Header: '',
                accessor: 'delete',
                maxWidth: 100,
                Cell: row =>
                  row.value.name !== 'admin' &&
                  canDeleteCompanyRole && (
                    <div
                      style={{ color: 'rgba(255, 255, 255, .5)' }}
                      onClick={ev => {
                        dispatch({
                          type: DELETE_COMPANY_ROLE,
                          roleID: row.value.id,
                        });
                        ev.preventDefault();
                      }}
                    >
                      <FontAwesomeIcon icon={['far', 'trash']} fixedWidth />
                    </div>
                  ),
              },
            ]}
            showPagination={false}
            minRows={0}
          />
          {/*{canCreateCompanyRole && (
            <Button
              variant={Variant.Outlined}
              alignment={Alignment.Right}
              border
              icon={['far', 'shield-alt']}
              onClick={event => {
                batch(() => {
                  dispatch(initialize('role-create', {}));
                  dispatch({
                    type: TOGGLE_MODAL,
                    selected: 'roles',
                  });
                });
                event.preventDefault();
              }}
            >
              {t('common:button.create_new_role')}
            </Button>
          )}*/}
        </Section>
      )}

      <Section title={t('company_details:users')}>
        <CardWrapper className={'--user-wrapper'}>
          {users &&
            users.map((user, index) => (
              <Card type={'avatar'} alignment={'center'}>
                {user.avatar_url && <CardHeader src={user.avatar_url} />}
                <CardBody>
                  <h3>{`${user.firstName} ${user.lastName}`}</h3>
                  <p>{`${user.email}`}</p>
                  <div
                    className={`tags__wrapper ${
                      user.company_roles && !!user.company_roles.length
                        ? ' --with-tags'
                        : ''
                    }`}
                  >
                    {user.company_roles &&
                      user.company_roles.map(
                        role =>
                          companyRoles &&
                          companyRoles[role] && (
                            <Tag isRole>{companyRoles[role].name}</Tag>
                          )
                      )}
                  </div>
                  {canAssignProjectRole && canDeleteUser && (
                    <CardActions
                      header={t('common:dropdown.user_actions')}
                      items={[
                        canAssignProjectRole && {
                          label: t('common:dropdown.assign_projects'),
                          dispatch: {
                            type: EDIT_PROJECT_ROLES,
                            userID: user.id,
                          },
                        },
                        canDeleteUser && {
                          label: t('common:dropdown.delete_user'),
                          dispatch: {
                            type: DELETE_USER,
                            userID: user.id,
                          },
                        },
                      ]}
                    />
                  )}
                </CardBody>
                {!!Object.values(usersWithProjects[user.id].projects).length &&
                canSeeProject ? (
                  <CardFooter
                      label={t('company_details:assigned_projects')}
                      amount={
                        Object.values(usersWithProjects[user.id].projects).length
                      }
                      index={index}
                    >
                      <List>
                        {Object.entries(usersWithProjects[user.id].projects).map(
                          ([projectID, roles]: [string, any]) => (
                            <ListItem
                              media={companyProjects[projectID].avatar_url}
                              label={companyProjects[projectID].name}
                            >
                              {roles.map(role => (
                              <Tag isRole>{role.name}</Tag>
                            ))}
                            </ListItem>
                        )
                        )}
                      </List>
                    </CardFooter>
                  ) : (
                  // can see and assign projects to users ...
                    canSeeProject &&
                  canAssignProjectRole && (
                      <CardFooter
                        label={t('company_details:assign_projects')}
                        as="button"
                        onClick={ev => {
                          dispatch({
                            type: EDIT_PROJECT_ROLES,
                            userID: user.id,
                          });
                          ev.preventDefault();
                        }}
                      />
                    )
                  )}
              </Card>
            ))}
          {canCreateCompanyUser && (
            <EmptyCard
              icon="user-plus"
              iconprefix="fal"
              label={t('company_details:create_new_user')}
              onClick={event => {
                dispatch({
                  type: 'TOGGLE_MODAL',
                  selected: 'user',
                });
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          )}
        </CardWrapper>
      </Section>

      {canSeeProject && (
        <Section title={t('company_details:projects')}>
          <CardWrapper>
            {companyProjects &&
              Object.values(companyProjects).map((project, index) => (
                <Card>
                  <CardHeader src={project.avatar_url} />
                  <CardBody>
                    <h3>{project.name}</h3>
                    <p>{project.description}</p>
                    {canUpdateProject && canDeleteProject && (
                      <CardActions
                        header={t('common:dropdown.project_actions')}
                        items={[
                          canUpdateProject && {
                            label: t('common:dropdown.edit_project'),
                            dispatch: {
                              type: EDIT_PROJECT,
                              project_id: project.id,
                            },
                          },
                          canDeleteProject && {
                            label: t('common:dropdown.delete_project'),
                            dispatch: {
                              type: DELETE_PROJECT,
                              project_id: project.id,
                            },
                          },
                        ]}
                      />
                    )}
                  </CardBody>
                  {projectsWithUsers[project.id] ? (
                    <CardFooter
                      label={t('company_details:assigned_users')}
                      amount={projectsWithUsers[project.id].length}
                      index={index}
                    >
                      <List>
                        {projectsWithUsers[project.id].map(user => (
                          <ListItem
                            label={`${user.firstName} ${user.lastName}`}
                            media={user.avatar_url}
                          >
                            {user.projects[project.id] &&
                              user.projects[project.id].map(role => (
                                <Tag isRole>{role['name']}</Tag>
                              ))}
                          </ListItem>
                        ))}
                      </List>
                    </CardFooter>
                  ) : (
                    <CardFooter
                      label={t('company_details:assign_users')}
                      as="button"
                      icon="users"
                      onClick={ev => {
                        dispatch({
                          type: EDIT_PROJECT,
                          project_id: project.id,
                        });
                        ev.preventDefault();
                      }}
                    />
                  )}
                </Card>
              ))}
            {canCreateProject && (
              <EmptyCard
                icon="building"
                iconprefix="fal"
                label={t('common:button.create_new_project')}
                onClick={event => {
                  dispatch({
                    type: 'TOGGLE_MODAL',
                    selected: 'project',
                  });
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            )}
          </CardWrapper>
        </Section>
      )}

      {!pristine && (
        <FormControls>
          <div>
            <Button
              type="button"
              variant={Variant.Default}
              disabled={pristine || submitting}
              onClick={reset}
            >
              {t('common:button.cancel')}
            </Button>
            <Button
              type="submit"
              variant={Variant.Success}
              disabled={!(!pristine || submitting)}
              loading={saveCompanyLoading}
            >
              {t('common:button.save_changes')}
            </Button>
          </div>
        </FormControls>
      )}
    </Form>
  ) : null;
};

const CompanyDetailsFormContainer = reduxForm<
CompanyDetailsFormData,
CompanyDetailsFormProps
>({
  form: 'company-details',
  enableReinitialize: true,
})(CompanyDetailsForm);

export default connect<{}, {}, CompanyDetailsFormProps>(state => ({
  initialValues: {
    name: get(state, 'company.company.name', null),
    description: get(state, 'company.company.description', null),
    address: get(state, 'company.company.address', null),
    phone: get(state, 'company.company.phone', null),
  },
}))(CompanyDetailsFormContainer);
