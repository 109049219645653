import React, { FC, useState } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rgba } from 'polished';

import { ModalBody, ModalFooter, ModalHeader } from 'components/ui/Modal/Modal';

import Field, {
  FieldRow,
  StyledSingleFormSelect,
} from 'components/ui/Form/Form';
import { Button, Variant } from 'components/ui/Button/Button';
import { useIsEqualForm, useLoading } from 'utils/hooks';
import { CREATE_ADUSER } from 'store/adusers/types';
import { useSelector, connect, useDispatch } from 'react-redux';
import { StateType } from 'store/types';
import { GET_ADCOMPANYROLES } from 'store/adcompanyroles/types';
import { GET_ADPROJECTROLES } from 'store/adprojectroles/types';

// -- TODO --
// Remove the key index signature type. It´s just there to prevent an failure with the
// validate utils function
interface CreateADUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  password_repeat?: string;
  active?: boolean;
  address?: string;
  avatar_url?: string;
  company_id: number;
  phone?: string;
  project_roles?: Array<number>;
  company_roles?: Array<number>;
}

const CreateADUserForm: FC<InjectedFormProps<CreateADUserFormData>> = ({
  submitting,
  pristine,
  reset,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const { companies } = useSelector((state: StateType) => state.adcompanies);
  const dispatch = useDispatch();

  const croles_loading = useSelector(
    (state: StateType) => state.adcompanyroles.loading
  );
  const companyRoles = useSelector(
    (state: StateType) => state.adcompanyroles.roles
  );

  const proles_loading = useSelector(
    (state: StateType) => state.adprojectroles.loading
  );
  const projectRoles = useSelector(
    (state: StateType) => state.adprojectroles.roles
  );

  const modal = useSelector((state: StateType) => state.modal);
  const companiesList =
    companies && companies.length > 0
      ? companies.map(company => {
        return {
          label: company.name,
          value: company.id,
        };
      })
      : [];

  const loadCompanyRoles = (company: any) => {
    dispatch({ type: GET_ADCOMPANYROLES });
  };

  const loadProjectRoles = (project: any) => {
    dispatch({ type: GET_ADPROJECTROLES });
  };

  const title = !modal.edit
    ? t('users:modal.create_new_user')
    : t('users:modal.update_user');

  pristine = useIsEqualForm('user-create');

  const [loading, createUserLoading] = useLoading(CREATE_ADUSER);

  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader>
        <h1>
          <FontAwesomeIcon icon={['fal', 'user-plus']} fixedWidth />
          {title}
        </h1>
      </ModalHeader>
      <ModalBody>
        <FieldRow>
          <StyledSingleFormSelect
            label={t('users:fields.company_id')}
            name="company_id"
            options={companiesList}
            onChange={(company: any) => {
              loadCompanyRoles(company);
            }}
          />
        </FieldRow>
        <FieldRow>
          <Field
            name="firstName"
            type="text"
            label={t('users:fields.firstName')}
          />
          <Field
            name="lastName"
            type="text"
            label={t('users:fields.lastName')}
          />
        </FieldRow>
        <FieldRow>
          <Field name="email" type="email" label={t('users:fields.email')} />
        </FieldRow>
        <FieldRow>
          <Field
            name="password"
            type="password"
            label={t('users:fields.password')}
          />
          <Field
            name="password_repeat"
            type="password"
            label={t('users:fields.password_repeat')}
          />
        </FieldRow>
        <FieldRow>
          <Field name="address" type="text" label={t('users:fields.address')} />
        </FieldRow>
        <FieldRow>
          <Field name="phone" type="tel" label={t('users:fields.phone')} />
        </FieldRow>
        <FieldRow>
          <StyledSingleFormSelect
            label={t('users:fields.company_roles')}
            name="company_roles"
            options={
              companyRoles
                ? companyRoles.map(role => {
                  return {
                    label: `${role.name}: ${role.company.name}`,
                    value: role.id,
                  };
                })
                : []
            }
            multiple={true}
          />
        </FieldRow>
        <FieldRow>
          <StyledSingleFormSelect
            label={t('users:fields.project_roles')}
            name="project_roles"
            options={
              projectRoles
                ? projectRoles.map(role => {
                  return {
                    label: `${role.name}: ${role.project.name}`,
                    value: role.id,
                  };
                })
                : []
            }
            multiple={true}
          />
        </FieldRow>
        <FieldRow>
          <StyledCheckboxTable />
        </FieldRow>{!pristine && (
        <ModalFooter>
          <Button
            type="submit"
            variant={Variant.Success}
            disabled={!(!pristine || submitting)}
            loading={createUserLoading ? createUserLoading : false}
          >
            {t('common:modal.save_user')}
          </Button>
          <Button
            type="button"
            variant={Variant.Default}
            disabled={pristine || submitting}
            onClick={reset}
          >
            {t('common:button.cancel')}
          </Button>
        </ModalFooter>
      )}
      </ModalBody>

    </form>
  );
};

const CheckboxTable = props => {
  return (
    <table className={props.className}>
      <thead>
        <tr>
          <th>User Active</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Field name="active.ACTIVE" type="checkbox" />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const StyledCheckboxTable = styled(CheckboxTable)`
  width: 100%;
  border: 0;
  background: ${({ theme }) => theme.purple2};

  tbody > tr > th {
    text-align: right;
  }

  thead > tr > th {
    width: 25%;
  }

  thead > tr > th:first-child {
    max-width: 100px;
  }

  tbody > tr > td > div {
    align-items: center;
  }

  thead {
    background: rgba(0, 0, 0, 0.1);
    border-top-left-radius: ${({ theme }) => theme.table.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.table.borderRadius};
    box-shadow: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    th {
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1.5px;
      padding: 15px 20px;
      color: ${({ theme }) => rgba(theme.purple6, 0.6)};
    }
  }

  tbody {
    border-bottom-left-radius: ${({ theme }) => theme.table.borderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.table.borderRadius};

    td {
      padding: 15px 20px;
      font-size: 14px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.01);
    }
  }
`;

export default reduxForm<CreateADUserFormData>({
  form: 'user-create',
  enableReinitialize: false,
})(CreateADUserForm);
