import React, { FC, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';

import Background from 'components/ui/Background/Background';

import { userManager } from 'utils/userManager';
import { userSignedOut } from 'store/auth/actions';

const Logout: FC<RouteComponentProps> = props => {
  const dispatch = useDispatch();
  const { history } = props;

  useEffect(() => {
    dispatch(userSignedOut());
    userManager
      .removeUser()
      .then(() => userManager.signoutRedirect())
      .catch(() => history.push('/login'));
  }, [dispatch, history]);

  return <Background />;
};

export default withRouter(Logout);
