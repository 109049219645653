import styled from 'styled-components';

export const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize4};
  padding: 15px 0 5px 0;
`;
export const StyledListItem = styled.li`
  display: flex;

  & + & {
    margin-top: 25px;
  }

  .list-item__media {
    display: inline-block;
    padding-right: 10px;

    > img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
  }

  .list-item__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div + div {
      margin-top: 5px;
    }
  }
`;
