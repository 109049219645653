import { findIndex } from 'lodash';

import {
  GET_DATA_POINTS_BY_SEARCH,
  GET_DATA_POINTS_FULFILLED,
  GET_DATA_POINTS_PENDING,
  GET_DATA_POINTS_REJECTED,
  GET_TIMESERIES_FULFILLED,
  GET_TIMESERIES,
  GET_TIMESERIES_PENDING,
  GET_TIMESERIES_REJECTED,
  GET_TIMESERIES_REFETCH_FULFILLED,
  REMOVE_TIMESERIES,
  GET_DATA_POINTS_BY_SEARCH_FULFILLD,
  GET_DATA_POINTS_BY_SEARCH_PENDING,
  GET_DATA_POINTS_BY_SEARCH_REJECTED,
  SET_FAVORITE_DATA_POINT,
  REMOVE_FAVORITE_DATA_POINT,
  DataPointState,
  DataPointActionTypes,
  CHANGE_CHART_TYPE,
  ChartType,
} from 'store/datapoint/types';
import { CHANGE_SELECTED_PROJECT } from 'store/project/types';

const initialState: DataPointState = {
  loading: false,
  timeseries: [],
  dataPoints: [],
  chartType: ChartType.line,
};

export default function ProjectReducer(
  state = initialState,
  action: DataPointActionTypes
) {
  switch (action.type) {
    case GET_DATA_POINTS_BY_SEARCH:
      return {
        ...state,
        search: action.search,
      };

    case GET_DATA_POINTS_FULFILLED:
    case GET_DATA_POINTS_BY_SEARCH_FULFILLD:
      return {
        ...state,
        loading: false,
        dataPoints: [
          ...action.dataPointIDs
            .filter(value => findIndex(state.timeseries, ['id', value]) < 0)
            .map(value => ({
              id: value,
              loading: false,
              selected: findIndex(state.timeseries, ['id', value]) > -1,
              favorite: findIndex(action.favorites, o => o === value) > -1,
            })),
          ...state.dataPoints.filter(value => value.selected),
        ],
        projectID: action.projectID.toString(),
      };
    case GET_TIMESERIES:
      if (action.chartType === ChartType.heat) {
        return {
          ...state,
          timeseries: [],
          dataPoints: state.dataPoints.map(dataPoint => {
            if (action.dataPointID === dataPoint.id) {
              return {
                ...dataPoint,
                loading: true,
                selected: true,
              };
            }
            return {
              ...dataPoint,
              selected: false,
            };
          }),
        };
      }
      return state;

    case GET_TIMESERIES_PENDING:
      return {
        ...state,
        // Map over data points and set loading and selected to true
        dataPoints: state.dataPoints.map(dataPoint => {
          if (action.dataPointID === dataPoint.id) {
            return {
              ...dataPoint,
              loading: true,
              selected: true,
            };
          }
          return dataPoint;
        }),
      };

    case GET_TIMESERIES_FULFILLED:
      return {
        ...state,
        loading: false,
        timeseries: [...state.timeseries, action.timeseries],
        // Map over data points and set loading to false
        dataPoints: state.dataPoints.map(dataPoint => {
          if (action.timeseries.id === dataPoint.id) {
            return {
              ...dataPoint,
              loading: false,
              selected: true,
            };
          }
          return dataPoint;
        }),
      };

    case GET_TIMESERIES_REFETCH_FULFILLED:
      const timeseries = state.timeseries.map(series => {
        if (series.id === action.timeseries.id) {
          return action.timeseries;
        }
        return series;
      });
      return { ...state, loading: false, timeseries: timeseries };

    case REMOVE_TIMESERIES:
      return {
        ...state,
        timeseries: state.timeseries.filter(e => e.id !== action.dataPointID),
        // Map over data points and set loading and selected to false
        dataPoints: state.dataPoints.map(dataPoint => {
          if (action.dataPointID === dataPoint.id) {
            return {
              ...dataPoint,
              loading: false,
              selected: false,
            };
          }
          return dataPoint;
        }),
      };
    case GET_DATA_POINTS_PENDING:
      return { ...state, loading: true, search: '' };
    case GET_DATA_POINTS_BY_SEARCH_PENDING:
      return { ...state, loading: true };

    case GET_DATA_POINTS_REJECTED:
    case GET_TIMESERIES_REJECTED:
    case GET_DATA_POINTS_BY_SEARCH_REJECTED:
      return { ...state, loading: false, error: true };
    case CHANGE_CHART_TYPE:
      return {
        ...state,
        chartType: action.chartType,
        timeseries: [],
        dataPoints: state.dataPoints.map(dataPoint => ({
          ...dataPoint,
          selected: false,
        })),
      };
    case CHANGE_SELECTED_PROJECT:
      const projectID = action.projectID.toString();
      return {
        ...state,
        projectID: projectID,
        timeseries: state.projectID !== projectID ? [] : state.timeseries,
        dataPoints:
          state.projectID !== projectID
            ? state.dataPoints.filter(dataPoint => !dataPoint.selected)
            : state.dataPoints,
      };
    case SET_FAVORITE_DATA_POINT:
      return {
        ...state,
        dataPoints: state.dataPoints.map(dataPoint => {
          if (action.dataPointID === dataPoint.id) {
            return {
              ...dataPoint,
              favorite: true,
            };
          }
          return dataPoint;
        }),
      };
    case REMOVE_FAVORITE_DATA_POINT:
      return {
        ...state,
        dataPoints: state.dataPoints.map(dataPoint => {
          if (action.dataPointID === dataPoint.id) {
            return {
              ...dataPoint,
              favorite: false,
            };
          }
          return dataPoint;
        }),
      };
    default:
      return state;
  }
}
