import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';

import rootSaga from 'store/sagas';
import reducers from 'store/reducers';
import { loadUser } from 'utils/userManager';
import notificationMiddleware from 'store/middleware/notification';

let sentryReduxEnhancer;

if (process.env.REACT_APP_SENTRY_DSN) {
  sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: state => {
      return {
        ...state,
        user: null,
        auth: null,
      };
    },
  });
}

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {};

// Create saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create the redux store
const store = createStore(
  reducers,
  initialState,
  process.env.REACT_APP_SENTRY_DSN && sentryReduxEnhancer
    ? composeEnhancers(
        applyMiddleware(sagaMiddleware, notificationMiddleware),
        sentryReduxEnhancer
      )
    : composeEnhancers(applyMiddleware(sagaMiddleware, notificationMiddleware))
);

// Start Saga
sagaMiddleware.run(rootSaga);

// Try to load the user before export
loadUser(store);

export default store;
