import {
  GET_ADUSERS_FULFILLED,
  GET_ADUSERS_REJECTED,
  GET_ADUSERS_PENDING,
  ADUsersActionTypes,
  ADUsersState,
  SAVE_ADUSER_FULFILLED,
  DELETE_ADUSER_FULFILLED,
  CREATE_ADUSER_FULFILLED,
} from 'store/adusers/types';

const initialState: ADUsersState = {
  loading: false,
  users: null,
};

export default (state = initialState, action: ADUsersActionTypes) => {
  switch (action.type) {
    case GET_ADUSERS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_ADUSERS_FULFILLED:
      return {
        ...state,
        loading: false,
        users: action.data,
      };
    case GET_ADUSERS_REJECTED:
      return {
        ...state,
        loading: false,
      };
    case SAVE_ADUSER_FULFILLED:
      /*
       * Find the user
       * */
      const user_ = state.users.findIndex(user => user.id === action.data.id);

      /*
       * Check if the user is in the right company
       * */
      const found_same_company = state.users.find(
        user => user.company.id === action.data.company.id
      );

      if (found_same_company) {
        state.users.splice(user_, 1, action.data);
      } else {
        state.users.splice(user_, 1);
      }
      return {
        ...state,
        loading: false,
        users: state.users,
      };
    case CREATE_ADUSER_FULFILLED:
      const users_list = state.users
        ? state.users.find(
          user => user.company_id == action.data.company_id
        )
          ? state.users.concat(action.data)
          : state.users
        : [action.data];

        return {
          users: users_list,
          loading: false,
        };
      break;
    case DELETE_ADUSER_FULFILLED:
        state.users.splice(
          state.users.findIndex(user => user.id === action.data),
          1
        );
        return {
          ...state,
          loading: false,
        };
    default:
      return state;
  }
};
