import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Section } from 'components/ui/Section/Section';
import Table from 'components/ui/Table/Table';
import { StateType } from 'store/types';
import { ClassicSpinner } from 'react-spinners-kit';
import StyledSpinner from 'components/ui/Spinner/StyledSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/pro-light-svg-icons';
import ModalContainer from 'container/CompanyDetails/ModalContainer';
import ConfirmCompanyModalForm from 'container/Forms/ConfirmCompanyModalForm';
import { validateWithCallback } from 'utils/form';
import {
  DELETE_ADCOMPANY,
  CREATE_ADCOMPANY,
  SAVE_ADCOMPANY,
  EDIT_ADCOMPANY,
} from 'store/companies/types';
import CreateCompany from 'container/Forms/CreateCompanyForm';
import { CardWrapper } from 'components/ui/Card/styles/CardStyles';
import { EmptyCard } from 'components/ui/Card/Card';
import { __RouterContext } from 'react-router';

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector((state: StateType) => state.modal);
  const { loading, companies } = useSelector(
    (state: StateType) => state.adcompanies
  );
  const { history } = useContext(__RouterContext);

  return (
    <>
      <h1>{t('companies:index.title')}</h1>
      <Section title={t('companies:index.companies.title')}>
        {companies && (
          <Table
            data={companies}
            showPagination={true}
            showPaginationTop={true}
            showPaginationBottom={false}
            defaultPageSize={7}
            minRows={7}
            sortable={true}
            columns={[
              {
                Header: '#ID',
                accessor: 'id',
              },
              {
                Header: 'Name',
                accessor: 'name',
              },
              {
                Header: 'Description',
                accessor: 'description',
              },
              {
                Header: 'Address',
                accessor: 'address',
              },
              {
                Header: 'Phone Number',
                accessor: 'phone',
              },
              {
                Header: 'Actions',
                accessor: 'id',
                maxWidth: 100,
                Cell: row => (
                  <>
                    <a
                      style={{
                        color: 'rgba(255, 255, 255, .5)',
                        marginRight: '1em',
                      }}
                      href="#"
                      onClick={ev => {
                        ev.preventDefault();
                        dispatch({
                          type: EDIT_ADCOMPANY,
                          data: row.value,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </a>
                    <a
                      style={{ color: 'rgba(255, 255, 255, .5)' }}
                      href="#"
                      onClick={ev => {
                        ev.preventDefault();
                        dispatch({
                          type: DELETE_ADCOMPANY,
                          data: row.value,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                  </>
                ),
              },
            ]}
          />
        )}
        <CardWrapper>
          <EmptyCard
            icon="building"
            iconprefix="fal"
            label={t('common:modal.create_new_company')}
            onClick={event => {
              dispatch({
                type: 'TOGGLE_MODAL',
                selected: 'company',
              });
              event.stopPropagation();
              event.preventDefault();
            }}
          />
        </CardWrapper>
        {loading && (
          <StyledSpinner>
            <ClassicSpinner size={40} />
          </StyledSpinner>
        )}
      </Section>
      <ModalContainer
        identifier={'confirm-delete-company'}
        Component={ConfirmCompanyModalForm}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              delete: 'required|equals:DELETE',
            },
            () => dispatch({ type: 'CONFIRM_DELETE' })
          )
        }
      />
      <ModalContainer
        identifier={'company'}
        Component={CreateCompany}
        isEdit={modal.edit}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              name: 'required',
              description: 'required',
              phone: 'string',
              address: 'string',
              avatar_url: 'string',
              slug: 'required'
            },
            () => {
              if (modal.edit) {
                dispatch({ type: SAVE_ADCOMPANY, data: values });
              } else {
                dispatch({
                  type: CREATE_ADCOMPANY,
                  data: values,
                  his: history,
                });
              }
            }
          )
        }
      />
    </>
  );
};
