import React, { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ModalBody, ModalFooter, ModalHeader } from 'components/ui/Modal/Modal';

import Field, {
  StyledReduxFormSelect,
  FieldRow,
} from 'components/ui/Form/Form';
import { Button, Variant } from 'components/ui/Button/Button';
import { useSelector } from 'react-redux';
import { StateType } from 'src/store/types';
import { useIsEqualForm } from 'utils/hooks';

// -- TODO --
// Remove the key index signature type. It´s just there to prevent an failure with the
// validate utils function
interface AssignProjectRolesFormData {
  projects: any;
  [key: string]: number | string;
}

interface ProjectFormProps {
  isEdit?: boolean;
}

type CombinedProjectFormProps = ProjectFormProps &
InjectedFormProps<AssignProjectRolesFormData, ProjectFormProps>;

const AssignProjectRoleForm: FC<CombinedProjectFormProps> = ({
  submitting,
  pristine,
  reset,
  handleSubmit,
  isEdit = false,
}) => {
  const { t } = useTranslation();
  const title = isEdit
    ? t('common:modal.edit_assigned_projects')
    : t('common:modal.assign_projects');
  const button = isEdit
    ? t('common:modal.edit_projects')
    : t('common:modal.save_projects');
  const form = useSelector(
    (state: StateType) => state.form['project-roles-edit']
  );
  pristine = useIsEqualForm('project-roles-edit');

  const { project: projectRoles } = useSelector(
    (state: StateType) => state.roles
  );
  const { company: companyProjects } = useSelector(
    (state: StateType) => state.projects
  );

  const companyProjectsOptions =
    projectRoles &&
    Object.values(projectRoles).map(el => ({
      value: el.id,
      label: `${companyProjects[projectRoles[el.id].project_id].name} (${projectRoles[el.id].name})`,
    }));

  const defaultValue =
    isEdit && form.values ? form.values.default_projects : [];

  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader>
        <h1>
          <FontAwesomeIcon icon={['fal', 'user-plus']} fixedWidth />
          {title}
        </h1>
      </ModalHeader>
      <ModalBody>
        <FieldRow>
          <Field type="hidden" name="user" />
          <Field
            label={t('common:modal.assign_to_projects')}
            name="projects"
            component={StyledReduxFormSelect}
            options={companyProjectsOptions}
            defaultValue={defaultValue}
          />
        </FieldRow>
      </ModalBody>
      {!pristine && (
        <ModalFooter>
          <Button type="submit" variant={Variant.Success} disabled={submitting}>
            {button}
          </Button>
          <Button
            type="button"
            variant={Variant.Default}
            disabled={submitting}
            onClick={reset}
          >
            {t('common:button.cancel')}
          </Button>
        </ModalFooter>
      )}
    </form>
  );
};

export default reduxForm<AssignProjectRolesFormData, ProjectFormProps>({
  form: 'project-roles-edit',
  enableReinitialize: true,
})(AssignProjectRoleForm);
