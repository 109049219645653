import { ADProject } from 'store/adprojects/types';

/*
 * Action Types
 * */
export const GET_ADPROJECTROLES = 'GET_ADPROJECTROLES';
export const GET_ADPROJECTROLES_PENDING = 'GET_ADPROJECTROLES_PENDING';
export const GET_ADPROJECTROLES_FULFILLED = 'GET_ADPROJECTROLES_FULFILLED';
export const GET_ADPROJECTROLES_REJECTED = 'GET_ADPROJECTROLES_REJECTED';

/*
 * Models
 * */
export interface ADProjectrole {
  id: number;
  name: string;
  description: string;
  endpoints: Array<number>;
  protected: boolean;
  project: ADProject;
  tagauths: Array<number>;
}

/*
 * State Models
 * */
export interface ADProjectrolesState {
  readonly loading: boolean;
  readonly roles: Array<ADProjectrole>;
}

/*
 * Actions
 * */
interface GetADProjectrolesAction {
  type: typeof GET_ADPROJECTROLES;
  data: number | undefined; // project_id
}

interface GetADProjectrolesPendingAction {
  type: typeof GET_ADPROJECTROLES_PENDING;
}

interface GetADProjectrolesFulfilledAction {
  type: typeof GET_ADPROJECTROLES_FULFILLED;
  data: Array<ADProjectrole>;
}

interface GetADProjectrolesRejected {
  type: typeof GET_ADPROJECTROLES_REJECTED;
}

/*
 * Export Types
 * */
export type ADProjectroleActionTypes =
  | GetADProjectrolesAction
  | GetADProjectrolesPendingAction
  | GetADProjectrolesFulfilledAction
  | GetADProjectrolesRejected;
