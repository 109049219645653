import styled from 'styled-components';
import get from 'lodash/get';
import { position } from 'polished';

export enum Variant {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warn = 'warn',
}

export enum Position {
  TopRight = 'top-right',
  TopLeft = 'top-left',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
}

export const StyledBadge = styled.div`
  position: relative;
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSize4};
`;

// @ts-ignore
export const StyledCount = styled.span`
  border-radius: 100vw;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;

  min-width: 15px;
  min-height: 15px;
  padding: 0 3px 2px 3px;

  text-align: center;
  font-size: 11px;

  background: ${({ theme, variant }: { theme: any; variant: Variant }) =>
    get(theme, `notification.badge.${variant}`, '#E50046')};

  ${({
    shadow,
    theme,
    variant,
  }: {
    shadow: boolean;
    theme: any;
    variant: Variant;
  }) =>
    maybeShadow({
      should: shadow,
      color: get(theme, `notification.badge.${variant}`, '#E50046'),
    })};

  // @ts-ignore
  ${({ theme, position }: { theme: any, position: Position }) =>
    maybePosition({ alignment: position })};
`;

const maybeShadow = ({ should, color }: { should: boolean; color: string }) => {
  return should ? `box-shadow: 0px 0px 6px ${color};` : '';
};

const maybePosition = ({
  alignment = Position.TopRight,
}: {
  alignment?: Position;
}) => {
  const zIndex = 1000;
  const drag = '-8px';
  switch (alignment) {
    case 'top-left':
      return {
        zIndex,
        ...position('absolute', drag, null, null, 0),
      };
    case 'bottom-left':
      return {
        zIndex,
        ...position('absolute', null, null, drag, 0),
      };
    case 'bottom-right':
      return {
        zIndex,
        ...position('absolute', null, 0, drag, null),
      };
    default:
      return {
        zIndex,
        ...position('absolute', drag, 0, null, null),
      };
  }
};
