import React, { FC, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Background from 'components/ui/Background/Background';
import { userManager } from 'utils/userManager';
import { URN } from 'constants/storageKeys';

/**
 * Component for the oidc authentication callback.
 *
 * @param {object} props -
 */
const AuthCallback: FC<RouteComponentProps> = props => {
  useEffect(() => {
    const currentUrl = window.location.href;
    userManager
      .signinRedirectCallback(currentUrl)
      .then(() => {
        // Try to get urn from session storage and remove it to avoid side effects
        const prevPath = sessionStorage.getItem(URN);
        sessionStorage.removeItem(URN);
        props.history.push(prevPath ? prevPath : '/');
      })
      .catch(err => {
        console.error(err);
        userManager.signinRedirect();
      });
  }, [props.history]);

  return <Background />;
};

export default withRouter(AuthCallback);
