import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  StyledDropzone,
  StyledImageContainer,
} from 'components/ui/ImageDropzone/styles/ImageDropzoneStyles';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { showNotification } from 'store/notification/actions';
import { useTranslation } from 'react-i18next';
import theme from '../../../config/themes/default';

interface Props {
  handleImageUpload?: () => void;
  imagePath?: string;
  disablePen?: boolean;
  dispatch: string;
}

const ImageDropzone = (props: Props) => {
  let status: boolean = false;
  const [avatarNotLoaded, setAvatarNotLoaded] = useState(null);
  const [isPlaceholderLoaded, setIsPlaceholderLoaded] = useState(null);
  const { disablePen = false } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onDrop = useCallback(
    acceptedFiles => {
      dispatch({
        type: props.dispatch,
        files: acceptedFiles,
      });
    },
    [dispatch, props.dispatch]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ['image/png', 'image/jpg', 'image/jpeg'],
    maxSize: 1000000,
    multiple: false,
    onDropRejected: files => {
      dispatch(
        showNotification({
          identifier: 'image-upload-notification',
          type: 'error',
          text: t('common:notification.image_upload_failed'),
          autoClose: 5000,
        })
      );
    },
  });

  if (
    props.imagePath &&
    avatarNotLoaded !==  null &&
    avatarNotLoaded !== props.imagePath
  ) {
    status = true;
  }

  if (!props.imagePath || isPlaceholderLoaded === 'placeholder') {
    if (status) {
      setIsPlaceholderLoaded(null);
    }

    return (
      <div>
        {isPlaceholderLoaded === 'placeholder' &&
        <div style={{ marginBottom: 15, color: theme.orange, fontSize: 14 }}>
          {t('users:notification.user_avatar_not_loaded')}
        </div>}
        <StyledDropzone>
          <div className="dz-container" {...getRootProps()}>
            <input {...getInputProps()} />
            <div>
              <FontAwesomeIcon icon={['fal', 'file-upload']} size="3x" />
              <br />
              Drag & drop or {''}
              <span className="browse-hint">browse</span>
              <br />
              <span className="accpted-types-text">
                JPG and PNG files <br />
                are supported
              </span>
            </div>
          </div>
        </StyledDropzone>
      </div>
    );
  } else {
    return (
      <StyledImageContainer>
        <img
          src={props.imagePath}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = 'assets/images/placeholder-avatar.svg';
            e.target.alt = 'placeholder';
            setIsPlaceholderLoaded(e.target.getAttribute('alt'));
            setAvatarNotLoaded(props.imagePath);
          }}
        />
        {!disablePen ? (
          <div className="dz-container" {...getRootProps()}>
            <input {...getInputProps()} />
            <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
          </div>
        ) : (
          ''
        )}
      </StyledImageContainer>
    );
  }
};

export default ImageDropzone;
