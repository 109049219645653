import React, { FC } from 'react';
import { StyledBackground } from 'components/ui/Background/styles/BackgroundStyles';

interface Props {
  centerContent?: boolean;
}

/**
 * Background component renders the primary background in an container with 100vh height
 * Also it is possible to add some child content
 *
 * @param {object} props - optional centerContent boolean and children
 * @returns {ReactNodeArray} StyledBackground and child components
 */
const Background: FC<Props> = props => (
  <StyledBackground centerContent={props.centerContent}>
    {props.children}
  </StyledBackground>
);

export default Background;
