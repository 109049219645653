import { toast } from 'react-toastify';
import get from 'lodash/get';
import { Dispatch } from 'redux';

import { ActionTypes } from 'store/types';
import { SHOW_NOTIFICATION } from 'store/notification/types';

/**
 * Notification middleware
 */
export default () => (next: Dispatch) => (action: ActionTypes) => {
  if (action.type === SHOW_NOTIFICATION) {
    const text = get(action, 'options.text', '');
    const type = get(action, 'options.type', 'success');
    const autoClose = get(action, 'options.autoClose', false);
    const identifier = get(action, 'options.identifier', 'global-notification');

    toast(text, {
      toastId: identifier,
      position: toast.POSITION.TOP_RIGHT,
      autoClose,
      type,
    });
  }

  return next(action);
};
