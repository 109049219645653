import { all, take, fork, select } from 'redux-saga/effects';
import { reduce } from 'lodash';

import endpointSaga from 'store/endpoints/saga';
import dataPointSaga from 'store/datapoint/saga';
import userSaga from 'store/user/saga';
import companySaga from 'store/company/saga';
import projectSaga from 'store/project/saga';
import rolesSaga from 'store/roles/saga';
import * as Sentry from '@sentry/react';

/**
 * Added for the Admin Dashboard
 * */
import adcompaniesSaga from 'store/companies/saga';
import adprojectsSaga from 'store/adprojects/saga';
import adusersSaga from 'store/adusers/saga';
import adcompanyrolesSaga from 'store/adcompanyroles/saga';
import adprojectrolesSaga from 'store/adprojectroles/saga';
import flowSaga from 'store/flow/saga';
import initializerSaga from 'store/initializers/saga';

/*
 * @TODO: import all_users saga
 * */

function* spawn() {
  /*
   * @TODO: call all_users saga ->
   *   fetches all available users
   * */
  yield all([projectSaga(), rolesSaga(), dataPointSaga(), adcompaniesSaga()]);
}

function* watcher() {
  yield take('USER_FOUND');
  yield fork(endpointSaga);
  yield take('GET_ENDPOINT_ENUMERATIONS_FULFILLED');
  yield fork(userSaga);
  yield fork(adprojectrolesSaga)

  // if this gets more complicated, we need to refactor this to a helper function
  yield take('GET_USER_FULFILLED');
  const user = yield select(state => state.user.details);
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.setUser({ id: user.id });
  }
  const roles = yield select(state => state.user.details.companyRoles);
  const available = reduce(
    roles,
    (acc, authed) => {
      acc = [...acc, ...authed.authed_endpoints];
      return acc;
    },
    []
  );
  const endpoints = yield select(state => state.endpoints);

  if (available.includes(endpoints['GET_COMPANY'].id)) {
    yield fork(companySaga);
  }

  yield fork(spawn);
}

export default function* rootSaga() {
  yield all([
    watcher(),
    adprojectsSaga(),
    adusersSaga(),
    adcompanyrolesSaga(),
    flowSaga(),
    initializerSaga(),
  ]);
}
