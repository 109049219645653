import styled from 'styled-components';
import { rgba } from 'polished';
import { Link } from 'react-router-dom';

export const StyledUserBar = styled.div`
  display: flex;
  margin-left: 50px;
  align-self: center;
  justify-content: flex-end;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    line-height: 1.5;

    &:first-child {
      padding-right: 15px;
    }
  }
`;

export const StyledHorizontal = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  > svg {
    margin-left: 10px;
  }
`;

export const StyledUsername = styled.div`
  font-size: ${({ theme }) => theme.fontSize4};
  font-weight: bold;
`;

export const StyledCompany = styled.div`
  font-size: ${({ theme }) => theme.fontSize5};
  color: ${({ theme }) => rgba(theme.purple6, 0.8)};
`;

export const StyledUserAvatar = styled.div`
  max-width: 40px;
  max-height: 40px;
  img {
    border-radius: 100%;
  }
`;

export const StyledLogoutButton = styled.div`
  > a {
    display: block;
    color: white;
    text-decoration: none;
  }
`;

export const StyledLink = styled(Link)`
  text-transform: capitalize;
  font-size: 16px;
  letter-spacing: 0;
`;
