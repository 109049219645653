import React, {
  FC,
  MouseEvent,
  useState,
  createContext,
  useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'react-collapse';

import {
  StyledCard,
  StyledEmptyCard,
} from 'components/ui/Card/styles/CardStyles';

import Dropdown from 'components/ui/Dropdown/Dropdown';
import { ActionTypes } from 'store/types';

interface CardProps {
  type?: string;
  alignment?: string;
  as?: string;
  className?: string;
}

interface CardFooterProps {
  iconprefix?: string;
  icon?: string;
  amount?: number;
  label: string;
  overlay?: boolean;
  as?: string;
  displayRightIcon?: boolean;
  onClick?: (event?: MouseEvent<HTMLDivElement>) => void;
  index?: number;
}

interface CardHeaderProps {
  src?: string;
}

interface CardActionsProps {
  header?: string;
  items?: {
    to?: string;
    dispatch?: ActionTypes | (() => void);
    label: string;
  }[];
}

interface EmptyCardProps {
  icon?: any; // font awesome props?
  iconprefix?: any; // font awesome props?
  label: string;
  onClick?: (event?: MouseEvent<HTMLDivElement>) => void;
}

const CardContext = createContext({
  opened: false,
  setOpened: null,
});

export const CardHeader: FC<CardHeaderProps> = ({ src }) => {
  return (
    <div className="card__header">
      <figure>
        <img src={src} alt="" />
      </figure>
    </div>
  );
};

export const CardActions: FC<CardActionsProps> = ({
  items,
  header = 'User Actions',
}) => {
  return (
    <div className="card__actions">
      <Dropdown
        component={<FontAwesomeIcon icon={['far', 'ellipsis-v']} fixedWidth />}
        items={items}
        placement={'bottom'}
        header={header}
      />
    </div>
  );
};

export const CardBody: FC = ({ children }) => (
  <div className="card__body">{children}</div>
);

export const CardFooter: FC<CardFooterProps> = ({
  iconprefix = 'fal',
  icon = 'building',
  amount = null,
  label = null,
  children = null,
  overlay = true,
  as = 'dropdown',
  displayRightIcon = true,
  onClick = null,
  index = 0,
}) => {
  const { opened, setOpened } = useContext(CardContext);

  switch (as) {
    case 'button':
      return (
        <div className={`card__footer ${overlay ? '--overlay' : ''}`}>
          <div onClick={onClick} className="card__footer-opener">
            {icon && (
              <span className="card__projects-icon">
                {/*
                // @ts-ignore */}
                <FontAwesomeIcon icon={[iconprefix, icon]} fixedWidth />
              </span>
            )}
            {label && (
              <span className="card__projects-description">{label}</span>
            )}
            <span
              className={`card__projects-arrow ${
                displayRightIcon ? '' : '--hide'
              }`}
            >
              <FontAwesomeIcon icon={['fal', 'plus-square']} fixedWidth />
            </span>
          </div>
        </div>
      );
    default:
      return (
        <div
          className={`card__footer ${overlay ? '--overlay' : ''}`}
          style={{
            zIndex: 300 - index,
          }}
        >
          <div
            onClick={event => {
              setOpened(!opened);
              event.preventDefault();
            }}
            className="card__footer-opener"
          >
            {icon && (
              <span className="card__projects-icon">
                {/*
                // @ts-ignore */}
                <FontAwesomeIcon icon={[iconprefix, icon]} fixedWidth />
              </span>
            )}
            {amount && <span className="card__projects-number">{amount}</span>}
            {label && (
              <span className="card__projects-description">{label}</span>
            )}
            <span className="card__projects-arrow">
              {opened ? (
                <FontAwesomeIcon icon={['fas', 'caret-up']} fixedWidth />
              ) : (
                <FontAwesomeIcon icon={['fas', 'caret-down']} fixedWidth />
              )}
            </span>
          </div>
          <Collapse isOpened={opened}>
            <div className="card__footer-body">{children}</div>
          </Collapse>
        </div>
      );
  }
};

export const Card: FC<CardProps> = ({
  type,
  alignment,
  children,
  className,
}) => {
  const [opened, setOpened] = useState(false);
  const existingChildren = React.Children.map(
    children,
    (child: any) => child && child.type.name
  );

  return (
    <CardContext.Provider value={{ opened, setOpened }}>
      <StyledCard
        className={`${type ? `--type-${type}` : ''} ${
          className !== undefined ? className : ''
        }`}
      >
        <div
          className={`${type ? `--type-${type}` : ''} ${
            alignment ? `--align-${alignment}` : ''
          }
          ${
    existingChildren && !existingChildren.includes('CardFooter')
      ? '--no-footer'
      : ''
    }
          ${
    existingChildren && !existingChildren.includes('CardHeader')
      ? '--no-header'
      : ''
    }`}
        >
          {children}
        </div>
      </StyledCard>
    </CardContext.Provider>
  );
};

export const EmptyCard: FC<EmptyCardProps> = props => (
  <StyledEmptyCard className={'--empty-card'} {...props}>
    <div className="card__button" {...props} onClick={props.onClick}>
      <div className="card__button-icon">
        <FontAwesomeIcon
          icon={[props.iconprefix, props.icon]}
          size={'3x'}
          fixedWidth
        />
      </div>
      <div className="card__button-label">{props.label}</div>
    </div>
  </StyledEmptyCard>
);
