import React, { FC } from 'react';
import ReactTable, { TableProps } from 'react-table';

import { StyledTable } from 'components/ui/Table/styles/TableStyles';

import 'react-table/react-table.css';

// yea, TableProps is fine, because then its like: loading, showPaginationTop, showPaginationBottom, manual, and 106 more properties are missing.
const Table: FC<Partial<TableProps>> = props => {
  return (
    <StyledTable>
      <ReactTable {...props} previousText={null} nextText={null} />
    </StyledTable>
  );
};

export default Table;
