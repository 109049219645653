import {
  ADProjectActionTypes,
  ADProjectState,
  CREATE_ADPROJECT_FULFILLED,
  CREATE_ADPROJECT_PENDING,
  EDIT_ADPROJECT_FULFILLED,
} from 'store/adprojects/types';

const initialState: ADProjectState = {
  loading: false,
  project: null,
};

export default (state = initialState, action: ADProjectActionTypes) => {
  switch (action.type) {
    case CREATE_ADPROJECT_PENDING:
      return {
        ...state,
        loading: true,
      }
    case CREATE_ADPROJECT_FULFILLED:
      return {
        loading: false,
        project: action.data,
      }
    case EDIT_ADPROJECT_FULFILLED:
      return {
        loading: false,
        project: action.data,
      };
    default:
      return state;
  }
};
