/*
 * Action types
 * */
import {
  UPLOAD_COMPANY_AVATAR,
  UPLOAD_COMPANY_AVATAR_FULFILLED,
} from 'src/store/company/types';
import * as H from 'history';

export const GET_ADPROJECTS = 'GET_ADPROJECTS';
export const GET_ADPROJECTS_PENDING = 'GET_ADPROJECTS_PENDING';
export const GET_ADPROJECTS_FULFILLED = 'GET_ADPROJECTS_FULFILLED';
export const GET_ADPROJECTS_REJECTED = 'GET_ADPROJECTS_REJECTED';

export const GET_ADPROJECT = 'GET_ADPROJECT';
export const GET_ADPROJECT_PENDING = 'GET_ADPROJECT_PENDING';
export const GET_ADPROJECT_FULFILLED = 'GET_ADPROJECT_FULFILLED';
export const GET_ADPROJECT_REJECTED = 'GET_ADPROJECT_REJECTED';

export const DELETE_ADPROJECT = 'DELETE_ADPROJECT';
export const DELETE_ADPROJECT_PENDING = 'DELETE_ADPROJECT_PENDING';
export const DELETE_ADPROJECT_FULFILLED = 'DELETE_ADPROJECT_FULFILLED';
export const DELETE_ADPROJECT_REJECTED = 'DELETE_ADPROJECT_REJECTED';

export const CREATE_ADPROJECT = 'CREATE_ADPROJECT';
export const CREATE_ADPROJECT_PENDING = 'CREATE_ADPROJECT_PENDING';
export const CREATE_ADPROJECT_FULFILLED = 'CREATE_ADPROJECT_FULFILLED';
export const CREATE_ADPROJECT_REJECTED = 'CREATE_ADPROJECT_REJECTED';

export const SAVE_ADPROJECT = 'SAVE_ADPROJECT';
export const SAVE_ADPROJECT_PENDING = 'SAVE_ADPROJECT_PENDING';
export const SAVE_ADPROJECT_FULFILLED = 'SAVE_ADPROJECT_FULFILLED';
export const SAVE_ADPROJECT_REJECTED = 'SAVE_ADPROJECT_REJECTED';

export const EDIT_ADPROJECT = 'EDIT_ADPROJECT';
export const EDIT_ADPROJECT_PENDING = 'EDIT_ADPROJECT_PENDING';
export const EDIT_ADPROJECT_FULFILLED = 'EDIT_ADPROJECT_FULFILLED';
export const EDIT_ADPROJECT_REJECTED = 'EDIT_ADPROJECT_REJECTED';

export const UPLOAD_ADPROJECT_AVATAR = 'UPLOAD_ADPROJECT_AVATAR';
export const UPLOAD_ADPROJECT_AVATAR_PENDING =
  'UPLOAD_ADPROJECT_AVATAR_PENDING';
export const UPLOAD_ADPROJECT_AVATAR_FULFILLED =
  'UPLOAD_ADPROJECT_AVATAR_FULFILLED';
export const UPLOAD_ADPROJECT_AVATAR_REJECTED =
  'UPLOAD_ADPROJECT_AVATAR_REJECTED';

export const INSPECT_ADPROJECT = 'INSPECT_ADPROJECT';
export const QUICK_CREATE_PROJECT = 'QUICK_CREATE_PROJECT';

/*
 * Models
 * */
export interface ADProject {
  id: number;
  name: string;
  description: string;
  avatar_url: string;
  company_id: number;
  handle: string;
  hash_id_length: number;
  load_balancing_group: string;
  schedules_enabled: boolean;
  setpoints_enabled: boolean;
  setpoints_min_priority: number;
}

export type TMqttUser = {
  name: string;
  password: string;
};

export interface ADProjectResult {
  project: ADProject;
  influx_password: string;
  influx_username: string;
  mqtt_users: TMqttUser[];
  mqtt_topic: string;
  mqtt_topic_controls: string;
  mqtt_topic_meta: string;
}

interface UploadADProjectAvatarResponse {
  src: string;
}

/*
 * State Models
 * */
export interface ADProjectState {
  readonly loading: boolean;
  readonly project?: ADProject | ADProjectResult;
}

export interface ADProjectsState {
  readonly loading: boolean;
  readonly projects?: Array<ADProject>;
}

/*
 * Actions
 * */
interface GetADProjectAction {
  type: typeof GET_ADPROJECT;
  data: number;
}

export interface GetADProjectsAction {
  type: typeof GET_ADPROJECTS;
  data: Array<number>;
}

interface SaveADProjectAction {
  type: typeof GET_ADPROJECT_FULFILLED;
  data: ADProject;
}

interface SaveADProjectsAction {
  type: typeof GET_ADPROJECTS_FULFILLED;
  data: Array<ADProject>;
}

interface PendingADProjectAction {
  type: typeof GET_ADPROJECT_PENDING;
}

interface PendingADProjectsAction {
  type: typeof GET_ADPROJECTS_PENDING;
}

export interface DeleteADProjectAction {
  type: typeof DELETE_ADPROJECT;
  data: number;
}

interface DeleteADProjectPendingAction {
  type: typeof DELETE_ADPROJECT_PENDING;
}

interface DeleteADProjectFulfilledAction {
  type: typeof DELETE_ADPROJECT_FULFILLED;
  data: number;
}

interface CreateADProjectPendingAction {
  type: typeof CREATE_ADPROJECT_PENDING;
}

interface CreateADProjectFulfilledAction {
  type: typeof CREATE_ADPROJECT_FULFILLED;
  data: ADProjectResult;
}

interface SaveADProjectFulfilledAction {
  type: typeof SAVE_ADPROJECT_FULFILLED;
  data: ADProject;
}

interface UploadADProjectAvatar {
  type: typeof UPLOAD_ADPROJECT_AVATAR;
  files: object[];
}

interface UploadADProjectAvatarFulfilled {
  type: typeof UPLOAD_ADPROJECT_AVATAR_FULFILLED;
  data: UploadADProjectAvatarResponse;
}

interface QuickCreateProjectAction {
  type: typeof QUICK_CREATE_PROJECT;
  data: {
    history: H.History;
  };
}

interface EditADProjectFulfilledAction {
  type: typeof EDIT_ADPROJECT_FULFILLED;
  data: ADProject;
}

/*
 * Export actiontypes
 * */
export type ADProjectActionTypes =
  | GetADProjectAction
  | SaveADProjectAction
  | PendingADProjectAction
  | DeleteADProjectAction
  | CreateADProjectPendingAction
  | CreateADProjectFulfilledAction
  | EditADProjectFulfilledAction;

export type ADProjectsActionTypes =
  | GetADProjectsAction
  | SaveADProjectsAction
  | PendingADProjectsAction
  | DeleteADProjectPendingAction
  | DeleteADProjectFulfilledAction
  | CreateADProjectFulfilledAction
  | SaveADProjectFulfilledAction
  | UploadADProjectAvatar
  | UploadADProjectAvatarFulfilled
  | QuickCreateProjectAction;
