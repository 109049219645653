import { CompanyRole, ProjectRole } from 'store/roles/types';

export const GET_USER = 'GET_USER';
export const GET_USER_PENDING = 'GET_USER_PENDING';
export const GET_USER_FULFILLED = 'GET_USER_FULFILLED';
export const GET_USER_REJECTED = 'GET_USER_REJECTED';

export const SAVE_USER = 'SAVE_USER';
export const SAVE_USER_PENDING = 'SAVE_USER_PENDING';
export const SAVE_USER_FULFILLED = 'SAVE_USER_FULFILLED';
export const SAVE_USER_REJECTED = 'SAVE_USER_REJECTED';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_PENDING = 'CREATE_USER_PENDING';
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED';
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_PENDING = 'DELETE_USER_PENDING';
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED';
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED';

export const GET_USER_PROJECTS_VIA_ROLES = 'GET_USER_PROJECTS_VIA_ROLES';
export const GET_USER_PROJECTS_VIA_ROLES_PENDING =
  'GET_USER_PROJECTS_VIA_ROLES_PENDING';
export const GET_USER_PROJECTS_VIA_ROLES_FULFILLED =
  'GET_USER_PROJECTS_VIA_ROLES_FULFILLED';

export const DISABLE_PASSWORD_CHANGE = 'DISABLE_PASSWORD_CHANGE';

export const UPLOAD_USER_AVATAR = 'UPLOAD_USER_AVATAR';
export const UPLOAD_USER_AVATAR_PENDING = 'UPLOAD_USER_AVATAR_PENDING';
export const UPLOAD_USER_AVATAR_FULFILLED = 'UPLOAD_USER_AVATAR_FULFILLED';
export const UPLOAD_USER_AVATAR_REJECTED = 'UPLOAD_USER_AVATAR_REJECTED';

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  address?: string;
  phone?: string;
  avatar_url?: string;
  company_id: number;
  company: string;
  passwordLockedUntil?: string;
}

interface UserSavedResponse {
  id: number;
  company_id: number;
  firstName: string;
  lastName: string;
  address: string;
  email: string;
  phone: string;
}

export interface UserStateWithRoles extends User {
  roles?: ProjectRole[];
  companyRoles?: CompanyRole[];
}

export interface UserWithRoles extends User {
  company_roles: number[];
  project_roles: number[];
}

export interface UserState {
  readonly loading: boolean;
  readonly details?: UserStateWithRoles;
}

interface UploadUserAvatarResponse {
  id: number;
  src: string;
}

interface GetUserAction {
  type: typeof GET_USER;
}

interface GetUserPendingAction {
  type: typeof GET_USER_PENDING;
}

interface GetUserFulfilledAction {
  type: typeof GET_USER_FULFILLED;
  data: Record<string, Record<string, string | number>>;
}

interface GetUserProjectsViaRolesAction {
  type: typeof GET_USER_PROJECTS_VIA_ROLES;
}

interface GetUserProjectsViaRolesPendingAction {
  type: typeof GET_USER_PROJECTS_VIA_ROLES_PENDING;
}

interface GetUserProjectsViaRolesFulfilledAction {
  type: typeof GET_USER_PROJECTS_VIA_ROLES_FULFILLED;
  data: Record<string, Record<string, string | number>>;
}

interface CreateUserFulfilledAction {
  type: typeof CREATE_USER_FULFILLED;
  data: UserWithRoles;
}

interface SaveUserFulfilledAction {
  type: typeof SAVE_USER_FULFILLED;
  data: UserSavedResponse;
}

interface DeleteUserAction {
  type: typeof DELETE_USER;
  userID: number;
}

interface DeleteUserFulfilledAction {
  type: typeof DELETE_USER_FULFILLED;
  data: UserWithRoles;
}

interface DisablePasswordChangeAction {
  type: typeof DISABLE_PASSWORD_CHANGE;
}

interface UploadUserAvatar {
  type: typeof UPLOAD_USER_AVATAR;
  files: object[];
}

interface UploadUserAvatarFulfilled {
  type: typeof UPLOAD_USER_AVATAR_FULFILLED;
  data: UploadUserAvatarResponse;
}

export type UserActionTypes =
  | GetUserAction
  | GetUserPendingAction
  | GetUserFulfilledAction
  | GetUserProjectsViaRolesAction
  | GetUserProjectsViaRolesPendingAction
  | GetUserProjectsViaRolesFulfilledAction
  | CreateUserFulfilledAction
  | SaveUserFulfilledAction
  | DeleteUserAction
  | DeleteUserFulfilledAction
  | DisablePasswordChangeAction
  | UploadUserAvatar
  | UploadUserAvatarFulfilled;
