import { DefaultTheme } from 'styled-components';
import { mix } from 'polished';

const defaultTheme: DefaultTheme = {
  name: '.io Dark',

  purple1: '#2B2D5D',
  purple2: '#272854',
  purple3: '#1D1F40',
  purple4: '#47486D',
  purple5: '#4b4c75',
  purple6: '#BFC1E0',
  purple7: '#363865',

  blue: '#0071A7',
  orange: '#F9B450',
  yellow: '#FFD700',
  green: '#A1AD68',
  purple: '#BF3EFF',
  red: '#E50046',
  pink: '#FDD7ED',

  textColor: '#FFFFFF',
  textColorLight: '#FFFFFF',

  gray90: mix(0.9, '#000', '#fff'),
  gray80: mix(0.8, '#000', '#fff'),
  gray70: mix(0.7, '#000', '#fff'),
  gray60: mix(0.6, '#000', '#fff'),
  gray50: mix(0.5, '#000', '#fff'),
  gray40: mix(0.4, '#000', '#fff'),
  gray30: mix(0.3, '#000', '#fff'),
  gray20: mix(0.2, '#000', '#fff'),
  gray10: mix(0.1, '#000', '#fff'),
  gray05: mix(0.05, '#000', '#fff'),
  gray03: mix(0.03, '#000', '#fff'),

  fontSize0: '24px',
  fontSize1: '20px',
  fontSize2: '18px',
  fontSize3: '16px',
  fontSize4: '14px',
  fontSize5: '12px',
  fontSize6: '10px',

  fontFamily: 'LatoWeb',
  fontFamilyBold: 'LatoWebBold',

  fontWeightRegular: 400,
  fontWeightBold: 600,

  zIndexNavMain: 1000,

  gradientPurpel1: ['#2B2D5D', '#1E2042'],
  gradientPurpel2: ['#47486D', '#2B2D5D'],

  gradientGreen1: ['#A1AD68', ' #8BAD68'],

  button: {
    padding: '18px 22px',
    fontSize: '14px',
    borderRadius: '5px',
    background: 'transparent',
    border: 'none',
    variants: {
      default: {
        background: 'rgba(255, 255, 255, 0)',
        color: '#fff',
      },
      light: {
        background: '#4b4c75',
        color: '#fff',
      },
      confirmation: {
        background: ['#008ca6', '#0072a7'],
        color: '#fff',
      },
      success: {
        background: ['#A1AD68', '#8BAD68'],
        color: '#fff',
      },
      warning: {
        background: ['#f9b250', '#fa9b50'],
        color: '#fff',
      },
      error: {
        background: '#6B265B',
        color: '#fff',
        border: '#E50046',
      },
      outlined: {
        background: 'transparent',
        color: '#fff',
        border: '#0071A7',
      },
    },
  },

  tag: {
    borderRadius: '3px',
    padding: '3px 5px',
    background: '#8C6C8F',
  },

  table: {
    borderRadius: '3px',
  },

  notification: {
    alert: {
      borderRadius: '12px',
      success: '#A1AD68',
      info: '#0072a7',
      warn: '#f9b250',
      error: '#E50046',
    },
    badge: {
      success: '#A1AD68',
      info: '#0072a7',
      warn: '#f9b250',
      error: '#E50046',
    },
  },

  datePicker: {
    borderRadius: '5px',
    preview: {
      background: '#4b4c75',
      color: '#fff',
      label: {
        background: ['#0072a7', '#008ca6'],
      },
    },
  },
};

export default defaultTheme;
