import * as H from 'history';

/*
 * Action Types
 * */
export const GET_ADCOMPANIES = 'GET_ADCOMPANIES';
export const GET_ADCOMPANIES_PENDING = 'GET_ADCOMPANIES_PENDING';
export const GET_ADCOMPANIES_FULFILLED = 'GET_ADCOMPANIES_FULFILLED';

export const GET_ADCOMPANY = 'GET_ADCOMPANY';
export const GET_ADCOMPANY_PENDING = 'GET_ADCOMPANY_PENDING';
export const GET_ADCOMPANY_FULFILLED = 'GET_ADCOMPANY_FULFILLED';

export const DELETE_ADCOMPANY = 'DELETE_ADCOMPANY';
export const DELETE_ADCOMPANY_PENDING = 'DELETE_ADCOMPANY_PENDING';
export const DELETE_ADCOMPANY_FULFILLED = 'DELETE_ADCOMPANY_FULFILLED';
export const DELETE_ADCOMPANY_REJECTED = 'DELETE_ADCOMPANY_REJECTED';

export const CREATE_ADCOMPANY = 'CREATE_ADCOMPANY';
export const CREATE_ADCOMPANY_PENDING = 'CREATE_ADCOMPANY_PENDING';
export const CREATE_ADCOMPANY_FULFILLED = 'CREATE_ADCOMPANY_FULFILLED';
export const CREATE_ADCOMPANY_REJECTED = 'CREATE_ADCOMPANY_REJECTED';

export const SAVE_ADCOMPANY = 'SAVE_ADCOMPANY';
export const SAVE_ADCOMPANY_PENDING = 'SAVE_ADCOMPANY_PENDING';
export const SAVE_ADCOMPANY_FULFILLED = 'SAVE_ADCOMPANY_FULFILLED';
export const SAVE_ADCOMPANY_REJECTED = 'SAVE_ADCOMPANY_REJECTED';

export const EDIT_ADCOMPANY = 'EDIT_ADCOMPANY';
export const EDIT_ADCOMPANY_PENDING = 'EDIT_ADCOMPANY_PENDING';
export const EDIT_ADCOMPANY_FULFILLED = 'EDIT_ADCOMPANY_FULFILLED';
export const EDIT_ADCOMPANY_REJECTED = 'EDIT_ADCOMPANY_REJECTED';

export const QUICK_CREATE_COMPANY = 'QUICK_CREATE_COMPANY';

/*
 * Models
 * */
export interface ADCompany {
  id: number;
  name: string;
  avatar_url?: string;
  description: string;
  phone?: string;
  address?: string;
}

/*
 * State Models
 * */
export interface ADCompanyState {
  readonly loading: boolean;
  readonly company?: ADCompany;
}

export interface ADCompaniesState {
  readonly loading: boolean;
  readonly companies?: Array<ADCompany>;
}

/*
 * Actions
 * */
interface GetADCompanyAction {
  type: typeof GET_ADCOMPANY;
}

interface GetADCompaniesAction {
  type: typeof GET_ADCOMPANIES;
}

interface GetADCompaniesPendingAction {
  type: typeof GET_ADCOMPANIES_PENDING;
}

interface SaveADCompaniesFulfilledAction {
  type: typeof GET_ADCOMPANIES_FULFILLED;
  data: Array<ADCompany>;
}

interface DeleteADCompanyAction {
  type: typeof DELETE_ADCOMPANY;
  data: number;
}

interface DeleteADCompanyPendingAction {
  type: typeof DELETE_ADCOMPANY_PENDING;
}

interface DeleteADCompanyFulfilledAction {
  type: typeof DELETE_ADCOMPANY_FULFILLED;
  data: number;
}

interface QuickCreateCompanyAction {
  type: typeof QUICK_CREATE_COMPANY;
  data: {
    history: H.History,
  }
}

interface EditADCompanyAction {
  type: typeof EDIT_ADCOMPANY_FULFILLED;
  data: ADCompany;
}

/*
 * Export Actiontypes
 * */
export type ADCompanyActionTypes =
  | GetADCompanyAction
  | GetADCompaniesAction
  | GetADCompaniesPendingAction
  | SaveADCompaniesFulfilledAction
  | DeleteADCompanyAction
  | DeleteADCompanyFulfilledAction
  | DeleteADCompanyPendingAction
  | QuickCreateCompanyAction
  | EditADCompanyAction;
