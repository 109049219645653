export const SET_DATETIME_FROM = 'SET_DATETIME_FROM';
export const SET_DATETIME_TO = 'SET_DATETIME_TO';

export interface DatepickerState {
  readonly from: string;
  readonly to: string;
  readonly timestampFrom: string;
  readonly timestampTo: string;
}

export interface DateObject {
  from?: string;
  to?: string;
  timestampFrom?: string;
  timestampTo?: string;
}

interface SetDatetimeAction {
  type: typeof SET_DATETIME_FROM | typeof SET_DATETIME_TO
  date: DateObject;
}

export type DatepickerActionTypes = SetDatetimeAction;
