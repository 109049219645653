import {
  ADUserState,
  ADUserActionTypes,
  CREATE_ADUSER_FULFILLED,
  CREATE_ADUSER_PENDING,
} from 'store/adusers/types';

const initialState: ADUserState = {
  loading: false,
  user: null,
}

export default (state = initialState, action: ADUserActionTypes) => {
  switch (action.type) {
    default:
      return state;
  }
};
