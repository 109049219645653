import React, { FC } from 'react';
import { StyledBreadcrumbs } from 'components/ui/Breadcrumbs/styles/BreadcrumbsStyles';

const Breadcrumbs: FC<{}> = () => {
  let paths = window.location.pathname.split('/');

  // -- TODO --
  // Implement logic to create breadcrumbs
  // paths = paths[l - 1] === '' ? paths.slice(0, l - 1) : paths;
  // paths = paths[1] === '' ? paths.slice(1) : paths;

  return <StyledBreadcrumbs>{`.${paths[1]}`}</StyledBreadcrumbs>;
};
export default Breadcrumbs;
