import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSize3};
    color: ${({ theme }) => theme.textColor};
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;

    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    user-select: none;
  
  }

  body {
    box-sizing: border-box
    min-height: 100%;
    padding: 0;
    margin: 0;

    ::-webkit-scrollbar {
      width: 0 !important;
    }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  *,
  *::before,
  *::after {
    font-family: inherit;
    box-sizing: inherit;
  }

  .--modal-opened {
    overflow: hidden;
  }

  a {
    color: ${({ theme }) => theme.textColor};
  }
`;
