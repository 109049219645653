import {
  SILENT_RENEW_ERROR,
  SESSION_TERMINATED,
  LOADING_USER,
  LOAD_USER_ERROR,
  USER_FOUND,
  USER_SIGNED_OUT,
  USER_EXPIRED,
  USER_EXPIRING,
  AuthActionTypes,
} from 'store/auth/types';

export function silentRenewError(): AuthActionTypes {
  return {
    type: SILENT_RENEW_ERROR,
  };
}

export function sessionTerminated(): AuthActionTypes {
  return {
    type: SESSION_TERMINATED,
  };
}

export function loadingUser(): AuthActionTypes {
  return {
    type: LOADING_USER,
  };
}

export function loadUserError() {
  return {
    type: LOAD_USER_ERROR,
  };
}

export function userFound(user: Oidc.User) {
  return {
    type: USER_FOUND,
    payload: user,
  };
}

export function userSignedOut(): AuthActionTypes {
  return {
    type: USER_SIGNED_OUT,
  };
}

export function userExpiring(): AuthActionTypes {
  return {
    type: USER_EXPIRING,
  };
}

export function userExpired(): AuthActionTypes {
  return {
    type: USER_EXPIRED,
  };
}
