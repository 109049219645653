import React, { FC } from 'react';
import { InjectedFormProps, reduxForm, FieldArray } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useIsEqualForm } from 'utils/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Variant } from 'components/ui/Button/Button';
import { ModalBody, ModalFooter, ModalHeader } from 'components/ui/Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StateType } from 'store/types';
import Field, { FieldRow } from 'components/ui/Form/Form';
import { TOGGLE_MODAL } from 'store/modal/types';

type InfluxUser = {
  password: string;
  username: string;
}

type InfluxData = {
  users: InfluxUser[];
}

type MqttTopic = {
  name: string;
  topic: string;
}

type MqttUser = {
  name: string;
  password: string;
}

type MqttData = {
  topics: MqttTopic[];
  users: MqttUser[];
}

type ProjectData = {
  avatar_url: string;
  copmany_id: number;
  description: string;
  handle: string;
  hash_id_length: number;
  id: number;
  load_balancing_group: string;
  name: string;
  schedules_enabled: boolean;
  setpoints_enabled: boolean;
  setpoints_min_priority: number;
  slug: string;
}

interface InspectADProjectFormData {
  influx: InfluxData;
  mqtt: MqttData;
  project: ProjectData;
}

const renderMqttUsers = ({ fields }) => (
  <>
    {fields.map((user: MqttUser, index: number) => (
      <FieldRow key={`key_mqtt_user_${index}`}>
        <Field
          name={`${user}.name`}
          type="text"
          label="Username"
          readonly={true}
        />
        <Field
          name={`${user}.password`}
          type="text"
          label="Password"
          readonly={true}
        />
      </FieldRow>
    ))}
  </>
);

const renderMqttTopics = ({ fields }) => (
  <>
    {fields.map((topic: MqttTopic, index: number) => (
      <FieldRow key={`key_mqtt_user_${index}`}>
        <Field
          name={`${topic}.name`}
          type="text"
          label="Topic Name"
          readonly={true}
        />
        <Field
          name={`${topic}.topic`}
          type="text"
          label="Topic"
          readonly={true}
        />
      </FieldRow>
    ))}
  </>
);

const renderInfluxUsers = ({ fields }) => (
  <>
    {fields.map((user: InfluxUser, index: number) => (
      <FieldRow>
        <Field
          name={`${user}.username`}
          type="text"
          label="Username"
          readonly={true}
        />
        <Field
          name={`${user}.password`}
          type="text"
          label="Password"
          readonly={true}
        />
      </FieldRow>
    ))}
  </>
);

const InspectADProjectForm: FC<InjectedFormProps<InspectADProjectFormData>> = ({
  pristine,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  pristine = useIsEqualForm('project-inspect');
  const project = useSelector((state: StateType) => state.adproject);
  console.debug(project)
  const dispatch = useDispatch();

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ModalHeader>
          <h1>
            <FontAwesomeIcon icon={['fal', 'building']} fixedWidth />
            {t('projects:modal.inspect_new_project')}
          </h1>
        </ModalHeader>
        <ModalBody>
          <h2>Influx</h2>
          <FieldArray name="influx.users" component={renderInfluxUsers} />
          <h2>MQTT Topics:</h2>
          <FieldArray name="mqtt.topics" component={renderMqttTopics} />
          <h2>MQTT Users:</h2>
          <FieldArray name="mqtt.users" component={renderMqttUsers} />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            variant={Variant.Warning}
            disabled={false}
            onClick={() => {
              dispatch({
                type: TOGGLE_MODAL,
                selected: 'project-data',
              });
            }}
          >
            {t('common:modal.close_project_inspect')}
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default reduxForm<InspectADProjectFormData>({
  form: 'project-inspect',
  enableReinitialize: true,
})(InspectADProjectForm);
