import React, { FC, useState } from 'react';
import { destroy, InjectedFormProps, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { ModalBody, ModalFooter, ModalHeader } from 'components/ui/Modal/Modal';

import Field, { FieldRow } from 'components/ui/Form/Form';
import { Button, Variant, Size } from 'components/ui/Button/Button';
import { useIsEqualForm, useLoading } from 'utils/hooks';
import { CREATE_ADCOMPANY } from 'store/companies/types';
import { StateType } from 'store/types';
import { useSelector, useDispatch } from 'react-redux';
import { INITIALIZERS, CREATE_INITIALIZER } from 'store/initializers/types';
import { triggerInitializer } from 'store/initializers/actions';
import { get } from 'lodash';

// -- TODO --
// Remove the key index signature type. It´s just there to prevent an failure with the
// validate utils function
interface CreateCompanyFormData {
  name: string;
  description: string;
  phone: string;
  address: string;
  avatar_url: string;
  slug: string;
}

const CreateCompanyForm: FC<InjectedFormProps<CreateCompanyFormData>> = ({
  submitting,
  pristine,
  reset,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const modal = useSelector((state: StateType) => state.modal);
  pristine = useIsEqualForm('company-create');
  const [initializer, setInitializer] = useState('');
  const [loadingInitializer, createInitializerLoading] = useLoading(CREATE_INITIALIZER);
  const dispatch = useDispatch();
  const [loading, createCompanyLoading] = useLoading(CREATE_ADCOMPANY);
  const { company } = useSelector((state: StateType) => state.adcompany);
  const [hashesInit, setHashesInit] = useState(false);
  const [syncInit, setSyncInit] = useState(false);
  const title = modal.edit
    ? t('companies:modal.edit_company')
    : t('companies:modal.create_new_company');

  const initializeDatapointsHashIds = () => {
    setInitializer('hashes');
    setHashesInit(true);
    dispatch(triggerInitializer({
      initializer: INITIALIZERS.INITIALIZE_DATAPOINT_HASH_IDS,
      company_id: get(company, 'id', null),
    }));
    setTimeout(function() {
      setHashesInit(false);
    }, 5000);
  }

  const syncDatapoints = () => {
    setInitializer('sync_influx');
    setSyncInit(true);
    dispatch(triggerInitializer({
        initializer: INITIALIZERS.SYNCHRONIZE_DATAPOINTS_WITH_INFLUX,
        company_id: get(company, 'id', null)
    }));
    setTimeout(function() {
      setSyncInit(false);
    }, 5000);
  }

  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader>
        <h1>
          <FontAwesomeIcon icon={['fal', 'building']} fixedWidth />
          {title}
        </h1>
      </ModalHeader>
      <ModalBody>
        <FieldRow>
          <Field name="name" type="text" label={t('companies:fields.name')} />
          <Field
            name="description"
            type="text"
            label={t('companies:fields.description')}
          />
        </FieldRow>
        <FieldRow>
          <Field name="phone" type="text" label={t('companies:fields.phone')} />
        </FieldRow>
        <FieldRow>
          <Field name="address" type="text" label={t('companies:fields.address')} />
        </FieldRow>
        {!modal.edit && (
          <FieldRow>
            <Field
              name="slug"
              type="text"
              label={t('companies:fields.slug')}
            />
          </FieldRow>
        )}
        <FieldRow>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Button
              variant={Variant.Success}
              scaleSize={Size.XL}
              onClick={initializeDatapointsHashIds}
              loading={hashesInit}
              style={{
                width: '45%'
              }}
            >
              {t('initializers:texts.hashes')}
            </Button>
            <Button
              variant={Variant.Success}
              scaleSize={Size.XL}
              onClick={syncDatapoints}
              loading={syncInit}
              style={{
                width: '45%'
              }}
            >
              {t('initializers:texts.sync_datapoints')}
            </Button>
          </div>
        </FieldRow>
      </ModalBody>
      {!pristine && (
        <ModalFooter>
          <Button
            type="submit"
            variant={Variant.Success}
            disabled={!(!pristine || submitting)}
            loading={createCompanyLoading}
          >
            {t('common:modal.save_company')}
          </Button>
          <Button
            type="button"
            variant={Variant.Default}
            disabled={pristine || submitting}
            onClick={reset}
          >
            {t('common:button.cancel')}
          </Button>
        </ModalFooter>
      )}
    </form>
  );
};

export default reduxForm<CreateCompanyFormData>({
  form: 'company-create',
  enableReinitialize: false,
})(CreateCompanyForm);
