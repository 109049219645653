import { CHANGE_SELECTED_PROJECT } from 'store/project/types';

export const GET_DATA_POINTS = 'GET_DATA_POINTS';
export const GET_DATA_POINTS_PENDING = 'GET_DATA_POINTS_PENDING';
export const GET_DATA_POINTS_FULFILLED = 'GET_DATA_POINTS_FULFILLED';
export const GET_DATA_POINTS_REJECTED = 'GET_DATA_POINTS_REJECTED';

export const GET_TIMESERIES = 'GET_TIMESERIES';
export const GET_TIMESERIES_PENDING = 'GET_TIMESERIES_PENDING';
export const GET_TIMESERIES_FULFILLED = 'GET_TIMESERIES_FULFILLED';
export const GET_TIMESERIES_REFETCH_FULFILLED =
  'GET_TIMESERIES_REFETCH_FULFILLED';
export const GET_TIMESERIES_REJECTED = 'GET_TIMESERIES_REJECTED';
export const REMOVE_TIMESERIES = 'REMOVE_TIMESERIES';
export const REFETCH_TIMESERIES = 'REFETCH_TIMESERIES';

export const GET_DATA_POINTS_BY_SEARCH = 'GET_DATA_POINTS_BY_SEARCH';
export const GET_DATA_POINTS_BY_SEARCH_PENDING =
  'GET_DATA_POINTS_BY_SEARCH_PENDING';
export const GET_DATA_POINTS_BY_SEARCH_FULFILLD =
  'GET_DATA_POINTS_BY_SEARCH_FULFILLD';
export const GET_DATA_POINTS_BY_SEARCH_REJECTED =
  'GET_DATA_POINT_BY_SEARCH_REJECTED';

export const CHANGE_CHART_TYPE = 'CHANGE_CHART_TYPE';

export const SET_FAVORITE_DATA_POINT = 'SET_FAVORITE_DATA_POINT';
export const SET_FAVORITE_DATA_POINT_REJECTED =
  'SET_FAVORITE_DATA_POINT_REJECTED';

export const REMOVE_FAVORITE_DATA_POINT = 'REMOVE_FAVORITE_DATA_POINT';
export const REMOVE_FAVORITE_DATA_POINT_REJECTED =
  'REMOVE_FAVORITE_DATA_POINT_REJECTED';

export enum ChartType {
  line = 'LINE',
  heat = 'HEAT',
}

export interface DataPointState {
  readonly loading: boolean;
  readonly projectID?: string;
  readonly error?: boolean;
  readonly search?: string;
  readonly timeseries: Record<string, string | [number, number][]>[];
  readonly dataPoints: {
    id: string;
    loading: boolean;
    selected: boolean;
    favorite: boolean;
  }[];
  readonly chartType: ChartType;
}

interface GetDataPointsAction {
  type: typeof GET_DATA_POINTS;
  projectID?: number;
  search?: string;
  page: number;
}

interface GetDataPointsPendingAction {
  type: typeof GET_DATA_POINTS_PENDING;
}

interface GetDataPointsFulfilledAction {
  type: typeof GET_DATA_POINTS_FULFILLED;
  dataPointIDs: string[];
  favorites: string[];
  projectID: string;
}

interface GetDataPointsRejectedAction {
  type: typeof GET_DATA_POINTS_REJECTED;
}

interface GetTimeseriesForDataPointAction {
  type: typeof GET_TIMESERIES;
  dataPointID: string;
  chartType: ChartType;
}

interface GetTimeseriesForDataPointPendingAction {
  type: typeof GET_TIMESERIES_PENDING;
  dataPointID: string;
}

interface GetTimeseriesForDataPointFulfilledAction {
  type: typeof GET_TIMESERIES_FULFILLED;
  timeseries: Record<string, [number, number][] | string>;
  dataPointID: string;
}

interface GetTimeseriesForDataPointRejectedAction {
  type: typeof GET_TIMESERIES_REJECTED;
}

interface RemoveTimeseriesAction {
  type: typeof REMOVE_TIMESERIES;
  dataPointID: string;
}

interface GetDataPointsBySearchAction {
  type: typeof GET_DATA_POINTS_BY_SEARCH;
  search: string;
}

interface GetDataPointsBySearchPendingAction {
  type: typeof GET_DATA_POINTS_BY_SEARCH_PENDING;
}

interface GetDataPointsBySearchFulfilledAction {
  type: typeof GET_DATA_POINTS_BY_SEARCH_FULFILLD;
  dataPointIDs: string[];
  favorites: string[];
  projectID: string;
}

interface GetDataPointsBySearchRejectedAction {
  type: typeof GET_DATA_POINTS_BY_SEARCH_REJECTED;
}

interface RefetchTimeseriesAction {
  type: typeof REFETCH_TIMESERIES;
}

interface ChangeSelectedProjectAction {
  type: typeof CHANGE_SELECTED_PROJECT;
  projectID: number;
}

interface RefetchTimeseriesFullfiledAction {
  type: typeof GET_TIMESERIES_REFETCH_FULFILLED;
  timeseries: Record<string, [number, number][] | string>;
}

interface ChangeChartTypeAction {
  type: typeof CHANGE_CHART_TYPE;
  chartType: string;
}

interface SetFavoriteDataPoint {
  type: typeof SET_FAVORITE_DATA_POINT;
  dataPointID: string;
}

interface RemoveFavoriteDataPoint {
  type: typeof REMOVE_FAVORITE_DATA_POINT;
  dataPointID: string;
}

export type DataPointActionTypes =
  | GetDataPointsAction
  | GetDataPointsPendingAction
  | GetDataPointsFulfilledAction
  | GetDataPointsRejectedAction
  | GetTimeseriesForDataPointAction
  | GetTimeseriesForDataPointPendingAction
  | GetTimeseriesForDataPointFulfilledAction
  | GetTimeseriesForDataPointRejectedAction
  | RemoveTimeseriesAction
  | GetDataPointsBySearchAction
  | GetDataPointsBySearchPendingAction
  | GetDataPointsBySearchFulfilledAction
  | GetDataPointsBySearchRejectedAction
  | RefetchTimeseriesAction
  | RefetchTimeseriesFullfiledAction
  | ChangeChartTypeAction
  | ChangeSelectedProjectAction
  | SetFavoriteDataPoint
  | RemoveFavoriteDataPoint;
