import {
  SESSION_TERMINATED,
  SILENT_RENEW_ERROR,
  LOADING_USER,
  LOAD_USER_ERROR,
  USER_FOUND,
  USER_SIGNED_OUT,
  USER_EXPIRED,
  AuthState,
  AuthActionTypes,
} from 'store/auth/types';
import * as Sentry from '@sentry/react';

// Loading needs to be initially set to true
const initialState: AuthState = {
  user: null,
  loading: false,
};

export default function authReducer(
  state = initialState,
  action: AuthActionTypes
) {
  switch (action.type) {
    case LOAD_USER_ERROR:
    case USER_EXPIRED:
    case SILENT_RENEW_ERROR:
    case SESSION_TERMINATED:
    case USER_SIGNED_OUT:
      Sentry.configureScope(scope => scope.setUser(null));
      return { ...state, user: null, loading: false };
    case USER_FOUND:
      return { ...state, user: action.payload, loading: false };
    case LOADING_USER:
      return { ...state, loading: true };
    default:
      return state;
  }
}
