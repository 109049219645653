import React, { CSSProperties, RefObject, FC, ReactElement, Ref } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Placement } from 'popper.js';
import isFunction from 'lodash/isFunction';
import { Manager, Reference, Popper } from 'react-popper';
import { Dispatch } from 'redux';

import { useClickOutside } from 'utils/hooks';

import { ActionTypes } from 'store/types';
import {
  StyledRef,
  StyledDropdown,
} from 'components/ui/Dropdown/styles/DropdownStyles';

interface Props {
  header?: string | ReactElement;
  footer?: string | ReactElement;
  component?: ReactElement;
  items: {
    to?: string;
    dispatch?: ActionTypes | (() => void);
    label: string;
  }[];
  placement?: Placement;
  fixed?: boolean;
  arrowProps?: {
    ref: RefObject<HTMLDivElement>;
    style: CSSProperties;
  };
  dropdownRef?: Ref<HTMLDivElement>;
  parentRef?: Ref<any>;
  style?: CSSProperties;
  setOpen?: (toggler: boolean) => void;
  dispatch?: Dispatch;
}

export const Dropdown: FC<Props> = ({
  dropdownRef,
  parentRef,
  style,
  placement,
  arrowProps,
  header = false,
  footer = false,
  items = [],
  setOpen = () => {},
  dispatch = () => {},
}) => {
  return (
    <StyledDropdown ref={dropdownRef}>
      <div
        ref={parentRef}
        style={style}
        data-placement={placement}
        className={'dropdown'}
      >
        {arrowProps && <div ref={arrowProps.ref} style={arrowProps.style} />}
        {header && <div className={'dropdown--header'}>{header}</div>}
        <div
          className={`dropdown--content 
                    ${footer ? '' : '--no-footer'}
                    ${header ? '' : '--no-header'} 
                    --item-count-${items.length}`}
        >
          <ul>
            {items &&
              items.map((item, index) => (
                <li key={index}>
                  {item.to && (
                    <Link to={item.to} onClick={() => setOpen(false)}>
                      {item.label}
                    </Link>
                  )}
                  {item.dispatch && (
                    <span
                      onClick={() => {
                        isFunction(item.dispatch)
                          ? item.dispatch()
                          : dispatch(item.dispatch);
                        setOpen(false);
                      }}
                    >
                      {item.label}
                    </span>
                  )}
                </li>
              ))}
          </ul>
        </div>
        {footer && <div className={'dropdown--footer'}>{footer}</div>}
      </div>
    </StyledDropdown>
  );
};

/**
 *
 * @param props
 * @return
 */
const DropdownContainer: FC<Props> = props => {
  const {
    header,
    footer,
    items,
    component,
    placement = 'bottom',
    fixed = false,
  } = props;
  const dropdownRef = React.createRef<HTMLDivElement>();
  const [open, setOpen] = useClickOutside(false, dropdownRef);
  const dispatch = useDispatch();

  const ForwardDropdown = React.forwardRef((props, ref) => (
    <Dropdown
      header={header}
      footer={footer}
      items={items}
      dropdownRef={dropdownRef}
      parentRef={ref}
      setOpen={setOpen}
      dispatch={dispatch}
      {...props}
    />
  ));

  return (
    <Manager>
      <Reference>
        {({ ref }) => {
          return (
            <StyledRef
              {...fixed}
              className={fixed ? 'fixed-dropdown' : 'flex-dropdown'}
              ref={ref}
              onClick={() => setOpen(!open)}
            >
              {component}
            </StyledRef>
          );
        }}
      </Reference>
      {open && (
        <Popper placement={placement}>
          {props => <ForwardDropdown {...props} ref={props.ref} />}
        </Popper>
      )}
    </Manager>
  );
};

export default DropdownContainer;
