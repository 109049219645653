import { Role } from 'src/store/roles/types';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_PENDING = 'CREATE_PROJECT_PENDING';
export const CREATE_PROJECT_FULFILLED = 'CREATE_PROJECT_FULFILLED';
export const CREATE_PROJECT_REJECTED = 'CREATE_PROJECT_REJECTED';

export const EDIT_PROJECT = 'EDIT_PROJECT';
export const EDIT_PROJECT_PENDING = 'EDIT_PROJECT_PENDING';
export const EDIT_PROJECT_FULFILLED = 'EDIT_PROJECT_FULFILLED';
export const EDIT_PROJECT_REJECTED = 'EDIT_PROJECT_REJECTED';

export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_PENDING = 'GET_PROJECT_PENDING';
export const GET_PROJECT_FULFILLED = 'GET_PROJECT_FULFILLED';
export const GET_PROJECT_REJECTED = 'GET_PROJECT_REJECTED';

export const SAVE_PROJECT = 'SAVE_PROJECT';
export const SAVE_PROJECT_PENDING = 'SAVE_PROJECT_PENDING';
export const SAVE_PROJECT_FULFILLED = 'SAVE_PROJECT_FULFILLED';
export const SAVE_PROJECT_REJECTED = 'SAVE_PROJECT_REJECTED';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_PENDING = 'DELETE_PROJECT_PENDING';
export const DELETE_PROJECT_FULFILLED = 'DELETE_PROJECT_FULFILLED';
export const DELETE_PROJECT_REJECTED = 'DELETE_PROJECT_REJECTED';

export const CHANGE_SELECTED_PROJECT = 'CHANGE_SELECTED_PROJECT';

export const ASSIGN_PROJECT_ROLE_TO_USER = 'ASSIGN_PROJECT_ROLE_TO_USER';
export const REMOVE_PROJECT_ROLE_FROM_USER = 'REMOVE_PROJECT_ROLE_FROM_USER';

// TODO: reshape, or rename interface
export interface Project {
  id: number;
  name: string;
  description: string;
  avatar_url?: string;
  roles?: Role[]; // company projects, have roles, but user assigned projects not in the state
}

export interface ProjectWithCompanyId extends Project {
  company_id: number;
}

export interface ProjectState {
  readonly selected?: number;
}

interface ChangeSelectedProject {
  type: typeof CHANGE_SELECTED_PROJECT;
  projectID: number;
}

export interface CreateProjectFulfilledAction {
  type: typeof CREATE_PROJECT_FULFILLED;
  data: any; // TODO: shape
  userID: number;
  companyName: string;
}

export interface EditProjectAction {
  type: typeof EDIT_PROJECT;
  project_id: number;
}

export interface DeleteProjectAction {
  type: typeof DELETE_PROJECT;
  project_id: number;
}

export interface DeleteProjectFulfilledAction {
  type: typeof DELETE_PROJECT_FULFILLED;
  data: any; // TODO: shape
  meta?: object;
}

export interface SaveProjectFulfilledAction {
  type: typeof SAVE_PROJECT_FULFILLED;
  data: any;
}

export interface AssignProjectRoleToUserAction {
  type: typeof ASSIGN_PROJECT_ROLE_TO_USER;
  userID: number;
  roleID: number;
}

export interface RemoveProjectRoleFromUserAction {
  type: typeof REMOVE_PROJECT_ROLE_FROM_USER;
  userID: number;
  roleID: number;
}

export type ProjectActionTypes =
  | ChangeSelectedProject
  | EditProjectAction
  | DeleteProjectAction
  | CreateProjectFulfilledAction
  | DeleteProjectFulfilledAction
  | SaveProjectFulfilledAction
  | AssignProjectRoleToUserAction
  | RemoveProjectRoleFromUserAction;
