import {
  ADProjectsActionTypes,
  ADProjectsState,
  CREATE_ADPROJECT_FULFILLED,
  DELETE_ADPROJECT_FULFILLED,
  DELETE_ADPROJECT_PENDING,
  GET_ADPROJECTS_FULFILLED,
  GET_ADPROJECTS_PENDING,
  SAVE_ADPROJECT_FULFILLED,
} from 'store/adprojects/types';

const initialState: ADProjectsState = {
  loading: false,
};

export default (state = initialState, action: ADProjectsActionTypes) => {
  switch (action.type) {
    case GET_ADPROJECTS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_ADPROJECTS_FULFILLED:
      return {
        ...state,
        loading: false,
        projects: action.data,
      };
    case CREATE_ADPROJECT_FULFILLED:
      const projects_list = state.projects
        ? state.projects.find(
          project => project.company_id === action.data.project.company_id
        )
          ? state.projects.concat(action.data.project)
          : state.projects
        : [action.data.project];

      return {
        projects: projects_list,
        loading: false,
      };
    case SAVE_ADPROJECT_FULFILLED:
      if (
        state.projects &&
        state.projects.find(
          project =>
            project.company_id === action.data.company_id &&
            project.id === action.data.id
        )
      ) {
        const project_index = state.projects.findIndex(
          project => project.id === action.data.id
        );

        state.projects.splice(project_index, 1, action.data);
        return {
          projects: state.projects,
          loading: false,
        };
      } else {
        const project_index = state.projects.findIndex(
          project => project.id === action.data.id
        );
        state.projects.splice(project_index, 1);
        return {
          projects: state.projects,
          loading: false,
        };
      }
    case DELETE_ADPROJECT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ADPROJECT_FULFILLED:
      state.projects.splice(
        state.projects.findIndex(project => project.id === action.data),
        1
      );
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
