import { StateType } from 'store/types';

/**
 *
 * @param state
 */
export const getAccessToken = (state: StateType) => {
  if (state.auth && state.auth.user) {
    return state.auth.user.access_token;
  }
  return null;
};

/**
 *
 * @param state
 */
export const getSelectedProjectID = (state: StateType) => {
  if (state.project && state.project.selected) {
    return state.project.selected;
  }
  return null;
};

/**
 *
 * @param state
 */
export const getTimeseriesState = (state: StateType) => {
  if (state.dataPoint && state.dataPoint.timeseries) {
    return state.dataPoint.timeseries;
  }
  return null;
};

/**
 *
 * @param state
 */
export const getDatepickerState = (state: StateType) => {
  if (state.datepicker) return state.datepicker;
  return null;
};

/**
 * -- TODO --
 * ADD CONTEXT TO FUNCTION PARAMS
 *
 * @param state
 */
export const getSearchTermState = (state: StateType) => {
  if (state.dataPoint) return state.dataPoint.search;
  return null;
};

/**
 * -- TODO --
 * ADD CONTEXT TO FUNCTION PARAMS
 *
 * @param state
 */
export const getSelectedChart = (state: StateType) => {
  if (state.dataPoint) return state.dataPoint.chartType;
  return null;
};
