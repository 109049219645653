import {
  call,
  put,
  select,
  all,
  takeLatest,
  take,
  fork,
  cancelled,
} from 'redux-saga/effects';
import { initialize, destroy, reset } from 'redux-form';
import {
  get,
  pick,
  head,
  map,
  reduce,
  filter,
  uniqBy,
  differenceBy,
  has,
  isEmpty,
} from 'lodash';
import i18next from 'i18next';

import {
  Project,
  CHANGE_SELECTED_PROJECT,
  CREATE_PROJECT,
  CREATE_PROJECT_FULFILLED,
  CREATE_PROJECT_PENDING,
  CREATE_PROJECT_REJECTED,
  DELETE_PROJECT,
  DELETE_PROJECT_FULFILLED,
  DELETE_PROJECT_PENDING,
  DELETE_PROJECT_REJECTED,
  EDIT_PROJECT,
  EDIT_PROJECT_PENDING,
  EDIT_PROJECT_REJECTED,
  SAVE_PROJECT,
  SAVE_PROJECT_FULFILLED,
  SAVE_PROJECT_PENDING,
  SAVE_PROJECT_REJECTED,
  ASSIGN_PROJECT_ROLE_TO_USER,
  REMOVE_PROJECT_ROLE_FROM_USER,
} from 'store/project/types';

import { request } from 'utils/axiosInstance';
import { showNotification } from 'store/notification/actions';
import { TOGGLE_MODAL } from 'store/modal/types';
import { GET_USER_PROJECTS_FULFILLED } from 'store/projects/types';

import { GET_DATA_POINTS } from 'store/datapoint/types';

export function* assignUserToProject(project_id, role_id, user_id) {
  const [postProjectRoleUser, cancel] = yield request(
    'POST_PROJECT_ROLE_USER',
    {
      project_id,
      role_id,
      user_id,
    }
  );
  try {
    yield postProjectRoleUser;

    yield put({
      type: ASSIGN_PROJECT_ROLE_TO_USER,
      userID: user_id,
      roleID: role_id,
    });

    yield put(
      showNotification({
        identifier: 'project-assigned-notification',
        type: 'success',
        text: i18next.t('common:notification.project_assigned_success'),
        autoClose: 2000,
      })
    );
  } catch (error) {
    console.error(error);
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: 'ASSIGN_PROJECT_ROLE_TO_USER_REJECTED' });
    }
  }
}

export function* removeUserFromProject(project_id, role_id, user_id) {
  const [deleteProjectRoleUser, cancel] = yield request(
    'DELETE_PROJECT_ROLE_USER',
    {
      project_id,
      role_id,
      user_id,
    }
  );
  try {
    yield deleteProjectRoleUser;

    yield put({
      type: REMOVE_PROJECT_ROLE_FROM_USER,
      userID: user_id,
      roleID: role_id,
    });

    yield put(
      showNotification({
        identifier: 'project-unassigned-notification',
        type: 'success',
        text: i18next.t('common:notification.project_unassigned_success'),
        autoClose: 2000,
      })
    );
  } catch (error) {
    console.error(error);
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: 'REMOVE_PROJECT_ROLE_FROM_USER_REJECTED' });
    }
  }
}

export function* getProject(action) {
  const { project_id } = action;

  const users = yield select(state => state.users.users);
  yield put({ type: EDIT_PROJECT_PENDING });
  const [getProject] = yield request('GET_PROJECT', { project_id });
  const [getProjectRoles] = yield request('GET_PROJECT_ROLES', { project_id });
  try {
    const [res, projectRoles] = [yield getProject, yield getProjectRoles];

    const projectRoleIDs = map(projectRoles.data, role => role.id);

    // get users via projectRoles
    res.data.project.default_users = map(
      uniqBy(
        reduce(
          projectRoleIDs,
          (acc, role) => {
            return [
              ...acc,
              ...filter(users, user => user.project_roles.includes(role)),
            ];
          },
          []
        ),
        'id'
      ),
      user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })
    );

    yield put(initialize('project-create', res.data.project));
    yield put({ type: 'TOGGLE_MODAL', selected: 'project', edit: true });
  } catch (error) {
    // how to get response? res is undefined, if used with let?
    yield put(
      showNotification({
        identifier: 'project-edit-notification',
        type: 'error',
        text: i18next.t('common:notification.project_fetch_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: EDIT_PROJECT_REJECTED });
  }
}

export function* saveProject(action) {
  // const { values } = action; why are these old values?

  const values = yield select(state =>
    get(state, 'form.project-create.values', {})
  );

  const fields = yield select(state =>
    get(state, 'form.project-create.fields', [])
  );

  // if values has no users object, no users have been changed, so it should be the same
  if (!has(values, 'users')) {
    values.users = values.default_users;
  }

  // clear fields ...
  const { id: projectID } = values;
  const mappedValues = pick(values, Object.keys(fields));

  // delete users ...
  delete mappedValues.users;

  yield put({ type: SAVE_PROJECT_PENDING });
  const [putProject, cancel] = yield request('PUT_PROJECT', {
    project_id: projectID,
    ...mappedValues,
  });
  try {
    const res = yield putProject;
    yield put({ type: SAVE_PROJECT_FULFILLED, data: res.data.resource });
    yield put(
      showNotification({
        identifier: 'project-saved-notification',
        type: 'success',
        text: i18next.t('common:notification.project_updated_success'),
        autoClose: 2000,
      })
    );

    // check which users have been added or removed and prepare requests
    const addedUsers = differenceBy(
      values.users,
      values.default_users,
      (el: any) => el.value
    );
    const deletedUsers = differenceBy(
      values.default_users,
      values.users,
      (el: any) => el.value
    );

    if (addedUsers.length > 0 || deletedUsers.length > 0) {
      const projectID = res.data.resource.id;
      const companyProjects = yield select(state => state.projects.company);

      if (addedUsers.length > 0) {
        yield all(
          addedUsers.map((user: any) => {
            const projectRole = companyProjects[projectID].roles[0].id;
            return call(
              assignUserToProject,
              projectID,
              projectRole,
              user.value
            );
          })
        );
      }

      if (deletedUsers.length > 0) {
        yield all(
          deletedUsers.map((user: any) => {
            const projectRole = companyProjects[projectID].roles[0].id;
            return call(
              removeUserFromProject,
              projectID,
              projectRole,
              user.value
            );
          })
        );
      }
    }

    yield put(reset('project-create'));
    yield put({ type: 'TOGGLE_MODAL', selected: 'project' });
  } catch (error) {
    yield put(
      showNotification({
        identifier: 'project-edit-notification',
        type: 'error',
        text: i18next.t('common:notification.project_update_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: SAVE_PROJECT_REJECTED });
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: SAVE_PROJECT_REJECTED });
    }
  }
}

export function* clearProjectFormOnBlur(action) {
  if (action.selected === 'project' && typeof action.edit === 'undefined') {
    yield put(destroy('project-create'));
  }
}

export function* createProject(action) {
  const { values } = action;

  // get users id
  const users = map(values.users, el => el.value);
  delete values.users;

  const userID = yield select(state => state.user.details.id);
  const companyName = yield select(state => state.company.company.name);
  yield put({ type: CREATE_PROJECT_PENDING });
  const [postCompanyProject, cancel] = yield request(
    'POST_COMPANY_PROJECT',
    values
  );
  try {
    const res = yield postCompanyProject;

    yield put({
      type: CREATE_PROJECT_FULFILLED,
      data: res.data.resource,
      userID: userID,
      companyName: companyName,
    });

    const { project, role } = res.data.resource;
    // add users to project ...
    yield all([
      ...users.map(
        user => call(assignUserToProject, project.id, role.id, user) // TODO: bubble cancelation to call?
      ),
    ]);

    yield put(
      showNotification({
        identifier: 'project-created-notification',
        type: 'success',
        text: i18next.t('common:notification.project_created_success'),
        autoClose: 2000,
      })
    );
    yield put(reset('project-create'));
    yield put({ type: 'TOGGLE_MODAL', selected: 'project' });
  } catch (error) {
    // how to get response? res is undefined, if used with let?
    yield put(
      showNotification({
        identifier: 'project-created-notification',
        type: 'error',
        text: i18next.t('common:notification.project_create_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: CREATE_PROJECT_REJECTED });
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: CREATE_PROJECT_REJECTED });
    }
  }
}

export function* deleteProject(action) {
  const { project_id } = action;

  const projectRoles = yield select(state => state.roles.project);

  // open confirm modal and wait for confirm dispatch
  yield put({ type: TOGGLE_MODAL, selected: 'confirm', style: 'box' });
  yield take('CONFIRM_DELETE');
  yield put({ type: DELETE_PROJECT_PENDING });
  const [deleteProject, cancel] = yield request('DELETE_PROJECT', { project_id });
  try {
    const res = yield deleteProject;
    yield put({
      type: DELETE_PROJECT_FULFILLED,
      data: res.data.resource,
      meta: projectRoles,
    });
    yield put(
      showNotification({
        identifier: 'project-deleted-notification',
        type: 'success',
        text: i18next.t('common:notification.project_deleted_success'),
        autoClose: 2000,
      })
    );
    yield put(reset('confirm-delete'));
    yield put({ type: TOGGLE_MODAL, selected: 'confirm' });
  } catch (error) {
    // how to get response? res is undefined, if used with let?
    yield put(
      showNotification({
        identifier: 'project-deleted-notification',
        type: 'error',
        text: i18next.t('common:notification.project_delete_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: DELETE_PROJECT_REJECTED });
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: DELETE_PROJECT_REJECTED });
    }
  }
}

export function* selectDefaultProject(action = { projectID: undefined }) {
  yield take(GET_USER_PROJECTS_FULFILLED);
  if (typeof action.projectID === 'undefined') {
    const projects = yield select(state => get(state, 'projects.user', {}));
    const project: Project = head(Object.values(projects));
    if (!isEmpty(project)) {
      yield put({ type: CHANGE_SELECTED_PROJECT, projectID: project.id });
    }
  } else {
    yield put({ type: CHANGE_SELECTED_PROJECT, projectID: action.projectID });
  }
}

export default function* projectSaga() {
  yield all([
    fork(selectDefaultProject),
    takeLatest(EDIT_PROJECT, getProject),
    takeLatest(SAVE_PROJECT, saveProject),
    takeLatest(CREATE_PROJECT, createProject),
    takeLatest(DELETE_PROJECT, deleteProject),
    takeLatest(GET_DATA_POINTS, selectDefaultProject),
    takeLatest(TOGGLE_MODAL, clearProjectFormOnBlur),
  ]);
}
