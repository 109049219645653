import {
  ADProjectroleActionTypes,
  ADProjectrolesState,
  GET_ADPROJECTROLES_FULFILLED,
  GET_ADPROJECTROLES_PENDING,
} from 'store/adprojectroles/types';

const initialState: ADProjectrolesState = {
  loading: false,
  roles: [],
};

export default (state = initialState, action: ADProjectroleActionTypes) => {
  switch (action.type) {
    case GET_ADPROJECTROLES_FULFILLED:
      return {
        ...state,
        loading: false,
        roles: action.data,
      };
    case GET_ADPROJECTROLES_PENDING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
