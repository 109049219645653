import { isArray } from 'lodash';
import { MetaState } from 'store/meta/types';
import { GET_USER_REJECTED } from 'store/user/types';

const initialState: MetaState = {
  loading: false,
  queue: [],
  critical_error: false,
};

const critical = [GET_USER_REJECTED];

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'CRITICAL_ERROR_NOT_ADMIN':
      return {
        ...initialState,
        critical_error: true,
        critical_error_message: action.payload.message,
        critical_error_title: action.payload.title,
      };
    default:
      let newState = { ...state };

      if (isArray(action.type.match(/_PENDING$/))) {
        const cleanedActionType = action.type.replace('_PENDING', '');
        newState = {
          ...newState,
          queue: [...state.queue, cleanedActionType],
        };
      }

      if (
        isArray(action.type.match(/_FULFILLED$/)) ||
        isArray(action.type.match(/_REJECTED$/))
      ) {
        const cleanedActionType = action.type
          .replace('_FULFILLED', '')
          .replace('_REJECTED', '');
        // only clean one, not all
        const newQueue = [];

        let found = 0;
        newState.queue.forEach(item => {
          if (item === cleanedActionType && found === 0) {
            found = 1;
            return;
          }

          newQueue.push(item);
          return;
        });

        newState = {
          ...newState,
          queue: newQueue,
        };
      }

      if (isArray(newState.queue) && newState.queue.length > 0) {
        newState.loading = true;
      } else {
        newState.loading = false;
      }

      if (critical.includes(action.type)) {
        return {
          ...newState,
          critical_error: true,
        };
      }

      return newState;
  }
};
