import styled from 'styled-components';
import { rgba } from 'polished';

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -15px;
  width: calc(100% + 30px);

  &.--user-wrapper {
    margin-top: -50px;
  }
`;

export const StyledCard: any = styled.div`
  width: calc(33.333% - 30px);

  box-shadow: 0px 3px 6px ${rgba('#314EA3', 0.16)};
  margin: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: calc(60px + 15px);

  @media (max-width: 1200px) {
    width: calc(50% - 30px);
  }

  @media (max-width: 920px) {
    width: calc(100% - 30px);
  }

  .card__footer-opener {
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.textColor};

    > span + span {
      margin-left: 10px;
    }

    span:last-child {
      float: right;
    }
  }

  &.--default-margin {
    margin: 15px !important;
  }

  .card__footer-body {
    padding-top: 15px;
  }

  .card__projects-icon,
  .card__projects-description {
    color: ${({ theme }) => rgba(theme.textColor, 0.7)};
  }

  .card__projects-number {
    font-family: ${({ theme }) => theme.fontFamilyBold};
  }

  .card__projects-arrow {
    color: ${({ theme }) => rgba(theme.textColor, 0.4)};
  }

  .card__projects-arrow.--hide {
    display: none;
  }

  .card__header > figure {
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0;

    > img {
      width: 100%;
      height: auto;
    }
  }

  .--no-header .card__body {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .--no-footer .card__body {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.--type-avatar {
    margin-top: 65px;
  }

  &.--type-avatar .card__header {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    z-index: 100;

    > figure {
      border-radius: 100%;
      display: inline-flex;

      > img {
        width: 100px;
        height: 100px;
      }
    }
  }

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &.--type-avatar .card__body {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .card__body p + .--with-tags {
    margin-top: 15px;
  }

  &.--type-avatar .card__header + .card__body {
    padding-top: 70px;
  }

  & .--align-center .card__body {
    text-align: center;
    align-items: center;
  }

  .card__actions {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .card__body {
    padding: 20px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: ${({ theme }) => theme.purple2};

    h3 {
      margin: 0;
      font-size: ${({ theme }) => theme.fontSize3};
      font-family: ${({ theme }) => theme.fontFamilyBold};
    }

    p {
      margin: 0;
      font-size: ${({ theme }) => theme.fontSize4};
      color: ${({ theme }) => rgba(theme.purple6, 0.8)};
    }

    h3 + p {
      margin-top: 5px;
    }
  }

  .card__footer.--overlay {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 200;
  }

  .card__footer {
    background: ${({ theme }) => theme.purple3};
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.--type-avatar + .--empty-card {
    margin-top: 63px;
  }

  @media (max-width: 1200px) {
    &:nth-child(2n + 1).--empty-card {
      margin-top: 15px;
      min-height: 200px;
    }
  }
`;

export const StyledEmptyCard = styled(StyledCard)`
  display: flex;
  margin-bottom: 15px;

  > .card__button {
    flex: 1;
    border-radius: 5px;
    border: 2px dashed #0071a7;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    background: radial-gradient(#303269, #262751);
    text-decoration: none;
    color: ${({ theme }) => theme.textColor};
    min-height: 150px;
  }

  svg {
    color: #0071a7;
  }

  .card__button-label {
    margin-top: 5px;
    font-family: ${({ theme }) => theme.fontFamilyBold};
    font-size: ${({ theme }) => theme.fontSize3};
  }
`;
