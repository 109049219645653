import {
  ProjectState,
  ProjectActionTypes,
  CHANGE_SELECTED_PROJECT,
} from 'store/project/types';

const initialState: ProjectState = {};

export default function(state = initialState, action: ProjectActionTypes) {
  switch (action.type) {
    case CHANGE_SELECTED_PROJECT:
      return {
        ...state,
        selected: action.projectID,
      };
    default:
      return state;
  }
}
