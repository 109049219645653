import {
  ADCompanyroleActionTypes,
  ADCompanyrolesState,
  GET_ADCOMPANYROLES_FULFILLED,
  GET_ADCOMPANYROLES_PENDING,
} from 'store/adcompanyroles/types';

const initialState: ADCompanyrolesState = {
  loading: false,
  roles: [],
};

export default (state = initialState, action: ADCompanyroleActionTypes) => {
  switch (action.type) {
    case GET_ADCOMPANYROLES_FULFILLED:
      return {
        ...state,
        loading: false,
        roles: action.data,
      };
    case GET_ADCOMPANYROLES_PENDING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
