import React, { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import Field, {
  StyledReduxFormSelect,
  FieldRow,
} from 'components/ui/Form/Form';

import { useSelector } from 'react-redux';
import { StateType } from 'src/store/types';

const SelectCompanyForm: FC<{}> = () => {
  const { t } = useTranslation();

  const { loading, companies } = useSelector(
    (state: StateType) => state.adcompanies
  );

  return (
    <FieldRow>
      <Field type="hidden" name="company" />
      <Field
        label={t('projects:form.select_company')}
        name="projects"
        component={StyledReduxFormSelect}
        options={
          companies && companies.length > 0
            ? companies.map((company: any) => {
              return {
                label: company.name,
                value: company.id,
              };
            })
            : null
        }
      />
    </FieldRow>
  );
};

export default reduxForm<{}>({
  form: 'projects-company-select',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(SelectCompanyForm);
