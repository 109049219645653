const initialState: any = {};

export default function(state = initialState, action: any) {
  switch (action.type) {
    case 'GET_ENDPOINT_ENUMERATIONS_FULFILLED':
      return {
        ...state,
        ...action.endpoints,
      };
    default:
      return state;
  }
}
