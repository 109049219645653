import { union, keys, reduce, isEqual, isEmpty, replace } from 'lodash';
import { validateAll } from 'indicative';
import i18n from 'i18n/i18n';

import { SubmissionError } from 'redux-form';

interface FlatObject {
  [key: string]: string | number;
}

/**
 * Function normalizes two given objects. Which means that the
 * two given objects are compared and only the changed key values paires are returned
 * in an new object. If no values changed the function returns false
 *
 * @param {object} initial - Inital object
 * @param {object} values - Object with changed values
 * @return Either an object with the changed values or false if none have changed
 */
export function normalize(
  initial: FlatObject,
  values: FlatObject
): FlatObject | false {
  const reduced = reduce(
    union(keys(initial), keys(values)),
    (result, key) => {
      if (!isEqual(initial[key], values[key])) {
        result[key] = values[key];
      }
      return result;
    },
    {}
  );

  return isEmpty(reduced) ? false : reduced;
}

/**
 * Validates the given values with a object set of rules. If any errors occures
 * they will reduced to an error object with the right error messages for the keys
 *
 *
 * @param {object} values - values to validate
 * @param {object} rules - set of validation rules
 * @return returns a promise or throw an new error
 */
export function validate(values: FlatObject, rules: FlatObject = {}) {
  // First get messages for validation, then flat 1 level
  // with field names and get fields to get translated field names.
  const messages = i18n.t('common:validation', {
    returnObjects: true,
    interpolation: { prefix: '[[', suffix: ']]' },
  });

  /**
   * Added '_' into the regexp to allow required fields to be replaced, named with underscores
   * */
  return validateAll(values, rules, messages).catch(errors => {
    throw errors.reduce((acc, error) => {
      acc[error.field] = replace(
        error.message,
        /<<([a-z_A-Z]+)>>/gm,
        (replace, capture) => i18n.t(`common:fields.${capture}`, capture)
      );
      return acc;
    }, {});
  });
}

/**
 * Uses the validate function and calls the callback if successful
 * or throws an new SubmissionError if the validation fails.
 *
 * @param {object} values - values to validate
 * @param {object} rules - set of validation rules
 * @callback callback with () => void signature
 * @return returns a new submission error or calls the callback if no error occures
 */
export function validateWithCallback(
  values: FlatObject,
  rules: FlatObject = {},
  callback: () => void
) {
  return validate(values, rules)
    .catch(errors => {
      throw new SubmissionError(errors);
    })
    .then(() => {
      callback();
    });
}
