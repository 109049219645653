import { Flow, Step } from 'store/flow/types';
import i18next from 'i18next';

/**
 * All Flows and Steps are hardcoded in here
 * */

/*
 * Steps for creating a new costumer
 * */
const CreateCostumerSteps: Array<Step> = [
  {
    action: '/companies',
    name: 'common:steps.create_new_company.name',
    description: 'common:steps.create_new_company.description',
    id: 1,
    status: false,
    active: false,
  },
  {
    action: '/projects',
    name: 'common:steps.create_new_project.name',
    description: 'common:steps.create_new_project.description',
    id: 2,
    status: false,
    active: false,
  },
  {
    action: '/users',
    name: 'common:steps.create_new_user.name',
    description: 'common:steps.create_new_user.description',
    id: 3,
    status: false,
    active: false,
  },
];

/*
 * Create new costumer flow
 * */
const Flows: Array<Flow> = [
  {
    currentStep: null,
    description: 'common:flows.create_costumer.description',
    id: 1,
    name: 'common:flows.create_costumer.name',
    steps: CreateCostumerSteps,
    icon: 'user-plus',
    modal: 'company',
  },
];

function getFlow(id: number) {
  return Object.assign({}, Flows.find(flow => flow.id === id));
}

export { getFlow, Flows };
