import {
  CREATE_ADUSER,
  CREATE_ADUSER_FULFILLED,
  CREATE_ADUSER_PENDING,
  CREATE_ADUSER_REJECTED,
  DELETE_ADUSER,
  DELETE_ADUSER_FULFILLED,
  DELETE_ADUSER_PENDING,
  DELETE_ADUSER_REJECTED,
  EDIT_ADUSER,
  GET_ADUSERS,
  GET_ADUSERS_FULFILLED,
  GET_ADUSERS_PENDING,
  GET_ADUSERS_REJECTED,
  GET_ADUSER_PENDING,
  GET_ADUSER_REJECTED,
  EDIT_ADUSER_REJECTED,
  EDIT_ADUSER_PENDING,
  SAVE_ADUSER_PENDING,
  SAVE_ADUSER,
  SAVE_ADUSER_FULFILLED,
  SAVE_ADUSER_REJECTED,
  QUICK_CREATE_USER,
} from 'store/adusers/types';

import { getADCompanyRoles } from 'store/adcompanyroles/actions';

import {
  create_user,
  delete_user,
  get_user,
  get_users,
  put_user,
} from 'utils/adminAxiosInstance';

import { get } from 'lodash';

import {
  takeLatest,
  put,
  select,
  take
} from '@redux-saga/core/effects';
import { showNotification } from 'store/notification/actions';
import {  destroy, initialize, reset } from 'redux-form';
import i18next from 'i18next';
import { TOGGLE_MODAL } from 'store/modal/types';
import { StateType } from 'store/types';
import {
  GET_ADCOMPANYROLES_FULFILLED,
} from 'store/adcompanyroles/types';
import { getADProjectRoles } from 'store/adprojectroles/actions';
import { GET_ADPROJECTROLES_FULFILLED } from 'store/adprojectroles/types';
import { finishStep } from 'store/flow/actions';

export function* getADUser(action) {
  const user_id = action.data;

  yield put({ type: EDIT_ADUSER_PENDING });
  yield put({ type: GET_ADUSER_PENDING });

  try {
    const req = yield get_user(user_id);
    const res = yield req;
    const company = yield select((state: StateType) =>
      state.adcompanies.companies.find(
        company => company.id === res.data.company.id
      )
    );
    res.data.company_id = { label: company.name, value: company.id };
    yield put(getADCompanyRoles(company.id));
    yield take(GET_ADCOMPANYROLES_FULFILLED);
    const company_roles = yield select(
      (state: StateType) => state.adcompanyroles.roles
    );

    const projectroles = yield select(
      (state: StateType) => state.adprojectroles
    );

    if (
      !projectroles.loading &&
      projectroles.roles &&
      projectroles.roles === []
    ) {
      yield put(getADProjectRoles(undefined));
      yield take(GET_ADPROJECTROLES_FULFILLED);
    }

    const project_roles = projectroles.roles;

    const users_company_roles = company_roles.filter(role =>
      res.data.company_roles.includes(role.id)
    );

    const users_project_roles = project_roles.filter(role =>
      res.data.project_roles.includes(role.id)
    );

    res.data.company_roles = users_company_roles.map(role => {
      return { label: role.name, value: role.id };
    });

    res.data.project_roles = users_project_roles.map(role => {
      return { label: `${role.name}: ${role.description}`, value: role.id };
    });

    res.data.active =
      res.data.active === true ? { ACTIVE: true } : { ACTIVE: false };

    yield put(
      initialize('user-create', res.data, {
        updateUnregisteredFields: true,
      })
    );

    yield put({ type: 'TOGGLE_MODAL', selected: 'user', edit: true });
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'user-edit-notification',
        type: 'error',
        text: err.response.data.error, //i18next.t('companies:notification.company_fetch_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: EDIT_ADUSER_REJECTED });
    yield put({ type: GET_ADUSER_REJECTED });
  }
}

export function* getADUsers(action) {
  const company_ids = action.data;

  try {
    const req = yield get_users(company_ids ? company_ids : null);
    yield put({ type: GET_ADUSERS_PENDING });
    const res = yield req;
    let userslist = [];
    res.map(response => {
      userslist = userslist.concat(response.data);
    });
    yield put({ type: GET_ADUSERS_FULFILLED, data: userslist });
  } catch (err) {
    yield put({ type: GET_ADUSERS_REJECTED });
    yield put(
      showNotification({
        identifier: 'users-notification',
        type: 'error',
        text: err.response.data.error,
        autoClose: 2000,
      })
    );
  }
}

export function* addADUser(action) {
  let { company_id } = action.data;
  company_id = company_id.value;
  const history = action.his;

  yield put({ type: CREATE_ADUSER_PENDING });

  try {
    const req = create_user(
      action.data.email,
      action.data.firstName,
      action.data.lastName,
      action.data.password,
      parseInt(company_id),
      action.data.active ? action.data.active.ACTIVE : false,
      action.data.address,
      action.data.avatar_url,
      action.data.company_roles
        ? action.data.company_roles.map(crole => parseInt(crole.value))
        : undefined,
      action.data.phone,
      action.data.project_roles
        ? action.data.project_roles.map(prole => parseInt(prole.value))
        : undefined
    );
    const res = yield req;
    yield put({
      type: CREATE_ADUSER_FULFILLED,
      data: res.data,
    });

    const currentFlow = yield select((state: StateType) => state.currentFlow);
    yield put(destroy('user-create'));
    yield put({ type: 'TOGGLE_MODAL', selected: 'user' });
    if (currentFlow.isActive) {
      yield put(finishStep(currentFlow.flow.currentStep.id, history));
    }
    /**
     * No notification for successful creation is needed, if an email notification will be sent.
     */
    /* yield put(
      showNotification({
        identifier: 'user-created-notification',
        type: 'success',
        text: i18next.t('users:notification.user_created_success'),
        autoClose: 2000,
      })
    ); */

    /**
     * Add notification for the creator, that the newly created user will get an welcome-email
    */
   yield put(
    showNotification({
      identifier: 'user-created-email-notification',
      type: 'success',
      text: i18next.t('users:notification.user_created_email', { username: res.data.email}),
      autoClose: 6000,
    })
  );
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'user-created-notification',
        type: 'error',
        text: err.response.data.error, //i18next.t('companies:notification.company_created_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: CREATE_ADUSER_REJECTED });
  }
}

export function* putADUser(action) {
  const values = yield select(state =>
    get(state, 'form.user-create.values', {})
  );
  const {
    id,
    email,
    avatar_url,
    firstName,
    lastName,
    phone,
    address,
    active,
    password,
    project_roles,
    company_roles,
  } = action.data;
  const company_id = values.company_id.value;
  yield put({ type: SAVE_ADUSER_PENDING });

  try {
    const req = yield put_user(
      id,
      email,
      firstName,
      lastName,
      password,
      active.ACTIVE,
      address,
      avatar_url,
      company_id,
      company_roles === ''
        ? []
        : company_roles.map(role => {
          return parseInt(role.value);
        }),
      phone,
      project_roles === ''
        ? []
        : project_roles.map(role => {
          return parseInt(role.value);
        })
    );

    const res = yield req;
    res.data.active =
      res.data.active === true ? { ACTIVE: true } : { ACTIVE: false };
    yield put({ type: SAVE_ADUSER_FULFILLED, data: res.data });
    yield put({ type: TOGGLE_MODAL, selected: 'user' });
    yield put(
      showNotification({
        identifier: 'user-save-notification',
        type: 'success',
        text: i18next.t('users:notification.user_updated_success'),
        autoClose: 2000,
      })
    );
  } catch (err) {
    yield put({ type: SAVE_ADUSER_REJECTED });
    yield put({ type: TOGGLE_MODAL, selected: 'user' });
    yield put(
      showNotification({
        identifier: 'user-save-notification',
        type: 'error',
        text: err.response.data.error,
        autoClose: 2000,
      })
    );
  }
}

export function* deleteADUser(action) {
  const user_id = action.data;

  yield put({ type: TOGGLE_MODAL, selected: 'confirm', style: 'box' });
  yield take('CONFIRM_DELETE');
  yield put({ type: DELETE_ADUSER_PENDING });

  try {
    const req = delete_user(user_id);
    const res = yield req;
    yield put({ type: DELETE_ADUSER_FULFILLED, data: user_id });
    yield put(
      showNotification({
        identifier: 'user-delete-notification',
        type: 'success',
        text: i18next.t('users:notification.user_deleted_success'),
        autoClose: 2000,
      })
    );
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'user-created-notification',
        type: 'error',
        text: err.response.data.error, //i18next.t('companies:notification.company_created_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: DELETE_ADUSER_REJECTED });
  }

  yield put(reset('confirm-delete'));
  yield put({ type: TOGGLE_MODAL, selected: 'confirm' });

}

export function* redirectAndCreate(action) {
  const { history } = action.data;

  /**
   * Redirect to companies index-view
   */
  history.push('users');

  /**
   * Clear the company-create form
   */
  yield put(destroy('user-create'));

  /**
   * Open the corresponding creation-modal
   */
  yield put({ type: TOGGLE_MODAL, selected: 'user', edit: false})

}

export default function* adusersSaga() {
  yield takeLatest(GET_ADUSERS, getADUsers);
  yield takeLatest(CREATE_ADUSER, addADUser);
  yield takeLatest(DELETE_ADUSER, deleteADUser);
  yield takeLatest(EDIT_ADUSER, getADUser);
  yield takeLatest(SAVE_ADUSER, putADUser);
  yield takeLatest(QUICK_CREATE_USER, redirectAndCreate);
}
