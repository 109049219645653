import {
    InitializerActionTypes,
    CREATE_INITIALIZER,
    INITIALIZERS,
} from "./types"

export const triggerInitializer = ({
    initializer,
    company_id,
    project_id
}: {
    initializer: INITIALIZERS,
    company_id?: number,
    project_id?: number
}): InitializerActionTypes => {
    const payload = {
        initializer,
        company_id,
        project_id,
    };

    for (let key in payload) {
        if (payload[key] === undefined) {
            delete payload[key];
        }
    }

    return {
        type: CREATE_INITIALIZER,
        resource: payload,
    }
};