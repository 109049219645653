import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import ModalContainer from 'container/CompanyDetails/ModalContainer';
import CompanyDetailsForm from 'container/Forms/CompanyDetailsForm';
//import RoleMatrixForm from 'container/Forms/RoleMatrixForm';
import CreateProject from 'container/Forms/CreateProjectForm';
import AssignProjectRole from 'container/Forms/AssignProjectRole';
import ConfirmModalForm from 'container/Forms/ConfirmModalForm';
import CreateUser from 'container/Forms/CreateUserForm';

import { SAVE_COMPANY } from 'store/company/types';
/*import { CREATE_ROLE, SAVE_COMPANY_ROLE } from 'store/roles/types';*/
import { CREATE_PROJECT, SAVE_PROJECT } from 'store/project/types';
import { showNotification } from 'store/notification/actions';
import { CREATE_USER } from 'store/user/types';
import { StateType } from 'store/types';

import { validateWithCallback } from 'utils/form';

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector((state: StateType) => state.modal);
  const companyAvatarUrl = useSelector((state: StateType) => get(state, 'company.company.avatar_url', null));

  return (
    <>
      <CompanyDetailsForm
        companyAvatarUrl={companyAvatarUrl}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              name: 'required',
            },
            () => dispatch({ type: SAVE_COMPANY, values })
          )
        }
        onSubmitFail={errors => {
          console.log(errors);
          showNotification({
            identifier: 'validation-error-notification',
            type: 'error',
            text: t('common:validation.general'),
            autoClose: 2000,
          });
        }}
      />
      <ModalContainer
        identifier={'user'}
        Component={CreateUser}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              email: 'required|email',
              firstName: 'required',
              lastName: 'required',
              password: 'required_with_all:passwordConfirmation|required',
              passwordConfirmation: 'required_with_all:password|same:password',
            },
            () => dispatch({ type: CREATE_USER, values })
          )
        }
      />
      {/*<ModalContainer
        identifier={'roles'}
        Component={RoleMatrixForm}
        isEdit={modal.edit}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              name: 'required',
            },
            () =>
              modal.edit
                ? dispatch({ type: SAVE_COMPANY_ROLE, values })
                : dispatch({ type: CREATE_ROLE, values })
          )
        }
      />*/}
      <ModalContainer
        identifier={'project'}
        Component={CreateProject}
        isEdit={modal.edit}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              name: 'required',
            },
            () =>
              modal.edit
                ? dispatch({ type: SAVE_PROJECT, values })
                : dispatch({ type: CREATE_PROJECT, values })
          )
        }
      />
      <ModalContainer
        identifier={'confirm'}
        Component={ConfirmModalForm}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              delete: 'required|equals:DELETE',
            },
            () => dispatch({ type: 'CONFIRM_DELETE' })
          )
        }
      />
      <ModalContainer
        identifier={'project-roles'}
        Component={AssignProjectRole}
        isEdit={modal.edit}
        onSubmit={values => {
          dispatch({ type: 'SAVE_PROJECT_ROLES', values });
        }}
      />
    </>
  );
};
