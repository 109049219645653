import React, { FC } from 'react';
// import { __RouterContext } from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import * as Routes from 'constants/routes';

import AuthCallback from 'components/functional/AuthCallback/AuthCallback';
import AuthRoute from 'components/functional/AuthRoute/AuthRoute';
import Logout from 'components/functional/Logout/Logout';

import AuthRouting from 'container/Routing/AuthRouting';
import Login from 'container/Login/Login';

/**
 * This is the root routing component. Every redirect or
 * history change will trigger a rerendering of the BrowserRouter.
 *
 * @returns {ReactNodeArray} BrowserRouter and Routes
 */
const Routing: FC<{}> = () => (
  <BrowserRouter>
    <Switch>
      <Route path={Routes.LOGIN} component={Login} />
      <Route path={Routes.LOGOUT} component={Logout} />
      <Route path={Routes.AUTH} component={AuthCallback} />
      <AuthRoute path={Routes.BASE} component={AuthRouting} />
    </Switch>
  </BrowserRouter>
);

export default Routing;
