import styled from 'styled-components';

export const StyledDropdown = styled.div`
  > .dropdown {
    margin-top: 5px;
    background: ${({ theme }) => theme.purple1};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    min-width: 200px;
    position: relative;
    z-index: 1000;
    max-width: 500px;
  }

  .dropdown--header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .dropdown--footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .dropdown--header,
  .dropdown--footer {
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.5);
    padding: 15px 25px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
  }

  .dropdown--content {
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: left;

      a,
      span {
        cursor: pointer;
        display: inline-block;
        padding: 20px 25px;
        color: ${({ theme }) => theme.textColor};
        text-decoration: none;
        width: 100%;
        font-size: 16px;

        &:hover {
          background: ${({ theme }) => theme.purple5};
        }
      }
    }
  }

  .dropdown--content.--no-footer {
    ul > li:last-child > a {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    ul > li:last-child > span {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .dropdown--content.--no-header.--item-count-1 {
    ul > li:last-child > a {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    ul > li:last-child > span {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
`;

export const StyledRef = styled.div`
  width: 100%;
  cursor: pointer;
  &.fixed-dropdown {
    position: fixed;
  }
  &.flex-dropdown {
    display: inline-flex;
    flex-direction: row-reverse;
  }
`;
