import * as H from 'history';

/*
 * Types
 * */

export const SET_CURRENT_FLOW = 'SET_CURRENT_FLOW';
export const UPDATE_CURRENT_FLOW = 'UPDATE_CURRENT_FLOW';
export const CHANGE_CURRENT_FLOW = 'CHANGE_CURRENT_FLOW';
export const FINISH_CURRENT_FLOW_STEP = 'FINISH_CURRENT_FLOW_STEP';
export const FINISH_CURRENT_FLOW = 'FINISH_CURRENT_FLOW';
export const RESET_CURRENT_FLOW = 'RESET_CURRENT_FLOW';
export const CHANGE_CURRENT_STEP = 'CHANGE_CURRENT_STEP';

/*
 * Models
 * */

export interface Step {
  name: string;
  description: string;
  id: number;
  action: string;
  status: boolean;
  active: boolean;
}

export interface Flow {
  name: string;
  description: string;
  id: number;
  steps: Array<Step>;
  currentStep: Step;
  icon: string;
  modal: string;
}

export interface FlowRouterHistory {
  history: H.History;
}

export interface FlowHasDataId {
  data_id: number;
}

/*
 * State Type
 * */
export interface CurrentFlowState {
  readonly flow?: Flow;
  readonly isActive: boolean;
  readonly data_id?: number;
}

/*
 * Action Types
 * */

interface SetCurrentFlowActionType {
  type: typeof SET_CURRENT_FLOW;
  data: number; //flow_id
  history: H.History;
}

interface UpdateCurrentFlowActionType {
  type: typeof UPDATE_CURRENT_FLOW;
  data: {
    currentStep: number; //step_id
  };
}

interface ChangeCurrentFlowActionType {
  type: typeof CHANGE_CURRENT_FLOW;
  data: number; //flow_id
}

interface FinishCurrentStepActionType extends FlowRouterHistory {
  type: typeof FINISH_CURRENT_FLOW_STEP;
  data: number; //step_id
}

interface ChangeCurrentStepActionType extends FlowRouterHistory {
  type: typeof CHANGE_CURRENT_STEP;
  data: number; //step_id
}

export type CurrentFlowActionTypes =
  | SetCurrentFlowActionType
  | UpdateCurrentFlowActionType
  | ChangeCurrentFlowActionType
  | FinishCurrentStepActionType
  | ChangeCurrentStepActionType;
