import {
  call,
  put,
  select,
  all,
  takeLatest,
  take,
  fork,
  cancelled,
  takeEvery,
} from 'redux-saga/effects';
import { initialize, reset } from 'redux-form';
import { get, map, pick, omit, isEmpty, reduce } from 'lodash';
import i18next from 'i18next';

import { request } from 'utils/axiosInstance';
import { showNotification } from 'store/notification/actions';
import { normalize } from 'utils/form';

import {
  GET_USER_PENDING,
  GET_USER_FULFILLED,
  // GET_USER_PROJECTS_VIA_ROLES_PENDING,
  // GET_USER_PROJECTS_VIA_ROLES_FULFILLED,
  SAVE_USER,
  SAVE_USER_PENDING,
  SAVE_USER_FULFILLED,
  SAVE_USER_REJECTED,
  CREATE_USER,
  CREATE_USER_PENDING,
  CREATE_USER_FULFILLED,
  CREATE_USER_REJECTED,
  DELETE_USER,
  DELETE_USER_PENDING,
  DELETE_USER_FULFILLED,
  DELETE_USER_REJECTED,
  GET_USER_REJECTED,
  DISABLE_PASSWORD_CHANGE,
  UPLOAD_USER_AVATAR,
  UPLOAD_USER_AVATAR_REJECTED,
  UPLOAD_USER_AVATAR_FULFILLED,
  UPLOAD_USER_AVATAR_PENDING,
} from 'store/user/types';

import {
  GET_USER_PROJECTS_PENDING,
  GET_USER_PROJECTS_FULFILLED,
  GET_USER_PROJECTS_REJECTED,
} from 'store/projects/types';

import { TOGGLE_MODAL } from 'store/modal/types';

function* assignCompanyRole(role_id, user_id) {
  try {
    const [postCompanyRoleUser] = yield request('POST_COMPANY_ROLE_USER', {
      role_id,
      user_id,
    });
    return postCompanyRoleUser;
  } catch (error) {
    console.log(error);
  }
}

function* assignProjectRole(project_id, role_id, user_id) {
  try {
    const [postProjectRoleUser] = yield request('POST_PROJECT_ROLE_USER', {
      project_id,
      role_id,
      user_id,
    });
    return postProjectRoleUser;
  } catch (error) {
    console.log(error);
  }
}

export function* fetchUserProjects() {
  yield put({ type: GET_USER_PROJECTS_PENDING });
  const [getUserProjects] = yield request('GET_USER_PROJECTS');

  try {
    const res = yield getUserProjects;

    const userProjectsWithId = reduce(
      res.data,
      (acc, value) => {
        return { ...acc, [value.project.id]: value.project };
      },
      {}
    );

    yield put({ type: GET_USER_PROJECTS_FULFILLED, data: userProjectsWithId });
  } catch (error) {
    yield put({ type: GET_USER_PROJECTS_REJECTED });
  }
}

export function* fetchUser() {
  // yield take(USER_FOUND);
  const loading = yield select(state => state.auth.loading);
  if (loading) throw Error('no user loaded');

  yield put({ type: GET_USER_PENDING });

  // prepare request
  const [getUser] = yield request('GET_USER');

  try {
    const res = yield getUser;
    yield put({ type: GET_USER_FULFILLED, data: res.data });

    // put project roles
    /*
    const projectRoles = reduce(res.roles, (acc, role) => {
      acc[role.id]
    }, {});
     */

    // get other related user data
    yield all([call(fetchUserProjects)]);
  } catch (error) {
    yield put({ type: GET_USER_REJECTED });
  }
}

export function* saveUser(action) {
  const { values } = action;

  const initial = yield select(state =>
    get(state, 'form.user-details.initial', {})
  );
  const normalized = normalize(initial, values);

  // split password change and user settings for user/password endpoint
  const passwordProperties = [
    'currentPassword',
    'password',
    'passwordConfirmation',
  ];

  const userDetailsNormalized = omit({ ...normalized }, passwordProperties);
  const passwordNormalized = pick({ ...normalized }, passwordProperties);

  yield put({ type: SAVE_USER_PENDING });

  if (!isEmpty(userDetailsNormalized)) {
    const [putUser, cancel] = yield request('PUT_USER', userDetailsNormalized);
    try {
      const res = yield putUser;
      yield put({ type: SAVE_USER_FULFILLED, data: res.data.resource });
      yield put(
        showNotification({
          identifier: 'user-saved-notification',
          type: 'success',
          text: i18next.t('common:notification.user_saved_success'),
          autoClose: 2000,
        })
      );
      yield put(initialize('user-details', values));
    } catch (error) {
      yield put({ type: SAVE_USER_REJECTED });
    } finally {
      if (yield cancelled()) {
        cancel('cancelled');
        yield put({ type: SAVE_USER_REJECTED });
      }
    }
  }

  if (!isEmpty(passwordNormalized)) {
    const [putPassword, cancelPassword] = yield request(
      'PUT_USER_PASSWORD',
      {
        // @ts-ignore
        current_password: passwordNormalized.currentPassword,
        // @ts-ignore
        new_password: passwordNormalized.password,
      },
      {
        header: {
          'Content-Type': 'multipart/form-data',
        },
        formData: true,
      }
    );

    try {
      const res = yield putPassword;
      yield put({ type: SAVE_USER_FULFILLED, data: res.data.resource });
      yield put(
        showNotification({
          identifier: 'user-saved-notification',
          type: 'success',
          text: i18next.t('common:notification.password_saved_success'),
          autoClose: 2000,
        })
      );
      yield put(initialize('user-details', omit(values, passwordProperties)));
    } catch (error) {
      let errorMessage = '';
      switch (error.response.status) {
        case 422:
          errorMessage = i18next.t('common:notification.password_invalid');
          break;
        case 423:
          errorMessage = i18next.t('common:notification.password_max_attempts');
          yield put({ type: DISABLE_PASSWORD_CHANGE });
          break;
      }

      yield put(
        showNotification({
          identifier: 'user-saved-notification',
          type: 'error',
          text: errorMessage,
          autoClose: 2000,
        })
      );
      yield put({ type: SAVE_USER_REJECTED });
    } finally {
      if (yield cancelled()) {
        cancelPassword('cancelled');
        yield put({ type: SAVE_USER_REJECTED });
      }
    }
  }
}

export function* createUser(action) {
  // const source = axios.CancelToken.source();
  const { values } = action;

  // get role ids
  const roles = map(values.roles, el => el.value);
  delete values.roles;

  // get project ids
  const projects = map(values.projects, el => el.value);
  delete values.projects;

  const companyProjects = yield select(state => state.projects.company);

  yield put({ type: CREATE_USER_PENDING });
  const [postUser, cancel] = yield request('POST_COMPANY_USER', values);
  try {
    const res = yield postUser;

    // add roles to user ...
    yield all(
      roles.map(role => call(assignCompanyRole, role, res.data.resource.id))
    );

    // add assign projects to user ... currently only project admin role
    const assignedProjectRoles = [];
    yield all([
      ...projects.map(project => {
        const projectRole = companyProjects[project].roles[0].id;
        assignedProjectRoles.push(projectRole);
        return call(
          assignProjectRole,
          project,
          projectRole,
          res.data.resource.id
        );
      }),
    ]);

    const userDefaultValues = {
      company_roles: roles,
      project_roles: assignedProjectRoles,
    };
    yield put({
      type: CREATE_USER_FULFILLED,
      data: { ...userDefaultValues, ...res.data.resource },
    });
    yield put(
      showNotification({
        identifier: 'user-created-notification',
        type: 'success',
        text: i18next.t('common:notification.user_created_success'),
        autoClose: 2000,
      })
    );
    yield put(reset('user-create'));
    yield put({ type: 'TOGGLE_MODAL', selected: 'user' });
  } catch (error) {
    // how to get response? res is undefined, if used with let?
    yield put(
      showNotification({
        identifier: 'user-created-notification',
        type: 'error',
        text: i18next.t('common:notification.user_create_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: CREATE_USER_REJECTED });
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: CREATE_USER_REJECTED });
    }
  }
}

export function* deleteUser(action) {
  const { userID } = action;

  // open confirm modal and wait for confirm dispatch
  yield put({ type: TOGGLE_MODAL, selected: 'confirm', style: 'box' });
  yield take('CONFIRM_DELETE');
  yield put({ type: DELETE_USER_PENDING });

  // prepare
  const [deleteCompanyUser, cancel] = yield request('DELETE_COMPANY_USER', {
    user_id: userID,
  });

  try {
    const res = yield deleteCompanyUser;
    yield put({
      type: DELETE_USER_FULFILLED,
      data: res.data.resource,
    });
    yield put(
      showNotification({
        identifier: 'user-deleted-notification',
        type: 'success',
        text: i18next.t('common:notification.user_deleted_success'),
        autoClose: 2000,
      })
    );
    yield put(reset('confirm-delete'));
    yield put({ type: TOGGLE_MODAL, selected: 'confirm' });
  } catch (error) {
    // how to get response? res is undefined, if used with let?
    yield put(
      showNotification({
        identifier: 'user-deleted-notification',
        type: 'error',
        text: i18next.t('common:notification.user_delete_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: DELETE_USER_REJECTED });
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: DELETE_USER_REJECTED });
    }
  }
}

function* uploadUserAvatar(action) {
  const { files } = action;
  const userID = yield select(state => state.user.details.id);

  yield put({ type: UPLOAD_USER_AVATAR_PENDING });
  const [postUserAvatar] = yield request(
    'POST_USER_AVATAR',
    {
      avatar: files[0],
    },
    { formData: true }
  );

  try {
    const res = yield postUserAvatar;

    yield put({
      type: UPLOAD_USER_AVATAR_FULFILLED,
      data: {
        id: userID,
        src: res.data.resource.avatar_url,
      },
    });

    // console.log(res);
  } catch (error) {
    console.log(error);
    yield put({ type: UPLOAD_USER_AVATAR_REJECTED });
  }
}

export default function* userSaga() {
  yield all([
    fork(fetchUser),
    takeLatest(SAVE_USER, saveUser),
    takeLatest(CREATE_USER, createUser),
    takeLatest(DELETE_USER, deleteUser),
    takeLatest(UPLOAD_USER_AVATAR, uploadUserAvatar),
  ]);
}
