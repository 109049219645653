import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import UserDetailsForm from 'container/Forms/UserDetailsForm';

import { showNotification } from 'store/notification/actions';
import { SAVE_USER } from 'store/user/types';
import { StateType } from 'store/types';

import { validateWithCallback } from 'utils/form';

export default props => {
  const projects = useSelector((state: StateType) => state.projects.user);
  const avatarUrl = useSelector((state: StateType) => get(state, 'user.details.avatar_url', null));
  const passwordLockedUntil = useSelector((state: StateType) =>
    get(state, 'user.details.passwordLockedUntil', false)
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <UserDetailsForm
        projects={projects}
        passwordLockedUntil={passwordLockedUntil}
        avatarUrl={avatarUrl}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              email: 'required|email',
              firstName: 'required',
              lastName: 'required',
              currentPassword: 'required_with_all:password',
              password:
                'required_with_all:currentPassword|same:passwordConfirmation|min:6',
              passwordConfirmation: 'required_with_all:password|same:password',
            },
            () => dispatch({ type: SAVE_USER, values })
          )
        }
        onSubmitFail={errors => {
          console.log(errors);
          showNotification({
            identifier: 'validation-error-notification',
            type: 'error',
            text: t('common:validation.general'),
            autoClose: 2000,
          });
        }}
      />
    </>
  );
};
