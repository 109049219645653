import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';

// fontawesome setup
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faCaretUp,
  faFilter,
  faStar as faStarFilled,
  faSortDown,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faSignOut,
  faEllipsisV,
  faAngleDown,
  faShieldAlt,
  faSearch,
  faTrash,
  faEdit,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faHome,
  faChartBar,
  faPlusSquare,
  faBuilding,
  faUsers,
  faUserPlus,
  faArrowToLeft,
  faArrowToRight,
  faUsersCog,
  faPencilAlt,
  faLongArrowRight,
  faChartScatter,
  faChartLine,
  faBell,
  faStar,
  faBookmark,
  faFileUpload,
} from '@fortawesome/pro-light-svg-icons';

import Routing from 'container/Routing/Routing';
import AuthProvider from 'components/functional/AuthProvider/AuthProvider';
import Alert from 'components/ui/Alert/Alert';
import ErrorBoundary from 'components/functional/ErrorBoundary/ErrorBoundary';
import { ModalContainer } from 'components/ui/Modal/Modal';
import { GlobalStyle } from 'container/Root/styles/RootStyles';

import defaultTheme from 'config/themes/default';
import store from 'store/store';
import 'i18n/i18n';

// fontawesome icons
library.add(
  faBell,
  faStar,
  faBookmark,
  faSignOut,
  faCaretDown,
  faEllipsisV,
  faBuilding,
  faCaretUp,
  faUsers,
  faFilter,
  faStarFilled,
  faUserPlus,
  faSortDown,
  faArrowToLeft,
  faArrowToRight,
  faAngleDown,
  faUsersCog,
  faShieldAlt,
  faPencilAlt,
  faSearch,
  faPlusSquare,
  faLongArrowRight,
  faTrash,
  faEdit,
  faCircle,
  faChartScatter,
  faChartLine,
  faPlus,
  faHome,
  faChartBar,
  faFileUpload
);

/**
 * This is the root react component. It serves as a wrapper for
 * the Redux Provider, the ThemeProvider amd imports the i18n instance.
 *
 * @returns {ReactNodeArray} Providers, AuthWrapper, Routing and GlobalStyle
 */
const Root: FC<{}> = () => (
  <Provider store={store}>
    <AuthProvider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <>
          <ErrorBoundary>
            <Alert />
            <ModalContainer />
            <Routing />
          </ErrorBoundary>
          <GlobalStyle />
        </>
      </ThemeProvider>
    </AuthProvider>
  </Provider>
);

export default Root;
