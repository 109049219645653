import React from 'react';
import styled from 'styled-components';

interface ItemsWillBeDeleted {
    message: string;
    items: string;
}

const Title = styled.h3`
    margin-bottom: -10px;
    margin-top: -5px;
`;

export const DeleteItemDetails = (props: ItemsWillBeDeleted) => {
    const allItems = props.items.split('+');

    return (
        <div>
            <Title>{props.message}</Title>
            <ul>
                {allItems.map((item: string, i: number) => <li key={i}>{item}</li>)}
            </ul>
        </div>
    );
}

export default DeleteItemDetails;
