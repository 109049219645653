import {
  call,
  put,
  select,
  all,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import qs from 'qs';

import { reduce } from 'lodash';

import {
  getAccessToken,
  getSelectedProjectID,
  getTimeseriesState,
  getDatepickerState,
  getSearchTermState,
  getSelectedChart,
} from 'store/selectors/selectors';
import { showNotification } from 'store/notification/actions';

import axiosInstance from 'utils/axiosInstance';

import {
  GET_USER_PROJECTS_PENDING,
  GET_USER_PROJECTS_FULFILLED,
} from 'store/projects/types';
import {
  GET_DATA_POINTS,
  GET_DATA_POINTS_PENDING,
  GET_DATA_POINTS_FULFILLED,
  GET_TIMESERIES,
  GET_TIMESERIES_PENDING,
  GET_TIMESERIES_FULFILLED,
  GET_TIMESERIES_REFETCH_FULFILLED,
  REFETCH_TIMESERIES,
  GET_DATA_POINTS_BY_SEARCH,
  GET_DATA_POINTS_BY_SEARCH_PENDING,
  GET_DATA_POINTS_BY_SEARCH_FULFILLD,
  SET_FAVORITE_DATA_POINT,
  REMOVE_FAVORITE_DATA_POINT,
  ChartType,
} from 'store/datapoint/types';
import {
  GET_PAGINATION_CONTEXT_PENDING,
  GET_PAGINATION_CONTEXT_FULFILLED,
  PREV_PAGE,
  NEXT_PAGE,
  SELECT_PAGE,
} from 'store/pagination/types';
import { DATA_POINTS_CONTEXT } from 'constants/context';

/**
 *
 * @param from
 * @param to
 */
const calculateSampleRate = (from: string, to: string): string => {
  const diff = Math.floor((Date.parse(to) - Date.parse(from)) / 86400000);
  if (diff === 0) return '10s';
  if (diff >= 1 && diff < 4) return '10m';
  if (diff >= 4 && diff < 8) return '30m';
  if (diff >= 8 && diff < 62) return '1h';
  if (diff >= 62 && diff < 186) return '2h';
  return '3h';
};

/**
 *
 * @param actionType
 * @param endPoint
 * @return
 */
export function* fetchHelper(
  actionType: string,
  endPoint: string,
  actionValues?: Record<string, string>
) {
  yield put({ type: `${actionType}`, ...(actionValues && actionValues) });
  const token = yield select(getAccessToken);
  return yield call(() => axiosInstance({ token: token }).get(`${endPoint}`));
}

/**
 * Worker
 * @param action
 */
export function* fetchDataPointsInitially(action) {
  try {
    // First check if there is a project id given, if not fetch projects
    const { projectID, page, search } = action;
    const query = qs.stringify(
      { page: page, search: search, per_page: 15, match_case: false },
      { addQueryPrefix: true }
    );

    yield put({ type: GET_PAGINATION_CONTEXT_PENDING });
    let res = yield fetchHelper(GET_USER_PROJECTS_PENDING, 'user/projects');
    const projectsWithIds = reduce(
      res.data,
      (acc, value) => {
        return { ...acc, [value.project.id]: value.project };
      },
      {}
    );

    yield put({ type: GET_USER_PROJECTS_FULFILLED, data: projectsWithIds });

    // -- TODO --
    // Cross check what is the best way to get the right project id!
    const id = projectID ? projectID : Object.keys(projectsWithIds)[0];

    res = yield fetchHelper(
      GET_DATA_POINTS_PENDING,
      `/project/${id}/datapoints/byPage${query}`
    );

    const { dataPointIDs, favorites, meta } = res.data;

    yield all([
      put({
        type: GET_DATA_POINTS_FULFILLED,
        projectID: id,
        dataPointIDs: dataPointIDs ? dataPointIDs : [],
        favorites: favorites ? favorites : [],
      }),
      put({
        type: GET_PAGINATION_CONTEXT_FULFILLED,
        contextIdentifier: DATA_POINTS_CONTEXT,
        currentPage: meta ? meta.current_page : 1,
        totalPages: meta ? meta.total_pages : 1,
      }),
    ]);
  } catch (error) {
    console.log(error);
  }
}

/**
 * Worker
 * @param action
 */
export function* refetchDataPointsForPage(action) {
  try {
    // Get page number and project id
    const { pageNumber } = action;
    const projectID = yield select(getSelectedProjectID);
    const search = yield select(getSearchTermState);

    const query = qs.stringify(
      {
        page: pageNumber,
        per_page: 15,
        search: search,
      },
      { addQueryPrefix: true }
    );

    // Fetch page
    const res = yield fetchHelper(
      GET_DATA_POINTS_PENDING,
      `/project/${projectID}/datapoints/byPage${query}`
    );
    const { dataPointIDs, favorites } = res.data;

    // Dispatch results back to the redux store
    yield all([
      put({
        type: GET_DATA_POINTS_FULFILLED,
        projectID: projectID,
        dataPointIDs: dataPointIDs ? dataPointIDs : [],
        favorites: favorites ? favorites : [],
      }),
    ]);
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param action
 */
export function* fetchDataPointsBySearch(action) {
  try {
    const { search } = action;
    const projectID = yield select(getSelectedProjectID);
    const query = qs.stringify(
      {
        search: search,
        per_page: 15,
        match_case: false,
      },
      { addQueryPrefix: true }
    );

    yield put({ type: GET_PAGINATION_CONTEXT_PENDING });
    const res = yield fetchHelper(
      GET_DATA_POINTS_BY_SEARCH_PENDING,
      `/project/${projectID}/datapoints/byPage${query}`
    );

    const { dataPointIDs, favorites, meta } = res.data;

    // Dispatch results back to the redux store
    yield all([
      put({
        type: GET_DATA_POINTS_BY_SEARCH_FULFILLD,
        projectID: projectID,
        dataPointIDs: dataPointIDs ? dataPointIDs : [],
        favorites: favorites ? favorites : [],
      }),
      put({
        type: GET_PAGINATION_CONTEXT_FULFILLED,
        contextIdentifier: DATA_POINTS_CONTEXT,
        currentPage: meta ? meta.current_page : 1,
        totalPages: meta ? meta.total_pages : 1,
      }),
    ]);
  } catch (error) {
    console.log(error);
  }
}

/**
 * Worker
 * @param action
 */
export function* fetchTimeseries(action) {
  try {
    const { dataPointID } = action;
    const timeseries = yield select(getTimeseriesState);
    const datepicker = yield select(getDatepickerState);
    const projectID = yield select(getSelectedProjectID);
    const chartType = yield select(getSelectedChart);

    if (timeseries.length < 7) {
      // If the series is not in state, fetch the series
      let samplerate = '1h';
      if (chartType !== ChartType.heat) {
        samplerate = calculateSampleRate(datepicker.from, datepicker.to);
      }
      const query = qs.stringify(
        {
          project_id: projectID,
          dataPointID: dataPointID,
          start: datepicker.from,
          end: datepicker.to,
          samplerate: samplerate,
          short: true,
        },
        { addQueryPrefix: true }
      );

      const res = yield fetchHelper(
        GET_TIMESERIES_PENDING,
        `/datapoint/timeseries${query}`,
        { dataPointID: dataPointID }
      );

      const { data } = res;
      const seriesData = data.map(e => [new Date(e[0]).getTime(), e[1]]);
      yield put({
        type: GET_TIMESERIES_FULFILLED,
        timeseries: {
          id: dataPointID,
          name: dataPointID,
          data: seriesData,
        },
      });
    } else {
      yield put(
        showNotification({
          identifier: 'timeseries-limit-error-notification',
          type: 'error',
          text: 'Maximum of selected datapoints are reached.',
          autoClose: 4000,
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Worker
 */
export function* refetchTimeseries() {
  try {
    const timeseries = yield select(getTimeseriesState);

    if (timeseries && timeseries.length > 0) {
      const datepicker = yield select(getDatepickerState);
      const projectID = yield select(getSelectedProjectID);
      const chartType = yield select(getSelectedChart);

      let samplerate = '1h';
      if (chartType !== ChartType.heat) {
        samplerate = calculateSampleRate(datepicker.from, datepicker.to);
      }
      for (let i = 0; i < timeseries.length; i++) {
        const dataPointID = timeseries[i].id;

        const query = qs.stringify(
          {
            project_id: projectID,
            dataPointID: dataPointID,
            start: datepicker.from,
            end: datepicker.to,
            samplerate: samplerate,
            short: true,
          },
          { addQueryPrefix: true }
        );

        const res = yield fetchHelper(
          GET_TIMESERIES_PENDING,
          `/datapoint/timeseries${query}`
        );

        const { data } = res;
        const seriesData = data.map(e => [new Date(e[0]).getTime(), e[1]]);
        yield put({
          type: GET_TIMESERIES_REFETCH_FULFILLED,
          timeseries: { id: dataPointID, name: dataPointID, data: seriesData },
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Worker
 * @param action
 */
export function* setFavoriteDataPoint(action) {
  try {
    const projectID = yield select(getSelectedProjectID);
    const token = yield select(getAccessToken);

    const query = qs.stringify(
      {
        project_id: projectID,
        dataPointID: action.dataPointID,
      },
      { addQueryPrefix: true }
    );

    yield call(() =>
      axiosInstance({ token: token }).post(`/datapoint/favorite${query}`)
    );
  } catch (error) {
    console.log(error);
  }
}

/**
 * Worker
 * @param action
 */
export function* removeFavoriteDataPoint(action) {
  try {
    const projectID = yield select(getSelectedProjectID);
    const token = yield select(getAccessToken);

    const query = qs.stringify(
      {
        project_id: projectID,
        dataPointID: action.dataPointID,
      },
      { addQueryPrefix: true }
    );

    yield call(() =>
      axiosInstance({ token: token }).delete(`/datapoint/favorite${query}`)
    );
  } catch (error) {
    console.log(error);
  }
}

/**
 * Export the datapoints saga
 */
export default function* dataPointsSaga() {
  yield all([
    // Initial and search
    takeLatest(GET_DATA_POINTS, fetchDataPointsInitially),
    takeLatest(GET_DATA_POINTS_BY_SEARCH, fetchDataPointsBySearch),

    // Pagination
    takeLatest(SELECT_PAGE, refetchDataPointsForPage),
    takeLatest(NEXT_PAGE, refetchDataPointsForPage),
    takeLatest(PREV_PAGE, refetchDataPointsForPage),

    // Timeseries
    takeEvery(GET_TIMESERIES, fetchTimeseries),
    takeEvery(REFETCH_TIMESERIES, refetchTimeseries),

    // Favorite data points
    takeEvery(SET_FAVORITE_DATA_POINT, setFavoriteDataPoint),
    takeEvery(REMOVE_FAVORITE_DATA_POINT, removeFavoriteDataPoint),
  ]);
}
