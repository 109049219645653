import { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import SelectElement from 'react-select';

export const styles = {
  control: (styles, { theme }) => ({
    ...styles,
    background: theme.purple4,
    border: 0,
  }),
  placeholder: (styles, { theme }) => ({
    ...styles,
    color: theme.textColor,
  }),
  menu: (styles, { theme }) => ({
    ...styles,
    background: theme.purple4,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected, theme }) => ({
    ...styles,
    border: 0,
    borderRadius: 0,
    background: theme.purple4,
    color: '#000',
    textAlign: 'center',
    ':hover': {
      background: theme.purple6,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (styles, { theme }) => ({
    ...styles,
    color: theme.textColor,
  }),
  multiValue: (styles, { theme }) => ({
    ...styles,
    background: theme.purple6,
  }),
  multiValueLabel: (styles, { theme }) => ({
    ...styles,
    color: theme.textColor,
    padding: '5px 6px 5px 10px',
  }),
  multiValueRemove: (styles, { theme }) => ({
    ...styles,
    background: theme.primary,
    ':hover': {
      background: theme.red,
    },
  }),
};

const selectElement: any = SelectElement
export const StyledSelect: FC = styled.div``;
export const SelectedElementWithTheme: FC = withTheme(selectElement);

export const StyledDropdownIndicator = styled.div`
  padding: 10px 10px 10px 0px;
`;
