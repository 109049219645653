import { getISODateString } from 'utils/date';
import {
  SET_DATETIME_FROM,
  SET_DATETIME_TO,
  DatepickerState,
  DatepickerActionTypes,
} from 'store/datepicker/types';

const initialState: DatepickerState = {
  from: getISODateString(-7),
  to: getISODateString(),
  timestampFrom: (
    Date.now() -
    604800000 -
    ((Date.now() - 604800000) % 86400000)
  ).toString(),
  timestampTo: (Date.now() - (Date.now() % 86400000)).toString(),
};

export default function(state = initialState, action: DatepickerActionTypes) {
  switch (action.type) {
    case SET_DATETIME_FROM:
      return {
        ...state,
        from: action.date.from,
        timestampFrom: action.date.timestampFrom,
      };
    case SET_DATETIME_TO:
      return {
        ...state,
        to: action.date.to,
        timestampTo: action.date.timestampTo,
      };
    default:
      return state;
  }
}
