import React, { FC } from 'react';
import { Variant } from 'components/ui/Badge/styles/BadgeStyles';
import {
  StyledBadge,
  StyledCount,
} from 'components/ui/Badge/styles/BadgeStyles';

interface Props {
  count?: number;
  position?: Position;
  variant?: Variant;
  shadow?: boolean;
}
/**
 * Styled badge component
 *
 * @param {Object} props - Children and count
 * @returns {ReactNodeArray}  Styled badge with wrapped children
 */
export const Badge: FC<Props> = props => {
  const { count = 0, children } = props;
  return (
    <StyledBadge {...props}>
      {count > 0 && <StyledCount {...props}>{count}</StyledCount>}
      {children}
    </StyledBadge>
  );
};
