import {
  GET_ADCOMPANIES_FULFILLED,
  GET_ADCOMPANIES_PENDING,
  DELETE_ADCOMPANY,
  DELETE_ADCOMPANY_FULFILLED,
  DELETE_ADCOMPANY_PENDING,
  DELETE_ADCOMPANY_REJECTED,
  CREATE_ADCOMPANY_PENDING,
  CREATE_ADCOMPANY_FULFILLED,
  CREATE_ADCOMPANY_REJECTED,
  CREATE_ADCOMPANY,
  EDIT_ADCOMPANY,
  EDIT_ADCOMPANY_PENDING,
  EDIT_ADCOMPANY_REJECTED,
  SAVE_ADCOMPANY,
  SAVE_ADCOMPANY_PENDING,
  SAVE_ADCOMPANY_REJECTED,
  SAVE_ADCOMPANY_FULFILLED,
  QUICK_CREATE_COMPANY,
  EDIT_ADCOMPANY_FULFILLED,
} from 'store/companies/types';

import {
  get_companies,
  delete_company,
  create_company,
  get_company,
  put_company,
} from 'utils/adminAxiosInstance';

import {
  put,
  select,
  fork,
  take,
  takeLatest,
  call,
} from '@redux-saga/core/effects';
import { TOGGLE_MODAL } from 'store/modal/types';
import { showNotification } from 'store/notification/actions';
import i18next from 'i18next';
import { reset, initialize, destroy } from 'redux-form';

import { get } from 'lodash';
import { StateType } from 'store/types';
import {finishStep, updateFlow} from 'store/flow/actions';
import {
  FINISH_CURRENT_FLOW,
  FINISH_CURRENT_FLOW_STEP,
} from 'store/flow/types';

export function* fetchADCompanies() {
  const loading = yield select(state => state.auth.loading);
  if (loading) throw Error('no user loaded');

  yield put({ type: GET_ADCOMPANIES_PENDING });
  const req = yield get_companies();

  try {
    const companies = yield req;
    yield put({ type: GET_ADCOMPANIES_FULFILLED, data: companies.data });
  } catch (err) {
    yield put({ type: GET_ADCOMPANIES_PENDING });
  }
}

export function* deleteADCompany(action) {
  const companyID = action.data;

  yield put({ type: TOGGLE_MODAL, selected: 'confirm-delete-company', style: 'box' });
  yield take('CONFIRM_DELETE');
  yield put({ type: DELETE_ADCOMPANY_PENDING });

  const req = yield delete_company(companyID);

  try {
    const res = yield req;
    yield put({
      type: DELETE_ADCOMPANY_FULFILLED,
      data: companyID,
    });
    yield put(
      showNotification({
        identifier: 'company-deleted-notification',
        type: 'success',
        text: i18next.t('companies:notification.company_deleted_success'),
        autoClose: 2000,
      })
    );
    yield put(reset('confirm-delete'));
    yield put({ type: TOGGLE_MODAL, selected: 'confirm' });
    yield fork(fetchADCompanies);
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'company-deleted-notification',
        type: 'error',
        text: err.response.data.error, //i18next.t('companies:company_deleted_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: DELETE_ADCOMPANY_REJECTED });
  }
}

export function* addADCompany(action) {
  const { name, description, address, phone, avatar_url, slug } = action.data;
  const history = action.his;

  yield put({ type: CREATE_ADCOMPANY_PENDING });
  const req = create_company(name, description, slug, address, avatar_url, phone);

  try {
    const res = yield req;
    yield put({
      type: CREATE_ADCOMPANY_FULFILLED,
      data: res.data,
    });
    const currentFlow = yield select((state: StateType) => state.currentFlow);
    if (currentFlow.isActive) {
      yield put({ type: 'TOGGLE_MODAL', selected: 'company' });
      yield put(finishStep(currentFlow.flow.currentStep.id, history));
    } else {
      yield put(destroy('company-create'));
      yield put({ type: 'TOGGLE_MODAL', selected: 'company' });
    }
    yield fork(fetchADCompanies);
    yield put(
      showNotification({
        identifier: 'company-created-notification',
        type: 'success',
        text: i18next.t('companies:notification.company_created_success'),
        autoClose: 2000,
      })
    );
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'company-created-notification',
        type: 'error',
        text: err.response.data.error, //i18next.t('companies:notification.company_created_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: CREATE_ADCOMPANY_REJECTED });
  }
}

export function* getADCompany(action) {
  const company_id = action.data;

  yield put({ type: EDIT_ADCOMPANY_PENDING });
  const req = yield get_company(company_id);

  try {
    const res = yield req;
    yield put(initialize('company-create', res.data));
    yield put({ type: 'TOGGLE_MODAL', selected: 'company', edit: true });
    yield put({type: EDIT_ADCOMPANY_FULFILLED, data: res.data});
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'company-edit-notification',
        type: 'error',
        text: err.response.data.error, //i18next.t('companies:notification.company_fetch_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: EDIT_ADCOMPANY_REJECTED });
  }
}

export function* saveADCompany(action) {
  const values = yield select(state =>
    get(state, 'form.company-create.values', {})
  );

  const { id, name, description, phone, address, avatar_url } = values;

  // Don't need this, since we just update everything
  /*const fields = yield select(state =>
    get(state, 'form.company-create.fields', [])
  );*/

  yield put({ type: SAVE_ADCOMPANY_PENDING });

  const req = yield put_company(
    id,
    name,
    description,
    address,
    avatar_url,
    phone
  );

  try {
    const res = yield fetch(req);
    yield put(
      showNotification({
        identifier: 'company-saved-notification',
        type: 'success',
        text: i18next.t('companies:notification.company_updated_success'),
        autoClose: 2000,
      })
    );
    yield put({ type: SAVE_ADCOMPANY_FULFILLED });
    yield fork(fetchADCompanies);
    yield put(reset('company-create'));
    yield put({ type: 'TOGGLE_MODAL', selected: 'company' });
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'company-saved-notification',
        type: 'error',
        text: err.response.data.error, //i18next.t('companies:notification.company_updated_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: SAVE_ADCOMPANY_REJECTED });
  }
}

export function* clearProjectFormOnBlur(action) {
  if (action.selected === 'company' && typeof action.edit === 'undefined') {
    yield put(destroy('company-create'));
  }
}

export function* redirectAndCreate(action) {
  const { history } = action.data;

  /**
   * Redirect to companies index-view
   */
  history.push('companies');

  /**
   * Clear the company-create form
   */
  yield put(destroy('company-create'));

  /**
   * Open the corresponding creation-modal
   */
  yield put({ type: TOGGLE_MODAL, selected: 'company', edit: false})

}

export default function* adcompaniesSaga() {
  yield fork(fetchADCompanies);
  yield takeLatest(DELETE_ADCOMPANY, deleteADCompany);
  yield takeLatest(CREATE_ADCOMPANY, addADCompany);
  yield takeLatest(EDIT_ADCOMPANY, getADCompany);
  yield takeLatest(SAVE_ADCOMPANY, saveADCompany);
  yield takeLatest(TOGGLE_MODAL, clearProjectFormOnBlur);
  yield takeLatest(QUICK_CREATE_COMPANY, redirectAndCreate);
}
