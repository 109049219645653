import {
  ADUsersActionTypes,
  QUICK_CREATE_USER,
} from 'store/adusers/types';
import * as H from 'history';

export const redirectToUserCreation = (his: H.History): ADUsersActionTypes => ({
  type: QUICK_CREATE_USER,
  data: {
    history: his,
  }
});
