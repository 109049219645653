import React, { FC } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledAlert } from 'components/ui/Alert/styles/AlertStyles';

const Alert: FC<{}> = props => (
  <StyledAlert>
    <ToastContainer
      closeButton={false}
      hideProgressBar={true}
      newestOnTop={true}
    />
  </StyledAlert>
);

export default Alert;
