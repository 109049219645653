import React, { FC, useCallback, useState } from 'react';
import {
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rgba } from 'polished';

import { ModalBody, ModalFooter, ModalHeader } from 'components/ui/Modal/Modal';

import Field, {
  FieldRow,
  StyledSingleFormSelect,
} from 'components/ui/Form/Form';
import { Button, Variant, Size } from 'components/ui/Button/Button';
import { useIsEqualForm, useLoading } from 'utils/hooks';
import { CREATE_ADPROJECT } from 'store/adprojects/types';
import { useSelector, useDispatch } from 'react-redux';
import { StateType } from 'store/types';
import { debounce, get } from 'lodash';
import axios from 'axios';
import { INITIALIZERS } from 'store/initializers/types';
import { initializeRequest } from 'utils/initializers';

// -- TODO --
// Remove the key index signature type. It´s just there to prevent an failure with the
// validate utils function
interface CreateADProjectFormData {
  name: string;
  description: string;
  company_id: number;
  hash_id_length: number;
  slug: string;
  setpoints_enabled: boolean;
  schedules_enabled: boolean;
  load_balancing_group: string;
  setpoints_min_priority: number;
  avatar_url: string;
}

const CreateADProjectForm: FC<InjectedFormProps<CreateADProjectFormData>> = ({
  submitting,
  pristine,
  reset,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const { companies } = useSelector((state: StateType) => state.adcompanies);
  const { project } = useSelector((state: StateType) => state.adproject);
  const companiesList =
    companies && companies.length > 0
      ? companies.map(company => {
          return {
            label: company.name,
            value: company.id,
          };
        })
      : [];

  const modal = useSelector((state: StateType) => state.modal);
  const title = !modal.edit
    ? t('projects:modal.create_new_project')
    : t('projects:modal.update_project');

  pristine = useIsEqualForm('project-create');

  const [loading, createProjectLoading] = useLoading(CREATE_ADPROJECT);
  const selector = formValueSelector('project-create');
  const state = useSelector(state => state);
  const token = useSelector((state: StateType) => state.auth.user.access_token);
  const dispatch = useDispatch();
  const [companyId, setCompanyId] = useState(null)
  const [hwarning, setHwarning] = useState('');
  const [hsuccess, setHSuccess] = useState('');
  const [hIsLoading, setHIsLoading] = useState(false);
  const [hError, setHError] = useState('');
  const [classificationInit, setClassificationInit] = useState(false);
  const [hashesInit, setHashesInit] = useState(false);
  const [syncInit, setSyncInit] = useState(false);

  const validateSlug = async (project_slug: string, companyIdValue: number) => {
    setHIsLoading(true);
    const project_slug_name = project_slug;
    const axiosInstance = axios.create({
      baseURL: globalThis.config.API_ADMIN,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    try {
      const result = await axiosInstance.get('project/validate_handle', {
        params: {
          project_slug: project_slug_name,
          company_id: companyIdValue
        },
      });
      if (result.status === 226) {
        setHwarning(t('common:validation.slug_exists_with_influx'));
        setHError('');
        setHSuccess('');
        setHIsLoading(false);
      } else {
        setHwarning('');
        setHError('');
        setHSuccess(t('common:validation.slug_valid'));
        setHIsLoading(false);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 409
      ) {
        setHSuccess('');
        setHError(t('common:validation.slug_used'));
        setHwarning('');
        setHIsLoading(false);
      } else if (
        error.response &&
        error.response.status &&
        error.response.status === 400
      ) {
        setHSuccess('');
        setHError(error.response.data.error);
        setHwarning('');
        setHIsLoading(false);
      } else {
        setHSuccess('');
        setHError('');
        setHwarning(t('common:validation.slug_invalid'));
        setHIsLoading(false);
      }
    }
  };

  const slugValidation = useCallback(
    debounce((slug: string, companyIdValue: number) => validateSlug(slug, companyIdValue), 300),
    []
  );

  const onChangeProjectSlugName = e => {
    if (!modal.edit) {
      setHIsLoading(true);
      slugValidation(e.target.value, companyId);
    }
  };

  const onCompanyChange = e => {
    setCompanyId(e.value)
  }

  const checkError = (handler, ev) => {
    const handle_data = selector(state, 'slug')
    if (!handle_data) {
      setHwarning('');
      setHError(t('common:validation.slug_provide'));
    }
    handler(ev);
    ev.preventDefault();
    ev.stopPropagation();
  };

  return (
    <form
      onSubmit={e => {
        setHwarning('');
        setHError('');
        checkError(handleSubmit, e);
      }}
    >
      <ModalHeader>
        <h1>
          <FontAwesomeIcon icon={['fal', 'chart-bar']} fixedWidth />
          {title}
        </h1>
      </ModalHeader>
      <ModalBody>
        <FieldRow>
          <StyledSingleFormSelect
            label={t('projects:fields.company_id')}
            name="company_id"
            options={companiesList}
            onChange={onCompanyChange}
          />
        </FieldRow>
        <FieldRow>
          <Field name="name" type="text" label={t('projects:fields.name')} />
          <Field
            name="description"
            type="text"
            label={t('projects:fields.description')}
          />
        </FieldRow>
        <FieldRow>
          <Field
            name="slug"
            type="text"
            disabled={modal.edit}
            readonly={modal.edit}
            label={t('projects:fields.slug')}
            meta={{
              error: hError,
              warning: hwarning,
              success: hsuccess,
              valid: !hError,
            }}
            onChange={onChangeProjectSlugName}
          />
          <Field
            name="hash_id_length"
            type="number"
            disabled={modal.edit}
            readonly={modal.edit}
            style={{ color: modal.edit ? 'grey' : 'white' }}
            label={t('projects:fields.hash_id_length')}
          />
        </FieldRow>
        <FieldRow>
          <Field
            name="load_balancing_group"
            type="text"
            disabled={modal.edit}
            readonly={modal.edit}
            style={{ color: modal.edit ? 'grey' : 'white' }}
            label={t('projects:fields.load_balancing_group')}
          />
          <Field
            name="setpoints_min_priority"
            type="number"
            label={t('projects:fields.setpoints_min_priority')}
          />
        </FieldRow>
        <FieldRow>
          <StyledCheckboxTable />
        </FieldRow>
        <FieldRow>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant={Variant.Success}
              scaleSize={Size.XL}
              onClick={() =>
                initializeRequest(
                  setHashesInit,
                  {
                    initializer: INITIALIZERS.INITIALIZE_DATAPOINT_HASH_IDS,
                    project_id: get(project, 'id', null),
                  },
                  dispatch
                )
              }
              loading={hashesInit}
              style={{
                width: '45%',
              }}
            >
              {t('initializers:texts.hashes')}
            </Button>
            <Button
              variant={Variant.Success}
              scaleSize={Size.XL}
              onClick={() =>
                initializeRequest(
                  setSyncInit,
                  {
                    initializer:
                      INITIALIZERS.SYNCHRONIZE_DATAPOINTS_WITH_INFLUX,
                    project_id: get(project, 'id', null),
                  },
                  dispatch
                )
              }
              loading={syncInit}
              style={{
                width: '45%',
              }}
            >
              {t('initializers:texts.sync_datapoints')}
            </Button>
          </div>
        </FieldRow>
        <FieldRow>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant={Variant.Success}
              scaleSize={Size.XL}
              onClick={() =>
                initializeRequest(
                  setClassificationInit,
                  {
                    initializer: INITIALIZERS.INITIALIZE_CLASSIFICATION,
                    project_id: get(project, 'id', null),
                  },
                  dispatch
                )
              }
              loading={classificationInit}
              style={{
                width: '45%',
              }}
            >
              {t('initializers:texts.classification')}
            </Button>
          </div>
        </FieldRow>
      </ModalBody>
      {!pristine && (
        <ModalFooter>
          <Button
            type="submit"
            variant={Variant.Success}
            disabled={!(!pristine || submitting) || !!hError}
            loading={createProjectLoading}
          >
            {t('common:modal.save_project')}
          </Button>
          <Button
            type="button"
            variant={Variant.Default}
            disabled={pristine || submitting}
            onClick={() => {
              setHwarning('');
              setHError('');
              setHSuccess('');
              reset();
            }}
          >
            {t('common:button.cancel')}
          </Button>
        </ModalFooter>
      )}
    </form>
  );
};

const CheckboxTable = props => {
  return (
    <table className={props.className}>
      <thead>
        <tr>
          <th>Schedules Enabled</th>
          <th>Setpoints Enabled</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Field name="schedules_enabled" type="checkbox" />
          </td>
          <td>
            <Field name="setpoints_enabled" type="checkbox" />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const StyledCheckboxTable = styled(CheckboxTable)`
  width: 100%;
  border: 0;
  background: ${({ theme }) => theme.purple2};

  tbody > tr > th {
    text-align: right;
  }

  thead > tr > th {
    width: 25%;
  }

  thead > tr > th:first-child {
    max-width: 100px;
  }

  tbody > tr > td > div {
    align-items: center;
  }

  thead {
    background: rgba(0, 0, 0, 0.1);
    border-top-left-radius: ${({ theme }) => theme.table.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.table.borderRadius};
    box-shadow: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    th {
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1.5px;
      padding: 15px 20px;
      color: ${({ theme }) => rgba(theme.purple6, 0.6)};
    }
  }

  tbody {
    border-bottom-left-radius: ${({ theme }) => theme.table.borderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.table.borderRadius};

    td {
      padding: 15px 20px;
      font-size: 14px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.01);
    }
  }
`;

export default reduxForm<CreateADProjectFormData>({
  form: 'project-create',
  enableReinitialize: false,
})(CreateADProjectForm);
