import React, { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { userManager } from 'utils/userManager';
import Background from 'components/ui/Background/Background';
import { Button, Variant } from 'components/ui/Button/Button';
import { StyledImage } from 'container/Login/styles/LoginStyles';

/**
 * The Login component only renders the aedifion logo and a
 * login button. Because aedifion uses openid with a hostet login page
 * we dont need to send any login informations here.
 *
 * @returns {ReactNodeArray} Components for the login page
 */
const Login: FC = () => {
  // Use the i18next translation hook
  const { t } = useTranslation();

  /**
   * Redirects to the authentication provider. This should be the only
   * place to use the user manager from a react component!
   *
   * @param {SyntheticEvent<HTMLButtonElement>} event
   * @returns {void}
   */
  const redirectToAuthProvider = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    userManager.signinRedirect();
  };

  return (
    <Background centerContent={true}>
      <StyledImage src="/assets/images/aedifion-logo.png" />
      <Button
        variant={Variant.Confirmation}
        onClick={redirectToAuthProvider}
        width={`150px`}
      >
        {t('common:button.login')}
      </Button>
    </Background>
  );
};

export default Login;
