import { ADCompanyActionTypes, EDIT_ADCOMPANY_FULFILLED, ADCompanyState } from "./types";

const initialState: ADCompanyState = {
    loading: false,
};

  export default (state = initialState, action: ADCompanyActionTypes) => {
    switch (action.type) {
      case EDIT_ADCOMPANY_FULFILLED:
        return {
          ...state,
          loading: true,
          company: action.data,
        };
      default:
        return state;
    }
  };