import { all, call, fork, select, put } from 'redux-saga/effects';
import { reduce, trimEnd } from 'lodash';
import { getAccessToken } from 'store/selectors/selectors';

import axiosInstance from 'utils/axiosInstance';

function buildEnum(path) {
  // remove api version
  path = path.replace(/^\/v[0-9]\//gm, '', path);
  // remove params
  path = path.replace(/{[A-Za-z_-]+}\/?/gm, '', path);
  // strip last underscore
  path = trimEnd(path, '/');
  // replace slashes with underscores
  path = path.replace(/\//gm, '_', path);
  return path.toUpperCase();
}

function* buildEndpointEnumerations() {
  const token = yield select(getAccessToken);

  yield put({ type: 'GET_ENDPOINT_ENUMERATIONS_PENDING' });
  try {
    const { data } = yield call(() =>
      axiosInstance({ token: token }).get('/meta/endpoints')
    );

    const endpoints = reduce(
      data,
      (acc, endpoint: any) => {
        const key = `${endpoint.request_method}_${buildEnum(endpoint.path)}`;
        acc[key] = endpoint;
        return acc;
      },
      {}
    );

    yield put({
      type: 'GET_ENDPOINT_ENUMERATIONS_FULFILLED',
      endpoints: endpoints,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: 'GET_ENDPOINT_ENUMERATIONS_REJECTED' });
  }
}

export default function* endpointSaga() {
  yield all([fork(buildEndpointEnumerations)]);
}
