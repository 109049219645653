import {
  CREATE_PROJECT_FULFILLED,
  DELETE_PROJECT_FULFILLED,
  SAVE_PROJECT_FULFILLED,
  Project,
  ProjectWithCompanyId,
} from 'src/store/project/types';

export const GET_PROJECTS_COMPANY = 'GET_PROJECTS_COMPANY';
export const GET_PROJECTS_COMPANY_PENDING = 'GET_PROJECTS_COMPANY_PENDING';
export const GET_PROJECTS_COMPANY_FULFILLED = 'GET_PROJECTS_COMPANY_FULFILLED';
export const GET_PROJECTS_COMPANY_REJECTED = 'GET_PROJECTS_COMPANY_REJECTED';

export const GET_USER_PROJECTS = 'GET_USER_PROJECTS';
export const GET_USER_PROJECTS_PENDING = 'GET_USER_PROJECTS_PENDING';
export const GET_USER_PROJECTS_FULFILLED = 'GET_USER_PROJECTS_FULFILLED';
export const GET_USER_PROJECTS_REJECTED = 'GET_USER_PROJECTS_REJECTED';

export interface ProjectsState {
  readonly company_loading: boolean;
  readonly user_loading: boolean;
  readonly user: { [id: number]: ProjectWithCompanyId };
  readonly company: { [id: number]: Project };
}

interface GetProjectsCompanyAction {
  type: typeof GET_PROJECTS_COMPANY;
}

interface GetUserProjectAction {
  type: typeof GET_USER_PROJECTS;
}

interface GetProjectsCompanyPendingAction {
  type: typeof GET_PROJECTS_COMPANY_PENDING;
}

interface GetUserProjectPendingAction {
  type: typeof GET_USER_PROJECTS_PENDING;
}

interface GetProjectsCompanyFulfilledAction {
  type: typeof GET_PROJECTS_COMPANY_FULFILLED;
  data: { [id: number]: ProjectWithCompanyId };
  projectID: number;
}

interface GetUserProjectFulfilledAction {
  type: typeof GET_USER_PROJECTS_FULFILLED;
  data: { [id: number]: ProjectWithCompanyId };
  projectID: number;
}

interface CreateProjectFulfilled {
  type: typeof CREATE_PROJECT_FULFILLED;
  data: any; // TODO: shape
}

interface DeleteProjectFulfilled {
  type: typeof DELETE_PROJECT_FULFILLED;
  data: any; // TODO: shape
}

interface SaveProjectFulfilled {
  type: typeof SAVE_PROJECT_FULFILLED;
  data: any; // TODO: shape
}

export type ProjectsActionTypes =
  | GetProjectsCompanyAction
  | GetUserProjectAction
  | GetProjectsCompanyPendingAction
  | GetProjectsCompanyFulfilledAction
  | GetUserProjectPendingAction
  | GetUserProjectFulfilledAction
  | CreateProjectFulfilled
  | DeleteProjectFulfilled
  | SaveProjectFulfilled;
