import {
  call,
  put,
  select,
  all,
  takeLatest,
  take,
  cancelled,
} from 'redux-saga/effects';
import { initialize, destroy, reset } from 'redux-form';
import {
  get,
  pick,
  reduce,
  differenceBy,
} from 'lodash';
import i18next from 'i18next';

import {
  EDIT_COMPANY_ROLE,
  DELETE_COMPANY_ROLE,
  EDIT_COMPANY_ROLE_PENDING,
  EDIT_COMPANY_ROLE_REJECTED,
  SAVE_COMPANY_ROLE,
  SAVE_COMPANY_ROLE_PENDING,
  SAVE_COMPANY_ROLE_FULFILLED,
  SAVE_COMPANY_ROLE_REJECTED,
  DELETE_COMPANY_ROLE_PENDING,
  DELETE_COMPANY_ROLE_FULFILLED,
  DELETE_COMPANY_ROLE_REJECTED,
  EDIT_PROJECT_ROLES,
  SAVE_PROJECT_ROLES,
} from 'store/roles/types';

import { request } from 'utils/axiosInstance';
import { showNotification } from 'store/notification/actions';
import { TOGGLE_MODAL } from 'store/modal/types';
import { assignUserToProject, removeUserFromProject } from 'store/project/saga';

export function* getCompanyRole(action) {
  const { roleID } = action;

  const endpoints = yield select(state => get(state, 'endpoints', []));

  yield put({ type: EDIT_COMPANY_ROLE_PENDING });
  const [getCompanyRoles] = yield request('GET_COMPANY_ROLES');
  try {
    const res = yield getCompanyRoles;

    const INVERTED_ENDPOINTS = reduce(
      endpoints,
      (acc, value, key) => {
        acc[value.id] = key;
        return acc;
      },
      {}
    );

    const role = res.data.find(role => role.id === roleID);
    const mappedRole = {
      ...role,
      authed_endpoints: reduce(
        role.authed_endpoints,
        (acc, id) => {
          acc[INVERTED_ENDPOINTS[id]] = true;
          return acc;
        },
        {}
      ),
    };
    yield put(initialize('role-create', mappedRole));
    yield put({ type: 'TOGGLE_MODAL', selected: 'roles', edit: true });
  } catch (error) {
    // how to get response? res is undefined, if used with let?
    yield put(
      showNotification({
        identifier: 'role-edit-notification',
        type: 'error',
        text: i18next.t('common:notification.company_role_fetch_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: EDIT_COMPANY_ROLE_REJECTED });
  }
}

export function* getProjectRoles(action) {
  const { userID } = action;
  yield put({ type: 'EDIT_PROJECT_ROLES_PENDING' });

  const projects = yield select(store => store.projects.company);
  const projectRoles = yield select(store => store.roles.project);
  // get assigned projects from user.
  const projectRoleIDs = yield select(
    store => store.users.users.find(user => user.id === userID).project_roles
  );

  const projectsWithLabels = projectRoleIDs.map(el => ({
    value: el,
    label: `${projects[projectRoles[el].project_id].name} (${projectRoles[el].name})`,
  }));

  try {
    yield put(
      initialize('project-roles-edit', {
        user: userID,
        default_projects: projectsWithLabels,
      })
    );
    yield put({ type: 'TOGGLE_MODAL', selected: 'project-roles', edit: true });
  } catch (error) {
    // how to get response? res is undefined, if used with let?
    yield put(
      showNotification({
        identifier: 'project-roles-edit-notification',
        type: 'error',
        text: i18next.t('common:notification.project_role_fetch_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: 'EDIT_PROJECT_ROLES_REJECTED' });
  }
}

export function* saveProjectRoles(action) {
  const projects = yield select(store => store.projects.company);
  const projectRoles = yield select(store => store.roles.project);
  const values = yield select(state =>
    get(state, 'form.project-roles-edit.values', {})
  );

  const addedProjects = differenceBy(
    values.projects,
    values.default_projects,
    (el: any) => el.value
  );

  const deletedProjects = differenceBy(
    values.default_projects,
    values.projects,
    (el: any) => el.value
  );

  /*
    yield all(
      roles.map(role => call(assignCompanyRole, role, res.data.resource.id))
    );
   */

  if (addedProjects.length > 0) {
    yield all(
      addedProjects.map((projectRole: any) => {
        return call(
          assignUserToProject,
          projects[projectRoles[projectRole.value].project_id].id,
          projectRole.value,
          values.user
        );
      })
    );
  }

  if (deletedProjects.length > 0) {
    yield all(
      deletedProjects.map((projectRole: any) => {
        return call(
          removeUserFromProject,
          projects[projectRoles[projectRole.value].project_id].id,
          projectRole.value,
          values.user
        );
      })
    );
  }

  yield put(reset('project-roles-edit'));
  yield put({ type: 'TOGGLE_MODAL', selected: 'project-roles' });
}

export function* saveCompanyRole(action) {
  // const { values } = action; // why are these old values?

  const endpoints = yield select(state => get(state, 'endpoints', []));

  const values = yield select(state =>
    get(state, 'form.role-create.values', {})
  );

  const fields = yield select(state =>
    get(state, 'form.role-create.fields', [])
  );

  values.authed_endpoints = reduce(
    values.authed_endpoints,
    (acc, value, key) => {
      if (value === true) {
        acc.push(endpoints[key].id);
      }

      return acc;
    },
    []
  );

  // clear fields ...
  const { id: roleID } = values;
  const mappedValues = pick(values, Object.keys(fields));

  yield put({ type: SAVE_COMPANY_ROLE_PENDING });
  const [putCompanyRole, cancel] = yield request('PUT_COMPANY_ROLE', {
    role_id: roleID,
    ...mappedValues,
  });

  try {
    const res = yield putCompanyRole;
    yield put({ type: SAVE_COMPANY_ROLE_FULFILLED, data: res.data.resource });
    yield put(
      showNotification({
        identifier: 'role-saved-notification',
        type: 'success',
        text: i18next.t('common:notification.company_role_saved_success'),
        autoClose: 2000,
      })
    );
    yield put(reset('role-create'));
    yield put({ type: 'TOGGLE_MODAL', selected: 'roles' });
  } catch (error) {
    yield put(
      showNotification({
        identifier: 'project-saved-notification',
        type: 'error',
        text: i18next.t('common:notification.company_role_save_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: SAVE_COMPANY_ROLE_REJECTED });
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: SAVE_COMPANY_ROLE_REJECTED });
    }
  }
}

export function* clearRolesFormOnBlur(action) {
  if (action.selected === 'project' && typeof action.edit === 'undefined') {
    yield put(destroy('project-create'));
  }
}

export function* deleteCompanyRole(action) {
  const { roleID } = action;

  // const projectRoles = yield select(state => state.roles.project);

  // open confirm modal and wait for confirm dispatch
  yield put({ type: TOGGLE_MODAL, selected: 'confirm', style: 'box' });
  yield take('CONFIRM_DELETE');
  yield put({ type: DELETE_COMPANY_ROLE_PENDING });
  const [deleteCompanyRole, cancel] = yield request('DELETE_COMPANY_ROLE', {
    role_id: roleID,
  });
  try {
    const res = yield deleteCompanyRole;
    yield put({
      type: DELETE_COMPANY_ROLE_FULFILLED,
      data: res.data.resource,
      // meta: projectRoles,
    });
    yield put(
      showNotification({
        identifier: 'role-deleted-notification',
        type: 'success',
        text: i18next.t('common:notification.company_role_deleted_success'),
        autoClose: 2000,
      })
    );
    yield put(reset('confirm-delete'));
    yield put({ type: TOGGLE_MODAL, selected: 'confirm' });
  } catch (error) {
    // how to get response? res is undefined, if used with let?
    yield put(
      showNotification({
        identifier: 'role-deleted-notification',
        type: 'error',
        text: i18next.t('common:notification.company_role_delete_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: DELETE_COMPANY_ROLE_REJECTED });
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: DELETE_COMPANY_ROLE_REJECTED });
    }
  }
}

export default function* rolesSaga() {
  yield all([
    takeLatest(EDIT_COMPANY_ROLE, getCompanyRole),
    takeLatest(EDIT_PROJECT_ROLES, getProjectRoles),
    takeLatest(SAVE_PROJECT_ROLES, saveProjectRoles),
    takeLatest(SAVE_COMPANY_ROLE, saveCompanyRole),
    takeLatest(DELETE_COMPANY_ROLE, deleteCompanyRole),
    takeLatest(TOGGLE_MODAL, clearRolesFormOnBlur),
  ]);
}
