/*
 * Actions for dispatching events
 * */
import {
  ADProjectroleActionTypes,
  GET_ADPROJECTROLES,
} from 'store/adprojectroles/types';

/*
 * Dispatch GET_ADPROJECTROLES for fetching roles
 * */

export const getADProjectRoles = (
  project_id: number
): ADProjectroleActionTypes => ({
  type: GET_ADPROJECTROLES,
  data: project_id,
});
