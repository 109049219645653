import {
  GET_ADCOMPANYROLES,
  GET_ADCOMPANYROLES_FULFILLED,
  GET_ADCOMPANYROLES_PENDING,
  GET_ADCOMPANYROLES_REJECTED,
} from 'store/adcompanyroles/types';
import { takeLatest, put } from '@redux-saga/core/effects';
import { get_companyroles } from 'utils/adminAxiosInstance';
import { showNotification } from 'store/notification/actions';

export function* getADCompanyroles(action) {
  const company_id = action.data;
  const req = yield get_companyroles(company_id);
  yield put({ type: GET_ADCOMPANYROLES_PENDING });
  try {
    const res = yield req;
    yield put({ type: GET_ADCOMPANYROLES_FULFILLED, data: res.data });
  } catch (err) {
    yield put({ type: GET_ADCOMPANYROLES_REJECTED });
    yield put(
      showNotification({
        identifier: 'companyroles-fetch-notification',
        type: 'error',
        text: err.response.data.error,
        autoClose: 2000,
      })
    );
  }
}

export default function* adcompanyrolesSaga() {
  yield takeLatest(GET_ADCOMPANYROLES, getADCompanyroles);
}
