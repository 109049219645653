import styled from 'styled-components';

export const StyledSection = styled.div`
  display: flex;
  flex-direction: row;

  > div:first-child {
    width: 20%;
  }

  > div:last-child {
    width: 80%;
  }

  @media (max-width: 920px) {
    flex-direction: column;

    > div {
      width: 100% !important;
    }
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize1};
    font-family: ${({ theme }) => theme.fontFamily};
  }

  & + & {
    margin-top: 100px;
  }
`;
