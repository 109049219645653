import React, { FC } from 'react';

import { StyledSection } from 'components/ui/Section/styles/SectionStyles';

export const Section: FC<{ title: string }> = ({ title, children }) => (
  <StyledSection>
    <div>
      <h2>{title}</h2>
    </div>
    <div>{children}</div>
  </StyledSection>
);
