import { UserWithRoles } from 'store/user/types';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_PENDING = 'GET_USERS_PENDING';
export const GET_USERS_FULFILLED = 'GET_USERS_FULFILLED';

export interface UsersState {
  readonly loading: boolean;
  readonly users?: UserWithRoles[];
}

interface GetUsersPendingAction {
  type: typeof GET_USERS_PENDING;
}

interface GetUsersFulfilledAction {
  type: typeof GET_USERS_FULFILLED;
  data: UserWithRoles[];
}

export type UsersActionTypes = GetUsersPendingAction | GetUsersFulfilledAction;
