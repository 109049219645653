import React, { FC } from 'react';
import styled from 'styled-components';
import { last, eq } from 'lodash';
import { rgba } from 'polished';
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'components/ui/Select/Select';

/**
 *
 */
export const FieldGroup = styled.div<{ width?: number }>`
  max-width: ${({ width }) => (width ? width : 80)}%;
  padding: 7.5px;
  background: ${({ theme }) => theme.purple2};
  border-radius: 5px;

  & + & {
    margin-top: 20px;
  }

  @media (max-width: 920px) {
    max-width: 100%;
  }
`;

export const FormSeperator = styled.hr`
  width: calc(100% - 20px);
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.purple3};
  margin: 45px auto 35px auto;
  opacity: 0.5;
`;

/**
 *
 * @param param0
 */
const Row: FC<{ className?: string }> = ({ className, children }) => (
  <div className={className}>{children}</div>
);

export const FieldRow = styled(Row)`
  display: flex;
  flex-direction: rows;

  > div {
    flex: 1;
    padding: 7.5px;
  }

  & + & {
    margin-top: 20px;
  }

  @media (max-width: 920px) {
    display: block;
  }

  > table {
    margin: 7.5px;
  }
`;

/**
 *
 * @param param0
 */
const CustomField = ({
  input,
  label,
  type,
  meta: { touched, error, warning, success, button },
  className,
  placeholder,
  style,
  readonly = false,
  disabled = false,
  icon,
}) => (
  <div
    className={`${className} ${error ? 'has-error' : ''} ${
      warning ? 'has-warning' : ''
    } ${success ? 'has-success' : ''} ${type === 'hidden' ? 'is-hidden' : ''} ${className}`}
  >
    {type !== 'checkbox' && (
      <label
        htmlFor={input.name
          .toLowerCase()
          .replace(/[^\w ]+/g, '')
          .replace(/ +/g, '-')}
      >
        {label}
      </label>
    )}
    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: type == 'checkbox' ? 'center' : 'flex-start'}}>
      <input
        {...input}
        name={last(input.name.split('.'))}
        type={type}
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
        style={style}
        id={input.name
          .toLowerCase()
          .replace(/[^\w ]+/g, '')
          .replace(/ +/g, '-')}
      />
      {type === 'checkbox' && (
        <label
          htmlFor={input.name
            .toLowerCase()
            .replace(/[^\w ]+/g, '')
            .replace(/ +/g, '-')}
        >
          {label}
        </label>
      )}
      {button
        ? button
        : null
      }
    </div>
    {icon && <FontAwesomeIcon icon={icon} />}
    {(error && <span className={'form-error'}>{error}</span>) ||
      (warning && <span className={'form-warning'}>{warning}</span>) ||
      (success && <span className={'form-success'}>{success}</span>) }
  </div>
);

/**
 *
 */
export const StyledCustomField = styled(CustomField)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &.is-hidden {
    display: none;
  }

  label {
    font-size: ${({ theme }) => theme.fontSize3};
    margin-bottom: 10px;
  }

  input, div input {
    border-radius: 5px;
    padding: ${props => (props.icon ? '15px 40px 15px 15px' : '20px')};
    font-size: ${({ theme }) => theme.fontSize3};
    border: 2px solid ${({ theme }) => theme.purple3};
    background: ${({ theme }) => theme.purple3};
    color: ${({ theme }) => theme.textColor};
    width: 100%;

    &:focus {
      outline: none;
      border: 2px solid ${({ theme }) => theme.blue};
      box-shadow: 0px 0px 12px ${({ theme }) => rgba(theme.blue, 0.25)};
    }

    &::placeholder {
      color: ${({ theme }) => rgba(theme.textColor, 0.3)};
    }
  }

  .form-error,
  .form-warning,
  .form-success,
  div.form-error,
  div.form-warning,
  div.form-success {
    font-family: ${({ theme }) => theme.fontFamilyBold};
    font-size: ${({ theme }) => theme.fontSize5};
    position: absolute;
    top: 100%;
  }

  &.has-error {
    position: relative;

    > input, > div input {
      border: 2px solid ${({ theme }) => theme.red};
      box-shadow: 0px 0px 12px ${({ theme }) => rgba(theme.red, 0.25)};
    }

    > .form-error, div .form-error {
      color: ${({ theme }) => theme.red};
    }
  }

  &.has-warning {
    > input, > div input {
      border: 2px solid ${({ theme }) => theme.orange};
    }

    > .form-warning, div .form-warning {
      color: ${({ theme }) => theme.orange};
    }
  }

  &.has-success {
    position: relative;

    > input, > div input {
      border: 2px solid ${({ theme }) => theme.green};
      box-shadow: 0px 0px 12px ${({ theme }) => rgba(theme.green, 0.25)};
    }

    > .form-success, div .form-success {
      color: ${({ theme }) => theme.green};
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.purple5};
    position: relative;
    display: block;
    content: '';
    margin: 0;
  }

  input[type='checkbox']:checked +  label::before {
    display: block;
    border-radius: 3px;
    content: '';
    position: absolute;
    left: 4px;
    right: 4px;
    top: 4px;
    bottom: 4px;
    background: ${({ theme }) => theme.green};
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin-right: 15px;
    font-size: 20px;
    color: ${({ theme }) => rgba(theme.textColor, 0.3)};
  }
`;

export default props => <Field component={StyledCustomField} {...props} />;

/*
  position: sticky;
  bottom: 0;
  margin-left: -40px;
  margin-right: -40px;
 */

// calculate padding substraction?
export const FormControls = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;

  z-index: 200;

  background: ${({ theme }) => theme.purple7};
  padding: 15px;

  display: flex;
  flex-direction: row-reverse;
`;

export const Form = styled.form`
  margin: 50px 0 150px 0;
`;

const ReduxFormSelect = ({
  input,
  options,
  error,
  className,
  warning,
  label,
  defaultValue = [],
}) => {
  if (input.value !== '' && !eq(input.value, defaultValue)) {
    defaultValue = input.value;
  } else {
    // if last user gets removed, defaultValue will be resetted
    // console.log('reset', defaultValue);
  }

  return (
    <div
      className={`${className} ${error ? 'has-error' : ''} ${
        warning ? 'has-warning' : ''
      }`}
    >
      <label
        htmlFor={input.name
          .toLowerCase()
          .replace(/[^\w ]+/g, '')
          .replace(/ +/g, '-')}
      >
        {label}
      </label>
      <Select
        {...input}
        onChange={value => {
          input.onChange(value);
        }}
        onBlur={() => {
          input.onBlur(input.value);
        }}
        value={defaultValue}
        options={options}
        isMulti={true}
        isClearable={false}
      />
    </div>
  );
};

const ReduxSingleFormSelect = ({
  input,
  options,
  className,
  label,
  meta: { touched, error, warning },
  defaultValue = [],
  multiple = false,
}) => {
  if (input.value !== '' && !eq(input.value, defaultValue)) {
    defaultValue = input.value;
  } else {
    // if last user gets removed, defaultValue will be resetted
    // console.log('reset', defaultValue);
  }

  return (
    <div
      className={`${className} ${error ? 'has-error' : ''} ${
        warning ? 'has-warning' : ''
      }`}
    >
      <label
        htmlFor={input.name
          .toLowerCase()
          .replace(/[^\w ]+/g, '')
          .replace(/ +/g, '-')}
      >
        {label}
      </label>
      <Select
        {...input}
        onChange={value => {
          input.onChange(value);
        }}
        onBlur={() => {
          input.onBlur(input.value);
        }}
        value={defaultValue}
        options={options}
        isMulti={multiple}
        isClearable={false}
      />
      {(error && <span className={'form-error'}>{error}</span>) ||
        (warning && <span className={'form-warning'}>{warning}</span>)}
    </div>
  );
};

export const StyledReduxFormSelect = styled(ReduxFormSelect)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    font-size: ${({ theme }) => theme.fontSize3};
    margin-bottom: 10px;
  }

  div[class$='-container'] > div[class$='-menu'] {
    background: ${({ theme }) => theme.purple5};
    border-radius: 5px;

    div[class$='-option'] {
      background: ${({ theme }) => theme.purple5};
      color: ${({ theme }) => theme.textColor};
      padding: 12px;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  div[class$='-container'] > div[class$='-control'] {
    padding: 12px;
    background: ${({ theme }) => theme.purple3};

    > div {
      padding: 0;
    }

    & div[class$='-multiValue'] {
      background: ${({ theme }) => theme.purple5};

      > div:first-child {
        padding: 10px 10px 10px 10px;
      }

      > div:last-child {
        padding-left: 10px;
        padding-right: 10px;
        background: rgba(0, 0, 0, 0.1);

        &:hover {
          background: ${({ theme }) => theme.red};
        }
      }
    }
  }
`;

export const StyledReduxSingleFormSelect = styled(ReduxSingleFormSelect)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .form-error,
  .form-warning {
    font-family: ${({ theme }) => theme.fontFamilyBold};
    font-size: ${({ theme }) => theme.fontSize5};
    position: absolute;
    top: 100%;
  }

  &.has-error {
    position: relative;

    div[class$='-container'] {
      border: 2px solid ${({ theme }) => theme.red} !important;
      box-shadow: 0px 0px 12px ${({ theme }) => rgba(theme.red, 0.25)} !important;
      border-radius: 5px;

      &:active {
        border: 2px solid ${({ theme }) => theme.red} !important;
        box-shadow: 0px 0px 12px ${({ theme }) => rgba(theme.red, 0.25)} !important;
        border-radius: 5px;
      }
    }

    > .form-error {
      color: ${({ theme }) => theme.red};
    }
  }

  &.has-warning {
    div[class$='-container'] {
      border: 2px solid ${({ theme }) => theme.orange};
      border-radius: 5px;
    }

    > .form-warning {
      color: ${({ theme }) => theme.orange};
    }
  }

  label {
    font-size: ${({ theme }) => theme.fontSize3};
    margin-bottom: 10px;
  }

  div[class$='-container'] > div[class$='-menu'] {
    background: ${({ theme }) => theme.purple5};
    border-radius: 5px;

    div[class$='-option'] {
      background: ${({ theme }) => theme.purple5};
      color: ${({ theme }) => theme.textColor};
      padding: 12px;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  div[class$='-container'] > div[class$='-control'] {
    padding: 12px;
    background: ${({ theme }) => theme.purple3};

    > div {
      padding: 0;
    }

    & div[class$='-multiValue'] {
      background: ${({ theme }) => theme.purple5};

      > div:first-child {
        padding: 10px 10px 10px 10px;
      }

      > div:last-child {
        padding-left: 10px;
        padding-right: 10px;
        background: rgba(0, 0, 0, 0.1);

        &:hover {
          background: ${({ theme }) => theme.red};
        }
      }
    }
  }
`;

export const StyledSingleFormSelect = props => (
  <Field component={StyledReduxSingleFormSelect} {...props} />
);
