import { ModalActionTypes, ModalState, TOGGLE_MODAL } from 'store/modal/types';

const initalState: ModalState = {
  opened: false,
  edit: false,
  style: 'default',
};

export default (state = initalState, action: ModalActionTypes) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        opened: !state.opened,
        selected: action.selected,
        edit: typeof action.edit === 'undefined' ? false : action.edit,
        style: typeof action.style === 'undefined' ? 'default' : action.style,
      };
    default:
      return state;
  }
};
