import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Props } from 'react-select/base';

import {
  StyledDropdownIndicator,
  StyledSelect,
  SelectedElementWithTheme,
  styles,
} from 'components/ui/Select/styles/SelectStyles';

const DropdownIndicator: FC = () => (
  <StyledDropdownIndicator>
    <FontAwesomeIcon icon={['far', 'angle-down']} fixedWidth />
  </StyledDropdownIndicator>
);

const Select: FC<Props> = props => {
  return (
    <StyledSelect {...props}>
      <SelectedElementWithTheme
        styles={styles}
        components={{
          DropdownIndicator,
        }}
        {...props}
      />
    </StyledSelect>
  );
};

export default Select;
