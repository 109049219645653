import React, { FC, useEffect } from 'react';
import Oidc from 'oidc-client';
import { Store } from 'redux';

import { userManager } from 'utils/userManager';
import {
  userExpired,
  userFound,
  silentRenewError,
  sessionTerminated,
  userExpiring,
  userSignedOut,
} from 'store/auth/actions';

interface Props {
  store: Store;
}

/**
 *
 * @param props
 */
const AuthProvider: FC<Props> = props => {
  const { store } = props;

  useEffect(() => {
    const onUserLoaded = (user: Oidc.User) => {
      store.dispatch(userFound(user));
    };

    const onSilentRenewError = () => {
      store.dispatch(silentRenewError());
    };

    const onAccessTokenExpired = () => {
      store.dispatch(userExpired());
    };

    const onUserUnloaded = () => {
      store.dispatch(sessionTerminated());
    };

    const onAccessTokenExpiring = () => {
      store.dispatch(userExpiring());
    };

    const onUserSignedOut = () => {
      store.dispatch(userSignedOut());
    };

    userManager.events.addUserLoaded(onUserLoaded);
    userManager.events.addUserUnloaded(onUserUnloaded);
    userManager.events.addUserSignedOut(onUserSignedOut);
    userManager.events.addSilentRenewError(onSilentRenewError);
    userManager.events.addAccessTokenExpired(onAccessTokenExpired);
    userManager.events.addAccessTokenExpiring(onAccessTokenExpiring);
    return () => {
      userManager.events.removeUserLoaded(onUserLoaded);
      userManager.events.removeUserUnloaded(onUserUnloaded);
      userManager.events.removeUserSignedOut(onUserSignedOut);
      userManager.events.removeSilentRenewError(onSilentRenewError);
      userManager.events.removeAccessTokenExpired(onAccessTokenExpired);
      userManager.events.removeAccessTokenExpiring(onAccessTokenExpiring);
    };
  }, [store]);

  return <>{props.children}</>;
};

export default AuthProvider;
