import {
  put,
  select,
  all,
  takeLatest,
  fork,
  cancelled,
} from 'redux-saga/effects';
import { initialize, reset } from 'redux-form';
import { get, flatten, reduce, filter } from 'lodash';
import i18next from 'i18next';

import { request } from 'utils/axiosInstance';
import { showNotification } from 'store/notification/actions';
import { normalize } from 'utils/form';

import { Project } from 'store/project/types';

import {
  GET_COMPANY_PENDING,
  GET_COMPANY_FULFILLED,
  SAVE_COMPANY,
  SAVE_COMPANY_PENDING,
  SAVE_COMPANY_FULFILLED,
  SAVE_COMPANY_REJECTED,
  UPLOAD_COMPANY_AVATAR,
  UPLOAD_COMPANY_AVATAR_PENDING,
  UPLOAD_COMPANY_AVATAR_FULFILLED,
  UPLOAD_COMPANY_AVATAR_REJECTED,
} from 'store/company/types';

import { ProjectRole, CompanyRole } from 'store/roles/types';

import {
  GET_COMPANY_ROLES_PENDING,
  GET_COMPANY_ROLES_FULFILLED,
  GET_PROJECT_ROLES_PENDING,
  GET_PROJECT_ROLES_FULFILLED,
} from 'store/roles/types';

import { GET_USERS_PENDING, GET_USERS_FULFILLED } from 'store/users/types';

import {
  CREATE_ROLE,
  CREATE_ROLE_PENDING,
  CREATE_ROLE_FULFILLED,
  CREATE_ROLE_REJECTED,
} from 'store/roles/types';

import {
  GET_PROJECTS_COMPANY_PENDING,
  GET_PROJECTS_COMPANY_FULFILLED,
} from 'store/projects/types';

export function* fetchCompany() {
  // yield take(USER_FOUND);
  const loading = yield select(state => state.auth.loading);
  if (loading) throw Error('no user loaded');

  yield put({ type: GET_PROJECTS_COMPANY_PENDING });
  yield put({ type: GET_COMPANY_PENDING });
  yield put({ type: GET_USERS_PENDING });
  yield put({ type: GET_COMPANY_ROLES_PENDING });
  yield put({ type: GET_PROJECT_ROLES_PENDING });

  // const token = yield select(getAccessToken);
  const [getCompany] = yield request('GET_COMPANY');
  try {
    const res = yield getCompany;

    const { projects, company } = res.data;

    // project roles ...
    const projectsWithIds = reduce(
      projects,
      (acc, value: Project) => {
        return { ...acc, [value.id]: value };
      },
      {}
    );

    const projectRolesFlattened = flatten(
      projects &&
        projects.map(
          (project: Project) =>
            project.roles &&
            project.roles.map(role => ({ ...role, project_id: project.id }))
        )
    );

    const projectRoles = reduce(
      projectRolesFlattened,
      (acc, value: ProjectRole) => {
        return value && { ...acc, [value.id]: value };
      },
      {}
    );

    // company roles ...
    const companyRoles = reduce(
      company.roles,
      (acc, value: CompanyRole) => {
        return { ...acc, [value.id]: value };
      },
      {}
    );

    // dont repeat and remove projects
    const cleaned = res.data;
    const users = res.data.users;
    delete cleaned.projects;
    delete cleaned.users;

    yield put({ type: GET_PROJECTS_COMPANY_FULFILLED, data: projectsWithIds });
    yield put({ type: GET_PROJECT_ROLES_FULFILLED, data: projectRoles });
    yield put({ type: GET_COMPANY_ROLES_FULFILLED, data: companyRoles });
    yield put({ type: GET_COMPANY_FULFILLED, data: cleaned });
    yield put({ type: GET_USERS_FULFILLED, data: users });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_COMPANY_PENDING });
  }
}

export function* saveCompany(action) {
  const { values } = action;

  const initial = yield select(state =>
    get(state, 'form.company-details.initial', {})
  );

  const normalized = normalize(initial, values);

  yield put({ type: SAVE_COMPANY_PENDING });
  const [putCompany, cancel] = yield request(
    'PUT_COMPANY',
    Object.assign(normalized, {})
  );
  try {
    const res = yield putCompany;
    yield put({ type: SAVE_COMPANY_FULFILLED, data: res.data.resource });
    yield put(
      showNotification({
        identifier: 'company-saved-notification',
        type: 'success',
        text: i18next.t('common:notification.company_details_saved_success'),
        autoClose: 2000,
      })
    );
    yield put(initialize('company-details', values));
  } catch (error) {
    yield put({ type: SAVE_COMPANY_REJECTED });
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: SAVE_COMPANY_REJECTED });
    }
  }
}

export function* createRole(action) {
  const { values } = action;

  const endpoints = yield select(state => get(state, 'endpoints', []));

  values.authed_endpoints = Object.keys(
    get(values, 'authed_endpoints', {})
  ).map(endpoint => get(endpoints, `${endpoint}.id`, null));

  // clean up null values
  values.authed_endpoints = filter(values.authed_endpoints, ep => ep !== null);

  yield put({ type: CREATE_ROLE_PENDING });
  const [postCompanyRole, cancel] = yield request('POST_COMPANY_ROLE', values);

  try {
    const res = yield postCompanyRole;
    yield put({ type: CREATE_ROLE_FULFILLED, data: res.data.resource });
    yield put(
      showNotification({
        identifier: 'user-created-notification',
        type: 'success',
        text: i18next.t('common:notification.company_role_created_success'),
        autoClose: 2000,
      })
    );
    yield put(reset('role-create'));
    yield put({ type: 'TOGGLE_MODAL', selected: 'role' });
  } catch (error) {
    // how to get response? res is undefined, if used with let?
    yield put(
      showNotification({
        identifier: 'role-created-notification',
        type: 'error',
        text: i18next.t('common:notification.company_role_create_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: CREATE_ROLE_REJECTED });
  } finally {
    if (yield cancelled()) {
      cancel('cancelled');
      yield put({ type: CREATE_ROLE_REJECTED });
    }
  }
}

function* uploadCompanyAvatar(action) {
  const { files } = action;

  yield put({ type: UPLOAD_COMPANY_AVATAR_PENDING });
  const [postCompanyAvatar] = yield request(
    'POST_COMPANY_AVATAR',
    {
      avatar: files[0],
    },
    { formData: true }
  );

  try {
    const res = yield postCompanyAvatar;

    yield put({
      type: UPLOAD_COMPANY_AVATAR_FULFILLED,
      data: {
        src: res.data.resource.avatar_url,
      },
    });

    // console.log(res);
  } catch (error) {
    console.log(error);
    yield put({ type: UPLOAD_COMPANY_AVATAR_REJECTED });
  }
}

export default function* companySaga() {
  yield all([
    fork(fetchCompany),
    takeLatest(SAVE_COMPANY, saveCompany),
    takeLatest(CREATE_ROLE, createRole),
    takeLatest(UPLOAD_COMPANY_AVATAR, uploadCompanyAvatar),
  ]);
}
