export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

export interface NotificationOptions {
  text: string;
  identifier?: string;
  autoClose?: boolean | number;
  type?: string;
}

interface ShowNotificationAction {
  type: typeof SHOW_NOTIFICATION;
  options: NotificationOptions;
}

export type NotificationActionTypes = ShowNotificationAction;
