import React, { FC } from 'react';

import Breadcrumbs from 'components/ui/Breadcrumbs/Breadcrumbs';
import Notifications from 'components/ui/Notifications/Notifications';
import UserDetails from 'container/StatusBar/UserDetails';

import { StyledStatusBar } from 'container/StatusBar/styles/StatusBar';

const StatusBar: FC<{}> = () => {
  return (
    <StyledStatusBar>
      <Breadcrumbs />
      <Notifications style={{ display: 'none' }} />
      <UserDetails />
    </StyledStatusBar>
  );
};

export default StatusBar;
