export const BASE = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const AUTH = '/auth';
export const USER_DETAILS = '/user-details';
export const COMPANY_DETAILS = '/company-details';

/*
 * Company routes
 * */
export const COMPANIES = '/companies';

/*
 * Project routes
 * */
export const PROJECTS = '/projects';

/*
 * User routes
 * */
export const USERS = '/users';
