import styled from 'styled-components';

export const StyledNotifications = styled.div`
  flex: 1;
  align-self: center;
  display: flex;
  justify-content: space-between;
  max-width: 130px;

  @media (max-width: 920px) {
    display: none;
  }

  svg {
    font-size: ${({ theme }) => theme.fontSize0};
  }
`;
