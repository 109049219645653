import { takeLatest, take, put, select } from '@redux-saga/core/effects';
import {
  FINISH_CURRENT_FLOW,
  SET_CURRENT_FLOW,
  RESET_CURRENT_FLOW,
  FINISH_CURRENT_FLOW_STEP,
} from 'store/flow/types';
import { showNotification } from 'store/notification/actions';
import i18next from 'i18next';
import { StateType } from 'store/types';
import { CREATE_ADCOMPANY_FULFILLED } from 'store/companies/types';
import { CREATE_ADPROJECT_FULFILLED } from 'store/adprojects/types';
import { change, initialize } from 'redux-form';
import { CREATE_ADUSER_FULFILLED } from 'store/adusers/types';
import { GET_ADPROJECTROLES, GET_ADPROJECTROLES_FULFILLED } from 'store/adprojectroles/types';
import { TOGGLE_MODAL } from 'store/modal/types';
import { GET_ADCOMPANYROLES } from 'store/adcompanyroles/types';

function* finishFlow(action) {
  const currentFlow = yield(select((state: StateType) => state.currentFlow));
  if(currentFlow.isActive){
    const { history } = action;
    history.push(currentFlow.flow.currentStep.action);
    yield put({
      type: TOGGLE_MODAL,
      selected: currentFlow.flow.modal
    });
  }
  yield take(FINISH_CURRENT_FLOW);
  yield put(
    showNotification({
      autoClose: 10000,
      text: `${i18next.t('common:flows.finished')}`,
      type: 'success',
      identifier: 'flow-success-notification',
    })
  );
  // Clean up state
  yield put({ type: RESET_CURRENT_FLOW });
}

function* finishFlowStep(action) {
  /*
   * Get the created company
   * */
  const new_resource_action = action;
  const new_resource = new_resource_action.data;
  const finished_step = yield take(FINISH_CURRENT_FLOW_STEP);
  /*
   * The routing history
   * */
  const history = finished_step.history;

  /*
   * The updated flow with the next currentStep
   * */
  const currentFlow = yield select((state: StateType) => state.currentFlow);

  if (finished_step.data && currentFlow.isActive === false) {
    history.push('/');
    yield put({ type: FINISH_CURRENT_FLOW });
  } else {
    /*
     * Navigate to the next step-view
     * */
    history.push(currentFlow.flow.currentStep.action);

    switch (new_resource_action.type) {
      case CREATE_ADCOMPANY_FULFILLED:
        // Open Project Modal
        yield put({
          type: 'TOGGLE_MODAL',
          selected: 'project',
          opened: true,
        });

        // Load and initialize company_id (object)
        yield put(
          change('project-create', 'company_id', {
            value: new_resource.id,
            label: new_resource.name,
          })
        );
        break;
      case CREATE_ADPROJECT_FULFILLED:
        // Open Project Modal
        yield put({
          type: 'TOGGLE_MODAL',
          selected: 'user',
          opened: true,
        });

        const companies = yield select(
          (state: StateType) => state.adcompanies.companies
        );
        const company_ = companies.find(
          company => company.id === new_resource.project.company_id
        );

        // Load and initialize company_id (object)
        yield put(
          change('user-create', 'company_id', {
            value: company_.id,
            label: company_.name,
          })
        );
        yield put(change('user-create', 'active', { ACTIVE: true }));
        const { id } = action.data.project;
        // Load and initialize project_roles for company_id
        yield put(
          {
            type: GET_ADCOMPANYROLES,
            data: company_.id
          }
        )
        yield put(
          {
            type: GET_ADPROJECTROLES,
            data: id,
          }
        );

        // Wait until the project_roles are loaded
        yield take(GET_ADPROJECTROLES_FULFILLED);
        const project_roles = yield(select((state: StateType) => state.adprojectroles.roles));

        // Now update the user-modal
        yield put(
          change('user-create', 'project_roles', [{label: `${project_roles[0].name}: ${project_roles[0].project.name}`, value: project_roles[0].id}])
        );
        break;
      case CREATE_ADUSER_FULFILLED:
        if (!currentFlow.isActive) {
          yield put({ type: FINISH_CURRENT_FLOW });
        } else {
          /*
           * Find the next, redirect the user and tell that the flow is unfinished
           * */
        }
        break;
    }
  }
}

export default function* flowSaga() {
  yield takeLatest(SET_CURRENT_FLOW, finishFlow);
  yield takeLatest(CREATE_ADCOMPANY_FULFILLED, finishFlowStep);
  yield takeLatest(CREATE_ADPROJECT_FULFILLED, finishFlowStep);
  yield takeLatest(CREATE_ADUSER_FULFILLED, finishFlowStep);
}
