import React, { FC } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { useTranslation } from 'react-i18next';

import Field, {
  StyledReduxFormSelect,
  FieldRow,
} from 'components/ui/Form/Form';

import { useSelector } from 'react-redux';
import { StateType } from 'store/types';

const SelectCompanyForm: FC<{}> = () => {
  const { t } = useTranslation();
  const { loading, companies } = useSelector(
    (state: StateType) => state.adcompanies
  );
  const state_ = useSelector(state => state);
  let selector = formValueSelector('users-company-select');
  let selected_companies = selector(state_, 'companies');

  return (
    <FieldRow>
      <Field type="hidden" name="company" />
      <Field
        label={t('projects:form.select_company')}
        name="companies"
        component={StyledReduxFormSelect}
        options={
          companies && companies.length > 0
            ? companies.map((company: any) => {
              return {
                label: company.name,
                value: company.id,
              };
            })
            : null
        }
        default={selected_companies}
      />
    </FieldRow>
  );
};

export default reduxForm<{}>({
  form: 'users-company-select',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(SelectCompanyForm);
