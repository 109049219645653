import {
  CreateProjectFulfilledAction,
  DeleteProjectFulfilledAction,
} from 'store/project/types';

export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_PENDING = 'CREATE_ROLE_PENDING';
export const CREATE_ROLE_FULFILLED = 'CREATE_ROLE_FULFILLED';
export const CREATE_ROLE_REJECTED = 'CREATE_ROLE_REJECTED';

export const DELETE_COMPANY_ROLE = 'DELETE_COMPANY_ROLE';
export const DELETE_COMPANY_ROLE_PENDING = 'DELETE_COMPANY_ROLE_PENDING';
export const DELETE_COMPANY_ROLE_FULFILLED = 'DELETE_COMPANY_ROLE_FULFILLED';
export const DELETE_COMPANY_ROLE_REJECTED = 'DELETE_COMPANY_ROLE_REJECTED';

export const EDIT_COMPANY_ROLE = 'EDIT_COMPANY_ROLE';
export const EDIT_COMPANY_ROLE_PENDING = 'EDIT_COMPANY_ROLE_PENDING';
export const EDIT_COMPANY_ROLE_FULFILLED = 'EDIT_COMPANY_ROLE_FULFILLED';
export const EDIT_COMPANY_ROLE_REJECTED = 'EDIT_COMPANY_ROLE_REJECTED';

export const SAVE_COMPANY_ROLE = 'SAVE_COMPANY_ROLE';
export const SAVE_COMPANY_ROLE_PENDING = 'SAVE_COMPANY_ROLE_PENDING';
export const SAVE_COMPANY_ROLE_FULFILLED = 'SAVE_COMPANY_ROLE_FULFILLED';
export const SAVE_COMPANY_ROLE_REJECTED = 'SAVE_COMPANY_ROLE_REJECTED';

export const GET_COMPANY_ROLES_PENDING = 'GET_COMPANY_ROLES_PENDING';
export const GET_COMPANY_ROLES_FULFILLED = 'GET_COMPANY_ROLES_FULFILLED';

export const GET_PROJECT_ROLES_PENDING = 'GET_PROJECT_ROLES_PENDING';
export const GET_PROJECT_ROLES_FULFILLED = 'GET_PROJECT_ROLES_FULFILLED';

export const EDIT_PROJECT_ROLES = 'EDIT_PROJECT_ROLES';

export const SAVE_PROJECT_ROLES = 'SAVE_PROJECT_ROLES';
export const SAVE_PROJECT_ROLES_PENDING = 'SAVE_PROJECT_ROLES_PENDING';
export const SAVE_PROJECT_ROLES_FULFILLED = 'SAVE_PROJECT_ROLES_FULFILLED';
export const SAVE_PROJECT_ROLES_REJECTED = 'SAVE_PROJECT_ROLES_REJECTED';

export interface AuthedTags {
  key: string;
  read: boolean;
  value: string;
  write: boolean;
}

export interface Role {
  authed_endpoints: number[]; // typeof ENDPOINTS?
  id: number;
  name: string;
  description?: string;
}

export interface ProjectRole extends Role {
  authed_tags: AuthedTags[];
  project_id: number;
}

export interface CompanyRole extends Role {
  company_id: number;
}

export interface RolesState {
  readonly project_roles_loading: boolean;
  readonly company_roles_loading: boolean;
  readonly project?: { [id: number]: ProjectRole };
  readonly company?: { [id: number]: CompanyRole };
}

interface GetCompanyRolesPendingAction {
  type: typeof GET_COMPANY_ROLES_PENDING;
}

interface GetCompanyRolesFulFilledAction {
  type: typeof GET_COMPANY_ROLES_FULFILLED;
  data: object[];
}

interface GetProjectRolesPendingAction {
  type: typeof GET_PROJECT_ROLES_PENDING;
}

interface GetProjectRolesFulFilledAction {
  type: typeof GET_PROJECT_ROLES_FULFILLED;
  data: object[];
}

interface CreateRoleFulfilledAction {
  type: typeof CREATE_ROLE_FULFILLED;
  data: any; // TODO: shape
}

export interface DeleteCompanyRoleFulfilledAction {
  type: typeof DELETE_COMPANY_ROLE_FULFILLED;
  data: any; // TODO: shape
  // meta?: object;
}

export interface SaveCompanyRoleFulfilled {
  type: typeof SAVE_COMPANY_ROLE_FULFILLED;
  data: any; // TODO: shape
}

export interface EditProjectRolesAction {
  type: typeof EDIT_PROJECT_ROLES;
  userID: number;
}

export type RolesActionTypes =
  | GetProjectRolesPendingAction
  | GetProjectRolesFulFilledAction
  | GetCompanyRolesPendingAction
  | GetCompanyRolesFulFilledAction
  | CreateRoleFulfilledAction
  | DeleteProjectFulfilledAction
  | CreateProjectFulfilledAction
  | DeleteCompanyRoleFulfilledAction
  | SaveCompanyRoleFulfilled
  | EditProjectRolesAction;
