import { useState, useContext, RefObject } from 'react';
import { __RouterContext } from 'react-router';
import { findDOMNode } from 'react-dom';
import { useSelector } from 'react-redux';
import {
  get,
  pick,
  map,
  union,
  intersection,
  isArray,
  omitBy,
  isEqual,
  reduce,
  isObject,
  isEmpty,
  sortedUniq,
  has,
  isRegExp,
  filter,
  uniq,
  merge,
} from 'lodash';
import qs from 'qs';
import * as Highcharts from 'highcharts';

import { StateType } from 'store/types';
import Theme from 'config/themes/default';

interface QueryStringObject {
  [key: string]: string;
}

/**
 *
 * @returns
 */
export function useSetQueryString(): (
  newParams: QueryStringObject,
  multipleValuesForKeys?: boolean,
  reset?: boolean
) => void {
  const { location, history } = useContext(__RouterContext);

  const callback = (
    newParams: QueryStringObject,
    multipleValuesForKeys?: false,
    reset?: boolean
  ) => {
    const oldParams = reset ? {} : qs.parse(location.search.slice(1));
    let params = {};

    if (multipleValuesForKeys) {
      let exists = false;
      // -- TODO --
      // At the moment the filtering just works for dataPointsIDs[]
      // make this more generic!
      Object.values(oldParams).forEach(v => {
        if (isArray(v) && v.includes(newParams['dataPointIDs[]'])) {
          exists = true;
          params = {
            ...oldParams,
            dataPointIDs: v.filter(id => id !== newParams['dataPointIDs[]']),
          };
        }
      });
      if (!exists) {
        params = {
          ...oldParams,
          ...newParams,
        };
      }
    } else {
      // Combine old and new params (replace values with the same keys )
      params = {
        ...oldParams,
        ...newParams,
      };
      // Omit key value pairs which are empty
      params = {
        ...omitBy(params, (value: string) => !value.length),
      };
    }
    // Create a new query string and push the string to the history
    const query = qs.stringify(params, { addQueryPrefix: true });
    history.push(`${location.pathname}${query}`);
  };

  return callback;
}

/**
 * @param initialState
 * @param ref
 */
export function useClickOutside(
  initialState: boolean,
  ref: RefObject<HTMLElement>
): [boolean, (boolean) => void] {
  const [open, setOpen] = useState(initialState);

  const handleOutsideClick = event => {
    const node = findDOMNode(ref.current);
    if (node && !node.contains(event.target as Node)) {
      setOpen(false);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      unbindEventListeners();
    }
  };

  const bindEventListeners = () => {
    if ('ontouchend' in window) {
      document.addEventListener('touchend', handleOutsideClick);
    } else {
      document.addEventListener('click', handleOutsideClick);
    }
  };

  const unbindEventListeners = () => {
    document.removeEventListener('touchend', handleOutsideClick);
    document.removeEventListener('click', handleOutsideClick);
  };

  if (open) {
    bindEventListeners();
  } else {
    unbindEventListeners();
  }

  return [open, setOpen];
}

const convertEndpointEnumeration = (grant, endpoints) =>
  grant.map(dependency => {
    if (isArray(dependency)) {
      const filtered = filter(
        dependency.map(dep => get(endpoints, `${dep}.id`, null)),
        el => el !== null
      );

      return isEmpty(filtered) ? null : filtered;
    }
    return get(endpoints, `${dependency}.id`, null);
  });

export const useGuard = (...grant) => {
  const endpoints = useSelector(state => get(state, 'endpoints', []));
  const companyRoles = useSelector(state =>
    get(state, 'user.details.companyRoles', [])
  );

  const projectRoles = useSelector(state =>
    get(state, 'user.details.roles', [])
  );

  const baseEndpoints = union(
    ...map(
      companyRoles,
      obj => pick(obj, 'authed_endpoints')['authed_endpoints']
    )
  ).sort((a: number, b: number) => a - b);

  const rolesPerProject = reduce(
    projectRoles,
    (acc, value) => {
      // get all with same project_id and merge endpoints and merge with base endpoints
      if (has(acc, value.project_id)) {
        // extend
        acc[value.project_id] = uniq([
          value.authed_endpoints,
          ...acc[value.project_id],
        ]);
      } else {
        // first time
        acc[value.project_id] = Object.values(
          Object.assign({}, baseEndpoints, value.authed_endpoints)
        );
      }

      return acc;
    },
    {}
  );

  // add company endpoints ...
  rolesPerProject['COMPANY'] = baseEndpoints;

  const translatedGrant = convertEndpointEnumeration(grant, endpoints);

  const check = (roles, depends) => {
    const isAllowed = intersection(roles, depends).length === depends.length;
    return depends.length === 0 || isAllowed;
  };

  return map(translatedGrant, depends => {
    if (depends === null) {
      return (noop = null) => false;
    }

    return (project_id = null) => {
      if (!has(rolesPerProject, [project_id])) {
        // project role does not exist, fallback to company roles
        return check(
          rolesPerProject['COMPANY'],
          isArray(depends) ? depends : [depends]
        );
      }
      return check(
        rolesPerProject[project_id],
        isArray(depends) ? depends : [depends]
      );
    };
  });
};

export const useRoles = (
  ...roleDependencies: Array<string | Array<string>>
): boolean[] => {
  const factory = useGuard(...roleDependencies);
  return factory.map(closure => closure('COMPANY'));
};

export const useDataPointsWithOptions = ({
  glow = false,
  fill = false,
  type = null,
} = {}) => {
  const { timeseries } = useSelector((state: StateType) => state.dataPoint);
  // color palette
  const colors = [
    Theme.green,
    Theme.red,
    Theme.pink,
    Theme.yellow,
    Theme.blue,
    Theme.orange,
    Theme.purple,
  ];

  const getGlow = index =>
    glow && {
      offsetX: 1,
      offsetY: 1,
      opacity: 0.05,
      width: 20,
      color: colors[index],
    };

  const getFill = index =>
    fill && {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [0, colors[index]],
        [1, new Highcharts.Color(colors[index]).setOpacity(0.001).get('rgba')],
      ],
    };

  const getType = () => {
    if (fill) {
      return 'area';
    }

    return type;
  };

  // add options
  const getOptions = index => ({
    color: colors[index],
    marker: {
      radius: 1,
      lineWidth: 0,
      lineColor: 'transparent',
      states: {
        hover: {
          radius: 5,
          lineColor: '#2B2D5D',
          lineWidth: 3,
          lineWidthPlus: 0,
        },
      },
    },
    step: 'left',
    lineWidth: 3,
    states: {
      hover: {
        lineWidth: 3,
      },
    },
    threshold: null,
    shadow: getGlow(index),
    fillColor: getFill(index),
    type: getType(),
  });

  if (timeseries) {
    return timeseries.map((series, key) => {
      return { ...series, ...getOptions(key) };
    });
  }

  return null;
};

// redux-form pristine does not work with form elements, which have another empty state, like an empty array
// so we check the state between initial and current values
const cleanObject = obj => {
  return reduce(
    obj,
    (acc, value, key) => {
      if (isObject(value)) {
        // if object, clean up false/true values
        // thats actually just the case for role matrix endpoint management
        // maybe we could clean up this to support cleaning deeply nested objects.
        value = reduce(
          value,
          (acc, value, key) => {
            if (value !== false) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );
      }

      acc[key] = value === '' ? null : value;

      if (isEmpty(acc[key]) && !isArray(acc[key])) {
        delete acc[key];
      }
      return acc;
    },
    {}
  );
};
export const useIsEqualForm = formName => {
  const form = useSelector((state: StateType) => state.form[formName]);

  const fields = has(form, 'fields')
    ? Object.keys(get(form, 'fields', []))
    : sortedUniq(
      Object.keys(get(form, 'registeredFields', [])).map(
        value => value.split('.')[0]
      )
    );

  // ok we have some special fields for selects ...
  const preInitial = reduce(
    get(form, 'initial', {}),
    (acc, value, key) => {
      acc[key.replace('default_', '')] = value;
      return acc;
    },
    {}
  );

  const preValues = reduce(
    get(form, 'values', {}),
    (acc, value, key) => {
      if (key.startsWith('default_')) {
        acc[key.replace('default_', '')] = value;
      }
      acc[key] = value;
      return acc;
    },
    {}
  );

  const values = pick(preValues, fields);
  const initial = pick(preInitial, fields);

  return isEqual(cleanObject(values), cleanObject(initial));
};

export const useLoading = (...args) => {
  const { loading, queue } = useSelector((state: StateType) => state.meta);

  const states = args.map(item => {
    if (isRegExp(item)) {
      return queue.some(que => isArray(que.match(item)));
    }
    return queue.includes(item);
  });

  return [loading, ...states];
};

export const useFlowIsActive = (...args) => {
  /*
   * First retrieve the current flow
   * */
  const currentFlow = useSelector((state: StateType) => state.currentFlow);

  return currentFlow.isActive;
};

export const useIsCurrentStep = path => {
  /*
   * Get the current flow
   * */
  const currentFlow = useSelector((state: StateType) => state.currentFlow);

  if (!currentFlow.isActive) {
    return false;
  }

  return path === currentFlow.flow.currentStep.action;

};
