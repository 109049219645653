export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export interface ModalState {
  readonly opened: boolean;
  readonly selected?: string;
  readonly edit?: boolean;
  readonly style?: string;
}

interface ToggleModalAction {
  type: typeof TOGGLE_MODAL;
  selected?: string;
  edit?: boolean;
  style?: string;
}

export type ModalActionTypes = ToggleModalAction;
