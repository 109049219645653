import i18n from 'i18next';
// -- TODO --
// See https://github.com/i18next/i18next/issues/1294 and add the
// plugin later if a ts solution is available
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import decommon from 'i18n/locales/de/common.json';
import dedashboard from 'i18n/locales/de/dashboard.json';
import deuserdetails from 'i18n/locales/de/user_details.json';
import decompanydetails from 'i18n/locales/de/company_details.json';
import decompanies from 'i18n/locales/de/companies.json';
import deprojects from 'i18n/locales/de/projects.json';
import deusers from 'i18n/locales/de/users.json';
import dequicklinks from 'i18n/locales/de/quicklinks.json';
import deinitializers from 'i18n/locales/de/initializers.json';

import encommon from 'i18n/locales/en/common.json';
import endashboard from 'i18n/locales/en/dashboard.json';
import enuserdetails from 'i18n/locales/en/user_details.json';
import encompanydetails from 'i18n/locales/en/company_details.json';
import encompanies from 'i18n/locales/en/companies.json';
import enprojects from 'i18n/locales/en/projects.json';
import enusers from 'i18n/locales/en/users.json';
import enquicklinks from 'i18n/locales/en/quicklinks.json';
import eninitializers from 'i18n/locales/en/initializers.json';

/**
 * This is the i18next configuration which is used for internationalization
 * the aedifion react application.
 *
 */
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
    },
    // lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: {
        common: encommon,
        dashboard: endashboard,
        user_details: enuserdetails,
        company_details: encompanydetails,
        companies: encompanies,
        projects: enprojects,
        users: enusers,
        quicklinks: enquicklinks,
        initializers: eninitializers,
      },
      de: {
        common: decommon,
        dashboard: dedashboard,
        user_details: deuserdetails,
        company_details: decompanydetails,
        companies: decompanies,
        projects: deprojects,
        users: deusers,
        quicklinks: dequicklinks,
        initializers: deinitializers,
      },
    },
    debug: process.env.REACT_APP_I8N_DEBUG ? true : false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
