import React, { FC, useContext, useState } from 'react';
import { Section } from 'components/ui/Section/Section';
import { useTranslation } from 'react-i18next';
import { EmptyCard } from 'components/ui/Card/Card';
import { Button, Variant, Size } from 'components/ui/Button/Button';
import { Flows } from 'constants/flows';
import { useDispatch } from 'react-redux';
import { setFlow } from 'store/flow/actions';
import { __RouterContext } from 'react-router';
import { redirectToCompanyCreation } from 'store/companies/actions';
import { redirectToProjectCreation } from 'store/adprojects/actions';
import { redirectToUserCreation } from 'store/adusers/actions';
import { CardWrapper } from 'components/ui/Card/styles/CardStyles';
import { INITIALIZERS } from 'store/initializers/types';
import { initializeRequest } from 'utils/initializers';

/**
 *
 */
const Dashboard: FC<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history } = useContext(__RouterContext);
  const [initializer, setInitializer] = useState('');

  const [rolesInit, setRolesInit] = useState(false);
  const [hashesInit, setHashesInit] = useState(false);
  const [analyticsInit, setAnalyticsInit] = useState(false);
  const [syncInit, setSyncInit] = useState(false);

  return (
    <>
      <h1>Dashboard</h1>
      <Section title={t('common:flows.title')}>
        <CardWrapper>
          {Flows.map(flow => {
            return (
              <EmptyCard
                key={flow.id}
                label={t(flow.name)}
                icon={flow.icon}
                iconprefix={'fal'}
                onClick={e => {
                  dispatch(setFlow(flow.id, history));
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            );
          })}
        </CardWrapper>
      </Section>
      <Section title={t('quicklinks:title')}>
        <CardWrapper>
          <EmptyCard
            label={t('quicklinks:companies.create.card_title')}
            icon={'building'}
            iconprefix={'fal'}
            onClick={e => {
              /**
               * Dispatch the event to redirect the user and open the modal
               */
              dispatch(redirectToCompanyCreation(history));
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <EmptyCard
            label={t('quicklinks:projects.create.card_title')}
            icon={'chart-bar'}
            iconprefix={'fal'}
            onClick={e => {
              /**
               * Dispatch the event to redirect the user and open the modal
               */
              dispatch(redirectToProjectCreation(history));
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          <EmptyCard
            label={t('quicklinks:users.create.card_title')}
            icon={'user-plus'}
            iconprefix={'fal'}
            onClick={e => {
              /**
               * Dispatch the event to redirect the user and open the modal
               */
              dispatch(redirectToUserCreation(history));
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </CardWrapper>
      </Section>
      <Section title={t('initializers:texts.title')}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant={Variant.Success}
            scaleSize={Size.XS}
            onClick={() => initializeRequest(
              setRolesInit,
              {
                initializer: INITIALIZERS.INITIALIZE_ROLES,
              },
              dispatch,
              setInitializer('roles')
            )}
            icon={'user-plus'}
            loading={rolesInit}
            style={{
              marginRight: '1rem',
              width: '20%',
            }}
          >
            {t('initializers:texts.roles')}
          </Button>
          <Button
            variant={Variant.Success}
            scaleSize={Size.XL}
            onClick={() => initializeRequest(
              setHashesInit,
              {
                initializer: INITIALIZERS.INITIALIZE_DATAPOINT_HASH_IDS,
              },
              dispatch,
              setInitializer('hashes')
            )}
            loading={hashesInit}
            style={{
              marginRight: '1rem',
              width: '20%',
            }}
          >
            {t('initializers:texts.hashes')}
          </Button>
          <Button
            variant={Variant.Success}
            scaleSize={Size.XL}
            onClick={() => initializeRequest(
              setAnalyticsInit,
              {
                initializer: INITIALIZERS.INITIALIZE_ANALYTICS,
              },
              dispatch,
              setInitializer('analytics')
            )}
            loading={analyticsInit}
            style={{
              marginRight: '1rem',
              width: '22.5%',
            }}
          >
            {t('initializers:texts.analytics')}
          </Button>
          <Button
            variant={Variant.Success}
            scaleSize={Size.XL}
            onClick={() => initializeRequest(
              setSyncInit,
              {
                initializer: INITIALIZERS.SYNCHRONIZE_DATAPOINTS_WITH_INFLUX,
              },
              dispatch,
              setInitializer('sync_influx')
            )}
            loading={syncInit}
            style={{
              width: '20%',
            }}
          >
            {t('initializers:texts.sync_datapoints')}
          </Button>
        </div>
      </Section>
    </>
  );
};

export default Dashboard;
