import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/ui/Modal/Modal';

import { showNotification } from 'store/notification/actions';
import { useLoading } from 'utils/hooks';

interface FlatObject {
  [key: string]: string | number;
}

interface Props {
  identifier: string;
  onSubmit: (values: FlatObject) => void;
  isEdit?: boolean;
  Component: any;
}

const ModalContainer: FC<Props> = props => {
  const { identifier, Component, onSubmit, isEdit } = props;
  const { t } = useTranslation();
  return (
    <Modal identifier={identifier}>
      <Component
        onSubmit={onSubmit}
        isEdit={isEdit}
        onSubmitFail={errors => {
          showNotification({
            identifier: 'validation-error-notification',
            type: 'error',
            text: t('common:validation.general'),
            autoClose: 2000,
          });
        }}
      />
    </Modal>
  );
};

export default ModalContainer;
