import { map, filter, merge } from 'lodash';

import {
  DELETE_USER_FULFILLED,
  SAVE_USER_FULFILLED, UPLOAD_USER_AVATAR_FULFILLED,
  UserWithRoles,
} from 'store/user/types';

import {
  GET_USERS_PENDING,
  GET_USERS_FULFILLED,
  UsersState,
  UsersActionTypes,
} from 'store/users/types';

import { CREATE_USER_FULFILLED, UserActionTypes } from 'store/user/types';
import {
  CREATE_PROJECT_FULFILLED,
  DELETE_PROJECT_FULFILLED,
  ASSIGN_PROJECT_ROLE_TO_USER,
  ProjectActionTypes,
  REMOVE_PROJECT_ROLE_FROM_USER,
} from 'store/project/types';

const initialState: UsersState = {
  loading: false,
  users: [],
};

// UserActionTypes
export default (
  state = initialState,
  action: UserActionTypes | UsersActionTypes | ProjectActionTypes
) => {
  switch (action.type) {
    case GET_USERS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_FULFILLED:
      return {
        ...state,
        loading: false,
        users: action.data,
      };
    case CREATE_USER_FULFILLED:
      return {
        ...state,
        users: [...state.users, action.data],
      };
    // TODO: in the user state, this needs to be added, too?
    case CREATE_PROJECT_FULFILLED:
      return {
        ...state,
        users: map(state.users, (user: UserWithRoles) => {
          if (user.id === action.userID) {
            user.project_roles = [...user.project_roles, action.data.role.id];
          }
          return user;
        }),
      };
    case ASSIGN_PROJECT_ROLE_TO_USER:
      return {
        ...state,
        users: map(state.users, (user: UserWithRoles) => {
          if (user.id === action.userID) {
            user.project_roles = [...user.project_roles, action.roleID];
          }
          return user;
        }),
      };
    case REMOVE_PROJECT_ROLE_FROM_USER:
      return {
        ...state,
        users: map(state.users, (user: UserWithRoles) => {
          if (user.id === action.userID) {
            user.project_roles = user.project_roles.filter(
              roles => roles !== action.roleID
            );
          }
          return user;
        }),
      };
    case DELETE_PROJECT_FULFILLED:
      const deletedRole = Object.values(action.meta).find(
        roles => roles.project_id === action.data.id
      );
      return {
        ...state,
        users: map(state.users, (user: UserWithRoles) => {
          user.project_roles = user.project_roles.filter(
            roles => roles !== deletedRole.id
          );
          return user;
        }),
      };
    case DELETE_USER_FULFILLED:
      return {
        ...state,
        users: filter(state.users, value => value.id !== action.data.id),
      };
    case SAVE_USER_FULFILLED:
      return {
        ...state,
        users: map(state.users, (user: UserWithRoles) => {
          // @ts-ignore
          if (user.id === action.data.id) {
            user = merge(user, action.data);
          }
          return user;
        }),
      };
    case UPLOAD_USER_AVATAR_FULFILLED:
      return {
        ...state,
        users: map(state.users, (user: UserWithRoles) => {
          if (user.id === action.data.id) {
            user.avatar_url = action.data.src;
          }
          return user;
        }),
      };
    default:
      return state;
  }
};
