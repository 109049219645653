import {
  GET_ADPROJECTROLES,
  GET_ADPROJECTROLES_FULFILLED,
  GET_ADPROJECTROLES_PENDING,
  GET_ADPROJECTROLES_REJECTED,
} from 'store/adprojectroles/types';
import { takeLatest, put, fork } from '@redux-saga/core/effects';
import { get_projectroles } from 'utils/adminAxiosInstance';
import { showNotification } from 'store/notification/actions';
export function* getADProjectroles(action) {
  const project_id = action.data ? action.data : undefined;
  const req = yield get_projectroles(project_id);
  yield put({ type: GET_ADPROJECTROLES_PENDING });
  try {
    const res = yield req;
    yield put({ type: GET_ADPROJECTROLES_FULFILLED, data: res.data });
  } catch (err) {
    yield put({ type: GET_ADPROJECTROLES_REJECTED });
    yield put(
      showNotification({
        identifier: 'projectroles-fetch-notification',
        type: 'error',
        text:
          err.response && err.response.status === 403
            ? err.response.data.message
            : err.response.data.error,
        autoClose: 2000,
      })
    );
  }
}

export default function* adprojectrolesSaga() {
  yield takeLatest(GET_ADPROJECTROLES, getADProjectroles);
  yield fork(getADProjectroles, { type: GET_ADPROJECTROLES });
}
