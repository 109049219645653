import { omit } from 'lodash';

import {
  ProjectsState,
  ProjectsActionTypes,
  GET_PROJECTS_COMPANY_PENDING,
  GET_PROJECTS_COMPANY_FULFILLED,
  GET_USER_PROJECTS_PENDING,
  GET_USER_PROJECTS_FULFILLED,
} from 'store/projects/types';

import {
  CREATE_PROJECT_FULFILLED,
  DELETE_PROJECT_FULFILLED,
  SAVE_PROJECT_FULFILLED,
} from 'store/project/types';

const initialState: ProjectsState = {
  user_loading: false,
  company_loading: false,
  user: {},
  company: {},
};

export default function(state = initialState, action: ProjectsActionTypes) {
  switch (action.type) {
    case GET_PROJECTS_COMPANY_PENDING:
      return { ...state, company_loading: true };
    case GET_USER_PROJECTS_PENDING:
      return { ...state, user_loading: true };
    case GET_PROJECTS_COMPANY_FULFILLED:
      return {
        ...state,
        company_loading: false,
        company: action.data,
      };
    case GET_USER_PROJECTS_FULFILLED:
      return {
        ...state,
        user_loading: false,
        user: action.data,
      };
    case CREATE_PROJECT_FULFILLED:
      // attach role to project
      const project = {
        ...action.data.project,
        roles: [action.data.role],
      };
      return {
        ...state,
        // because this should be on the dashboard and user details page, too
        // we need to add it to personal projects
        user: {
          ...state.user,
          [action.data.project.id]: project,
        },
        company: {
          ...state.company,
          [action.data.project.id]: project,
        },
      };
    case DELETE_PROJECT_FULFILLED:
      return {
        ...state,
        user: omit(state.user, action.data.id),
        company: omit(state.company, action.data.id),
      };
    case SAVE_PROJECT_FULFILLED:
      return {
        ...state,
        company_loading: false,
        company: {
          ...state.company,
          [action.data.id]: {
            ...state.company[action.data.id],
            ...action.data,
          },
        },
      };
    default:
      return state;
  }
}
