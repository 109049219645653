import styled from 'styled-components';

export const StyledAlert = styled.div`
  .Toastify__toast-container {
    width: auto;
    padding: 0;
  }

  .Toastify__toast {
    border-radius: ${({ theme }) => theme.notification.alert.borderRadius};
    font-size: 16px;
    background-color: ${({ theme }) => theme.purple3};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
    padding: 20px 35px;
    position: relative;
    overflow: visible;
    min-height: 0;

    &:after {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background: #ccc;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
      position: absolute;
      left: -9px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 200;
    }

    &.Toastify__toast--success {
      &:after {
        background: ${({ theme }) => theme.notification.alert.success};
        box-shadow: 0px 0px 10px
          ${({ theme }) => theme.notification.alert.success};
      }
    }

    &.Toastify__toast--info {
      &:after {
        background: ${({ theme }) => theme.notification.alert.info};
        box-shadow: 0px 0px 10px ${({ theme }) => theme.notification.alert.info};
      }
    }

    &.Toastify__toast--warning {
      &:after {
        background: ${({ theme }) => theme.notification.alert.warn};
        box-shadow: 0px 0px 10px ${({ theme }) => theme.notification.alert.warn};
      }
    }

    &.Toastify__toast--error {
      &:after {
        background: ${({ theme }) => theme.notification.alert.error};
        box-shadow: 0px 0px 10px
          ${({ theme }) => theme.notification.alert.error};
      }
    }
  }
`;
