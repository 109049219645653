import styled from 'styled-components';

export const StyledStatusBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 500;
  background: ${({ theme }) => theme.purple1};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  display: flex;
  max-height: 75px;
  padding: 17px 0 17px 0;
`;
