import React, { FC, useState } from 'react';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Dropdown from 'components/ui/Dropdown/Dropdown';
import {
  StyledUserBar,
  StyledHorizontal,
  StyledUsername,
  StyledCompany,
  StyledUserAvatar,
  StyledLogoutButton,
  StyledLink,
} from 'container/StatusBar/styles/UserDetails';

import * as Routes from 'constants/routes';
import { UserState } from 'store/user/types';
import { useRoles } from 'utils/hooks';

interface AvatarProps {
  className?: string;
  user: UserState;
}

/**
 *
 * @param param0
 */
const UserAvatar: FC<AvatarProps> = ({ className, user }) => (
  <img
    width={40}
    height={40}
    className={className}
    src={user.details.avatar_url}
    onError={(e: any) => {
      e.target.onerror = null;
      e.target.src = 'assets/images/placeholder-avatar.svg';
    }}
    alt={
      user &&
      user.details &&
      `${user.details.firstName} ${user.details.lastName}`
    }
  />
);

/**
 *
 * @param param0
 */
const LogoutButton: FC<{ className?: string }> = ({ className, children }) => (
  <StyledLogoutButton>
    <StyledLink to={'logout'} className={className}>
      <FontAwesomeIcon icon={['far', 'sign-out']} fixedWidth /> {children}
    </StyledLink>
  </StyledLogoutButton>
);

/**
 *
 * @param param0
 */
const WithDropdown: FC<{ user: UserState }> = ({ user }) => {
  const [language, setLanguage] = useState(i18n.language);
  const { t } = useTranslation();
  const [canSeeCompany] = useRoles('GET_COMPANY');

  return (
    <Dropdown
      component={
        <StyledHorizontal>
          {user.details.avatar_url && (
            <StyledUserAvatar>
              <UserAvatar user={user} />
            </StyledUserAvatar>
          )}
          <FontAwesomeIcon icon={['fas', 'caret-down']} fixedWidth />
        </StyledHorizontal>
      }
      items={[
        { label: t('common:dropdown.user_details'), to: Routes.USER_DETAILS },
          canSeeCompany && {
              label: t('common:dropdown.company_details'),
              to: Routes.COMPANY_DETAILS,
          },
        {
          label: t('common:language.switch'),
          dispatch: () => {
            if (language === 'en') {
              i18n.changeLanguage('de');
              setLanguage('de');
            } else {
              i18n.changeLanguage('en');
              setLanguage('en');
            }
          },
        },
      ]}
      placement={'bottom-start'}
      header={t('common:dropdown.user_settings')}
      footer={<LogoutButton>{t('common:button.logout')}</LogoutButton>}
    />
  );
};

/**
 * StatusBar contains alerts and user profile settings
 *
 * @param {React.Component} - react child component
 * @returns {ReactNodeArray} -
 */
const StatusBar: FC<{}> = () => {
  // -- TODO --
  // Use the StateType -> Solve problem with ts user state resolving
  // Get the user information from the redux store
  const user = useSelector((state: any) => state.user);

  return user && user.details ? (
    <StyledUserBar>
      <div>
        <StyledUsername>{`${user.details.firstName} ${user.details.lastName}`}</StyledUsername>
        <StyledCompany>{user.details.company}</StyledCompany>
      </div>
      <div>
        <WithDropdown user={user} />
      </div>
    </StyledUserBar>
  ) : null;
};

export default StatusBar;
