/*
 * Actions for dispatching events
 * */
import {
  ADCompanyroleActionTypes,
  GET_ADCOMPANYROLES,
} from 'store/adcompanyroles/types';

/*
 * Dispatch GET_ADCOMPANYROLES for fetching roles
 * */

export const getADCompanyRoles = (
  company_id: number
): ADCompanyroleActionTypes => ({
  type: GET_ADCOMPANYROLES,
  data: company_id,
});
