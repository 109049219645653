import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from 'store/types';
import { Step, StepDevider } from 'components/ui/Steps/Steps';
import { __RouterContext } from 'react-router';
import { useTranslation } from 'react-i18next';
import { changeStep } from 'store/flow/actions';
import { useFlowIsActive, useIsCurrentStep } from 'utils/hooks';
import { showNotification } from 'store/notification/actions';

const StyledStepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1em auto;
`;

const StepBar: FC<{}> = props => {
  const currentFlow = useSelector((state: StateType) => state.currentFlow);
  const { history, location } = useContext(__RouterContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFlowActive = useFlowIsActive();
  const isCurrentStep = useIsCurrentStep(location.pathname);

  const changeSite = (step: any): void => {
    if(!isFlowActive){
      dispatch(changeStep(step.id, history));
      history.push(step.action);
    } else {
      dispatch(
        showNotification({
          identifier: 'in-flow-action-warning',
          type: 'warning',
          text: `${t('common:flows.in_flow_warning')}`,
          autoClose: 3000,
        })
      );
    }
  };

  return (
    <>
      <h3>{currentFlow.isActive ? `${t(currentFlow.flow.name)}:` : ''}</h3>
      <StyledStepWrapper>
        {currentFlow.isActive
          ? currentFlow.flow.steps.map((step: any, index: number) => {
            return (
                <Step
                  clicked={() => changeSite(step)}
                  key={step.id + '_' + index}
                  active={step.active}
                  finished={step.status}
                  description={t(step.name)}
                  number={index + 1}
                  site={step.action}
                />
                /* {index < currentFlow.flow.steps.length - 1 ? (
                  <StepDevider key={'step_devider_' + index}></StepDevider>
                ) : (
                  true
                )} */
            );
          })
          : null}
      </StyledStepWrapper>
    </>
  );
};

export default StepBar;
