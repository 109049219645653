import React, {useContext} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StateType } from 'store/types';
import { ClassicSpinner } from 'react-spinners-kit';
import StyledSpinner from 'components/ui/Spinner/StyledSpinner';
import ModalContainer from 'container/CompanyDetails/ModalContainer';
import ConfirmProjectModalForm from 'container/Forms/ConfirmProjectModalForm';
import { validateWithCallback } from 'utils/form';

import { CardWrapper } from 'components/ui/Card/styles/CardStyles';
import {
  Card,
  CardActions,
  CardBody,
  CardFooter,
  CardHeader,
  EmptyCard,
} from 'components/ui/Card/Card';
import SelectCompanyForm from 'container/Projects/Forms/SelectCompanyForm';
import {
  ADProject,
  ADProjectResult,
  DELETE_ADPROJECT,
  GET_ADPROJECTS,
  EDIT_ADPROJECT,
} from 'store/adprojects/types';
import CreateProject from 'container/Forms/CreateADProjectForm';
import InspectProject from 'container/Projects/Forms/InspectProjectForm';
import {
  CREATE_ADPROJECT,
  SAVE_ADPROJECT,
  INSPECT_ADPROJECT,
} from 'store/adprojects/types';
import { showNotification } from 'store/notification/actions';
import { isArray, get } from 'lodash';
import Tag from 'components/ui/Tag/Tag';
import { __RouterContext } from 'react-router';

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector((state: StateType) => state.modal);
  const { loading, projects } = useSelector(
    (state: StateType) => state.adprojects
  );
  const { history } = useContext(__RouterContext);
  const project = useSelector((state: StateType) => state.adproject);
  const companies_s = useSelector((state: StateType) => state.adcompanies);

  const fetchProjects = value => {
    const ids =
      value.projects && isArray(value.projects) && value.projects.length > 0
        ? value.projects.map(comp => comp.value)
        : [];

    dispatch({
      type: GET_ADPROJECTS,
      data: ids,
    });
  };

  const deleteProject: any = (id: number) => {
    dispatch({
      type: DELETE_ADPROJECT,
      data: id,
    });
  };

  return (
    <>
      <h1>{t('projects:index.title')}</h1>
      <SelectCompanyForm
        onChange={value => {
          fetchProjects(value);
        }}
      ></SelectCompanyForm>
      <h2>{t('projects:overview.available_projects')}</h2>
      {loading && (
        <StyledSpinner>
          <ClassicSpinner size={40} />
        </StyledSpinner>
      )}
      <CardWrapper>
        {projects && !loading
          ? projects.map((project_: ADProject) => {
            return (
              <Card key={project_.id} type="default" alignment="center">
                <CardHeader
                  src={
                    project_.avatar_url && project_.avatar_url !== ''
                      ? project_.avatar_url
                      : 'https://via.placeholder.com/400x250'
                  }
                />
                <CardBody>
                  {project &&
                      project_.id === get(project, 'project.project.id') && (
                    <div className="tags__wrapper --with-tags">
                      <Tag isRole>{t('projects:overview.new')}</Tag>
                    </div>
                  )}
                  <h3>{project_.name}</h3>
                  <p>
                    {
                      companies_s.companies.find(
                        company => company.id == project_.company_id
                      ).name
                    }
                  </p>
                  <CardActions
                    header={t('projects:overview.actions')}
                    items={
                      project &&
                        project_.id === get(project, 'project.project.id')
                        ? [
                              {
                                label: t('projects:overview.delete'),
                            dispatch: () => {
                                  deleteProject(project_.id);
                            },
                              },
                          {
                                label: t('projects:overview.inspect'),
                            dispatch: () => {
                              dispatch({
                                type: INSPECT_ADPROJECT,
                              });
                                },
                          },
                        ]
                        : [
                              {
                            label: t('projects:overview.delete'),
                                dispatch: () => {
                                  deleteProject(project_.id);
                            },
                              },
                        ]
                    }
                    />
                </CardBody>
                <CardFooter
                  label={t('projects:overview.edit')}
                  as="button"
                  iconprefix="fal"
                  icon="chart-bar"
                  displayRightIcon={false}
                  onClick={ev => {
                    ev.preventDefault();
                    dispatch({
                      type: EDIT_ADPROJECT,
                      data: project_.id,
                    });
                  }}
                />
              </Card>
            );
          })
          : null}
        <EmptyCard
          icon="chart-bar"
          iconprefix="fal"
          label={t('common:modal.create_new_adproject')}
          onClick={event => {
            if (companies_s.companies && companies_s.companies.length > 0) {
              dispatch({
                type: 'TOGGLE_MODAL',
                selected: 'project',
              });
            } else {
              showNotification({
                identifier: 'projects-fetch-notification',
                type: 'error',
                text: t('companies:notification.no_companies'),
                autoClose: 2000,
              });
            }
            event.stopPropagation();
            event.preventDefault();
          }}
        />
      </CardWrapper>
      <ModalContainer
        identifier={'confirm-delete-project'}
        Component={ConfirmProjectModalForm}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              delete: 'required|equals:DELETE',
            },
            () => dispatch({ type: 'CONFIRM_DELETE' })
          )
        }
      />
      <ModalContainer
        identifier={'project'}
        Component={CreateProject}
        isEdit={modal.edit}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              name: 'required',
              description: 'required',
              company_id: 'required',
              avatar_url: 'string',
              slug: 'required',
              hash_id_length: 'number',
              load_balancing_group: 'required|string',
              schedules_enabled: 'boolean',
              setpoints_enabled: 'boolean',
              setpoints_min_priority: 'number',
            },
            () => {
              if (modal.edit) {
                dispatch({ type: SAVE_ADPROJECT, data: values });
              } else {
                dispatch({
                  type: CREATE_ADPROJECT,
                  data: values,
                  his: history,
                });
              }
            }
          )
        }
      />
      <ModalContainer
        identifier={'project-data'}
        Component={InspectProject}
        isEdit={modal.edit}
        onSubmit={() => {}}
      />
    </>
  );
};
