import {
  SHOW_NOTIFICATION,
  NotificationOptions,
  NotificationActionTypes,
} from 'store/notification/types';

export const showNotification = (
  options: NotificationOptions
): NotificationActionTypes => ({
  type: SHOW_NOTIFICATION,
  options: options,
});
