import styled from 'styled-components';

export const StyledTag = styled.div`
  display: inline-flex;
  font-size: 14px;
  border-radius: ${({ theme }) => theme.tag.borderRadius};
  background: ${({ theme }) => theme.tag.background};
  padding: ${({ theme }) => theme.tag.padding};
  margin: 0 2.5px;
`;
