import {
  CurrentFlowState,
  SET_CURRENT_FLOW,
  RESET_CURRENT_FLOW,
  UPDATE_CURRENT_FLOW,
  Flow,
  Step,
  CHANGE_CURRENT_STEP,
  FINISH_CURRENT_FLOW_STEP,
} from 'store/flow/types';
import { getFlow } from 'constants/flows';

const initialState: CurrentFlowState = {
  isActive: false,
};

function resetFlow(flow: Flow){
  let resettet_flow: Flow;
  resettet_flow = flow;
  resettet_flow.steps = flow.steps.map(step => {
    return {
      ...step,
      active: false,
      status: false
    }
  });

  return resettet_flow;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_CURRENT_FLOW:
      return {
        isActive: false,
        flow: state.flow ? resetFlow(state.flow) : undefined,
      };
    case SET_CURRENT_FLOW:
      const flow = resetFlow(getFlow(action.data));
      flow.steps[0].active = true;
      flow.currentStep = flow.steps[0];
      return {
        isActive: true,
        flow,
      };
    case CHANGE_CURRENT_STEP:
      const find_next_step = state.flow.steps.find(
        step => step.id === action.data
      );
      if (find_next_step) {
        const updated_flow = Object.assign({}, state.flow);
        const index = updated_flow.steps.findIndex(
          step => step.id === action.data
        );
        updated_flow.steps = updated_flow.steps.map(step => {
          return { ...step, active: false };
        });
        updated_flow.steps[index].active = true;
        updated_flow.currentStep = updated_flow.steps[index];

        return {
          isActive: true,
          flow: updated_flow,
        };
      } else {
        return state;
      }
    case FINISH_CURRENT_FLOW_STEP:
      // clone the current flow
      const updated_flow = Object.assign({}, state.flow);

      // update the finished step
      updated_flow.steps = updated_flow.steps.map(step => {
        if (step.id === action.data) {
          step.active = false;
          step.status = true;
        }
        return step;
      });

      const found_unfinished = updated_flow.steps.findIndex(
        step => step.status == false
      );
      if (found_unfinished === -1) {
        // finish the flow
        return {
          isActive: false,
        };
      } else {
        updated_flow.steps[found_unfinished].active = true;
        updated_flow.currentStep = updated_flow.steps[found_unfinished];
        // select the next step of the flow
        return {
          isActive: true,
          flow: updated_flow,
        };
      }
    default:
      return state;
  }
}
