import {
  ADCompaniesState,
  ADCompanyActionTypes,
  GET_ADCOMPANIES_FULFILLED,
  GET_ADCOMPANIES_PENDING,
} from 'store/companies/types';

const initialState: ADCompaniesState = {
  loading: true,
};

export default (state = initialState, action: ADCompanyActionTypes) => {
  switch (action.type) {
    case GET_ADCOMPANIES_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_ADCOMPANIES_FULFILLED:
      return {
        ...state,
        loading: false,
        companies: action.data,
      }
    default:
      return state;
  }
};
