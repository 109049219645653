import styled from 'styled-components';

export const StyledBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-image: ${({ theme }) =>
    `radial-gradient( 
    ${theme.gradientPurpel1[0]}, ${theme.gradientPurpel1[1]})`};

  ${({ centerContent }: { centerContent?: boolean }) =>
    centerContent
      ? `
    flex-direction: column;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
      `
      : ``};
`;
