import { filter } from 'lodash';

import {
  DISABLE_PASSWORD_CHANGE,
  GET_USER_FULFILLED,
  GET_USER_PENDING,
  GET_USER_PROJECTS_VIA_ROLES_FULFILLED,
  GET_USER_PROJECTS_VIA_ROLES_PENDING,
  SAVE_USER_FULFILLED,
  UPLOAD_USER_AVATAR_FULFILLED,
  UserActionTypes,
  UserState,
} from 'store/user/types';

import {
  CREATE_PROJECT_FULFILLED,
  DELETE_PROJECT_FULFILLED,
  ProjectActionTypes,
  SAVE_PROJECT_FULFILLED,
} from 'store/project/types';

const initialState: UserState = {
  loading: false,
};

// UserActionTypes
export default (
  state = initialState,
  action: UserActionTypes | ProjectActionTypes
) => {
  switch (action.type) {
    case GET_USER_PENDING:
      return {
        ...state,
        loading: true,
      };
    case SAVE_USER_FULFILLED:
      const data = action.data;

      return {
        ...state,
        details: Object.assign(state.details, data),
      };
    case GET_USER_FULFILLED:
      const user = {
        ...action.data['user'],
        company: action.data['company'].name,
        roles: action.data['roles'],
        companyRoles: action.data['companyroles'],
        passwordLockedUntil: action.data['password_locked_until'],
      };

      return {
        ...state,
        loading: false,
        details: user,
      };
    case DISABLE_PASSWORD_CHANGE:
      return {
        ...state,
        details: {
          ...state.details,
          passwordLockedUntil: true, // this should be a date, but we wont get one in the response?
        },
      };
    case GET_USER_PROJECTS_VIA_ROLES_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_PROJECTS_VIA_ROLES_FULFILLED:
      // @ts-ignore
      const normalized = action.data.reduce((acc, { data }) => {
        const role =
          state &&
          state.details &&
          state.details.roles.find(role => role.project_id === data.project.id);
        acc[data.project.id] = {
          name: data.project.name,
          description: data.project.description,
          company: data.company.name,
          role: role.name,
          role_description: role.description,
        };
        return acc;
      }, {});

      return {
        ...state,
        roles: normalized,
      };
    case CREATE_PROJECT_FULFILLED:
      const { project, role } = action.data;
      const newProjectRole = {
        name: project.name,
        description: project.description,
        company: action.companyName,
        role: role.name,
        role_description: role.description,
      };
      return {
        ...state,
        details: {
          ...state.details,
          roles: [...state.details.roles, newProjectRole],
        },
      };
    case DELETE_PROJECT_FULFILLED:
      return {
        ...state,
        details: {
          ...state.details,
          roles: filter(
            state.details.roles,
            item => item.project_id !== action.data.id
          ),
        },
      };
    case SAVE_PROJECT_FULFILLED:
      return {
        ...state,
        details: {
          ...state.details,
          roles: [
            ...state.details.roles,
            {
              id: action.data.id,
              name: action.data.name,
              description: action.data.description,
            },
          ],
        },
      };
    case UPLOAD_USER_AVATAR_FULFILLED:
      return {
        ...state,
        details: {
          ...state.details,
          avatar_url: action.data.src,
        },
      };
    default:
      return state;
  }
};
