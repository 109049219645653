import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import * as Routes from 'constants/routes';

import Background from 'components/ui/Background/Background';
import NavBar from 'components/ui/NavBar/NavBar';
import Dashboard from 'container/Dashboard/Dashboard';
import UserDetails from 'container/UserDetails/UserDetails';
import CompanyDetails from 'container/CompanyDetails/CompanyDetails';
import StatusBar from 'container/StatusBar/StatusBar';
import Panel from 'components/ui/Panel/Panel';
import StepBar from 'container/StepBar/StepBar';

/*
 * Company containers
 * */
import CompanyIndex from 'container/Companies/index';

/*
 * Project containers
 * */
import ProjectIndex from 'container/Projects/index';

/*
 * User containers
 * */
import UserIndex from 'container/Users/index';

// Container for the sidebar
const SidebarContainer = () => <div id="sidebar-container" />;

/**
 * This routing component contains all parts of the app which requires
 * authentication.
 *
 * @returns {ReactNodeArray} Background, NavBar Routes and if no Route matches a Redirect
 */
const AuthRouting: FC<{}> = () => (
  <Background>
    <NavBar />
    <SidebarContainer />

    <Panel>
      <StatusBar />
      <StepBar />
      <Switch>
        <Route exact path={Routes.BASE} component={Dashboard} />
        <Route path={Routes.USER_DETAILS} component={UserDetails} />
        <Route path={Routes.COMPANY_DETAILS} component={CompanyDetails} />
        <Route path={Routes.COMPANIES} component={CompanyIndex} />
        <Route path={Routes.PROJECTS} component={ProjectIndex} />
        <Route path={Routes.USERS} component={UserIndex} />
        <Redirect to={Routes.BASE} />
      </Switch>
    </Panel>
  </Background>
);

export default AuthRouting;
