import styled from 'styled-components';

export const StyledDropzone = styled.div`
  .dz-container {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 1px dashed #4b9de8;
    margin: 0 auto;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(245, 239, 239, 0.2);
    }

    .fa-file-upload {
      color: #4b9de8;
      margin-bottom: 10px;
    }

    > div {
      text-align: center;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      > .browse-hint {
        text-decoration: underline;
        color: #f2a055;
      }

      > .accpted-types-text {
        color: #acacac;
        font-size: 13px;
      }
    }
  }
`;

// -- TODO --
// Center image in div
export const StyledImageContainer = styled.div`
  position: relative;
  height: 200px;
  width: 200px;

  > img {
    height: 200px;
    width: 100%;
    border-radius: 50%;
  }

  > .dz-container {
    width: 50px;
    height: 50px;
    top: 70%;
    left: 75%;
    border-radius: 50%;
    position: absolute !important;
    background-color: #4b9de8;
    text-align: center;
    padding: 13px;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }

    .fa-pencil-alt {
      font-size: 25px;
    }
  }
`;
