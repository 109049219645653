import {
    CREATE_INITIALIZER, CreateInitializerAction, INITIALIZERS, CREATE_INITIALIZER_PENDING, CREATE_INITIALIZER_FULFILLED, CREATE_INITIALIZER_REJECTED,
} from 'store/initializers/types';

import { put, takeLatest } from '@redux-saga/core/effects';
import { showNotification } from 'store/notification/actions';
import {
    create_initialization,
  } from 'utils/adminAxiosInstance';

export function* createInitialization(action: CreateInitializerAction) {
    const initializer: INITIALIZERS = action.resource.initializer;
    const company_id: number = action.resource.company_id;
    const project_id: number = action.resource.project_id;
    yield put({type: CREATE_INITIALIZER_PENDING});
    const req = yield create_initialization(initializer, company_id, project_id);
    try {
        const res = yield req;
        yield put({
            type: CREATE_INITIALIZER_FULFILLED,
            response: res.data,
        });
        if(res.data.success) {
            yield put(
                showNotification({
                    identifier: 'initializer-failed-notification',
                    type: 'success',
                    text: res.data.message,
                    autoClose: 2000,
                })
            );
        }
    } catch (err) {
        yield put(
            showNotification({
                identifier: 'initializer-failed-notification',
                type: 'error',
                text: err.response.data.error,
                autoClose: 2000,
            })
        );
        yield put({ type: CREATE_INITIALIZER_REJECTED });
    }
}

export default function* initializersSaga() {
    yield takeLatest(CREATE_INITIALIZER, createInitialization);
};