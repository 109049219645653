import styled from 'styled-components';

export const FieldCol = styled.div`
  display: flex;

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`;

export const FieldFlexWidth = styled.div<{
  basis?: string;
  growNumber: number;
}>`
  flex-grow: ${({ growNumber }) => growNumber};
  ${({ basis }) => basis && `flex-basis: ${basis}`}
`;

export const AlignPicture = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 35px;
`;
