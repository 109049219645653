/**
 * Get a valid iso date string in the format YYYY-MM-DD. Optional
 * the function calculates the date from now to or past an given day.
 *
 * @param {number} [days] - integer days from now. (Negativ possible)
 * @return A valid ISOString date string in format YYYY-MM-DD
 */
export const getISODateString = (days?: number) => {
  let now = new Date();
  if (!days) return now.toISOString().slice(0, 10);
  let past = now.getDate() + days;
  now.setDate(past);
  return now.toISOString().slice(0, 10);
};
