import styled from 'styled-components';

export const StyledNavMain = styled.nav`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
  width: 100px;
  min-width: 100px;
  position: sticky;
  top: 0;
  height: 100vh;
  background-color: ${({ theme }) => theme.purple3};
  z-index: ${({ theme }) => theme.zIndexNavMain};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  > section:last-child {
    margin-top: auto;
  }
`;

export const StyledLogo = styled.img`
  padding: 15px 30px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
`;

export const StyledItem = styled.div`
  text-align: center;

  > a {
    padding: 40px 30px;
    display: block;
    text-decoration: none;
    color: ${({ theme }) => theme.purple5};

    > svg {
      font-size: ${({ theme }) => theme.fontSize0};
    }

    :hover:not(.active) {
      > svg {
        color: #fff;
      }
    }
  }

  .active {
    background-color: ${({ theme }) => theme.purple2};
    > svg {
      color: #fff;
    }
  }
`;
