import Oidc, { UserManager } from 'oidc-client';
import { Store } from 'redux';

import {
  userFound,
  userExpired,
  loadUserError,
  loadingUser,
} from 'store/auth/actions';

/**
 * Create and export an new oidc user manger instance
 *
 * @returns Oidc.UserManger.Instance
 */
export const userManager = new UserManager({
  response_type: 'token id_token',
  //response_mode: 'query',
  client_id: `${globalThis.config.OIDC_CLIENT_ID}`,
  redirect_uri: `${window.origin}/auth`,
  authority: `${globalThis.config.OIDC_AUTHORITY}`,
  post_logout_redirect_uri: `${window.origin}/login`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: false,
  clockSkew: 600,
  scope: 'openid profile roles',
});

/**
 * The loadUser function tries to load a valid oidc user
 * into the redux store. If any error occures it will disptach
 * an loadUserError action.
 *
 * @param {object} store - the redux root store
 */
export const loadUser = (store: Store) => {
  store.dispatch(loadingUser());

  // If the getUser() promise was succesfull try to load the user
  const success = (user: Oidc.User) => {
    if (user && !user.expired) {
      store.dispatch(userFound(user));
    } else if (!user || (user && user.expired)) {
      store.dispatch(userExpired());
      const OIDC_AUTHORITY = globalThis.config.OIDC_AUTHORITY;
      const OIDC_CLIENT_ID = globalThis.config.OIDC_CLIENT_ID;
      window.sessionStorage.removeItem(
        `oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`
      );
    }
    return user;
  };

  // If any error occures, dispatch a loadUserError() action
  const error = () => store.dispatch(loadUserError());

  return userManager
    .getUser()
    .then(success)
    .catch(error);
};
