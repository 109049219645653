import axios, { AxiosInstance } from 'axios';
import { select, put } from '@redux-saga/core/effects';
import { getAccessToken } from 'store/selectors/selectors';
import { INITIALIZERS } from 'src/store/initializers/types';

axios.interceptors.response.use(
  response => {
    return response;
  },
  function*(error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 403
    ) {
      // Missing administrator role on keycloak
      yield put({
        type: 'CRITICAL_ERROR_NOT_ADMIN',
        payload: {
          message: error.response.data.message,
          title: error.response.data.error,
        },
      });
    }
    return Promise.reject(error);
  }
);

/**
 * Configure and export an axios instance.
 *
 * @param {object} settings - additional header options like Bearer Token
 * @exports AxiosInstance
 */
export default (settings?: Record<string, string>) => {
  const headers: Record<string, string> = {
    'content-type': 'application/json',
  };

  if (settings && settings.token)
    headers.Authorization = `Bearer ${settings.token}`;

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: globalThis.config.API_ADMIN,
    headers,
  });

  return axiosInstance;
};

/*
 * Request arguments COMPANY
 * */
interface PostCompanyArguments {
  (
    name: string,
    description: string,
    slug: string,
    address?: string,
    avatar_url?: string,
    phone?: string,
  ): Generator;
}

interface PutCompanyArguments {
  (
    company_id: number,
    name?: string,
    description?: string,
    avatar_url?: string,
    address?: string,
    phone?: string
  ): Generator;
}

interface DeleteCompanyArguments {
  (company_id: number): Generator;
}

/*
 * Request arguments PROJECT
 * */
interface PostProjectArguments {
  (
    name: string,
    description: string,
    company_id: number,
    slug: string,
    hash_id_length?: number,
    load_balancing_group?: string,
    schedules_enabled?: boolean,
    setpoints_enabled?: boolean,
    setpoints_min_priority?: number,
    avatar_url?: string
  ): Generator;
}

interface PutProjectArguments {
  (
    project_id: number,
    name: string,
    description: string,
    company_id?: number,
    hash_id_length?: number,
    load_balancing_group?: string,
    schedules_enabled?: boolean,
    setpoints_enabled?: boolean,
    setpoints_min_priority?: number,
    avatar_url?: string
  ): Generator;
}

interface DeleteProjectArguments {
  (project_id: number): Generator;
}

/*
 * Request arguments USER
 * */
interface PostUserArguments {
  (
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    company_id: number,
    active?: boolean,
    address?: string,
    avatar_url?: string,
    company_roles?: Array<number>,
    phone?: string,
    project_roles?: Array<number>
  ): Generator;
}

interface PutUserArguments {
  (
    id: number,
    email?: string,
    firstName?: string,
    lastName?: string,
    password?: string,
    active?: boolean,
    address?: string,
    avatar_url?: string,
    company_id?: number,
    company_roles?: Array<number>,
    phone?: string,
    project_roles?: Array<number>
  ): Generator;
}

interface DeleteUserArguments {
  (user_id: number): Generator;
}

/**
 * Request arguments INITIALIZERS
 */
interface CreateInitializationArguments {
  (
    initializer: INITIALIZERS,
    company_id?: number,
    project_id?: number
  )
}

function* get_users(company_ids?: Array<number>) {
  const token = yield select(getAccessToken);
  const requests = [];
  if (company_ids) {
    for (const company_id of company_ids) {
      requests.push(
        yield axios.get('user', {
          baseURL: globalThis.config.API_ADMIN,
          params: {
            company_id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
    }
  } else {
    requests.push(
      yield axios.get('user', {
        baseURL: globalThis.config.API_ADMIN,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
  }

  return requests;
}

/*
 * All possible requests
 * */
function* get_companyroles(company_id: number) {
  const token = yield select(getAccessToken);
  return yield axios.get('company/roles', {
    baseURL: globalThis.config.API_ADMIN,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      company_id,
    },
  });
}

function* get_projectroles(project_id: number | undefined) {
  const token = yield select(getAccessToken);
  return axios.get('project/roles', {
    baseURL: globalThis.config.API_ADMIN,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      project_id: project_id ? project_id : null,
    },
  });
}

function* get_companies() {
  const token = yield select(getAccessToken);
  return yield axios.get('company', {
    baseURL: globalThis.config.API_ADMIN,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function* get_projects(company_ids: Array<number>) {
  const token = yield select(getAccessToken);
  const requests = [];
  for (const company_id of company_ids) {
    requests.push(
      yield axios.get('project', {
        baseURL: globalThis.config.API_ADMIN,
        params: {
          company_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
  }

  return requests;
}

function* get_company(company_id: number) {
  const token = yield select(getAccessToken);
  return yield axios.get(`company/${company_id}`, {
    baseURL: globalThis.config.API_ADMIN,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function* get_project(project_id: number) {
  const token = yield select(getAccessToken);
  return yield axios.get(`project/${project_id}`, {
    baseURL: globalThis.config.API_ADMIN,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function* get_user(user_id: number) {
  const token = yield select(getAccessToken);
  return yield axios.get(`user/${user_id}`, {
    baseURL: globalThis.config.API_ADMIN,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

const create_company: PostCompanyArguments = function*(
  name,
  description,
  slug,
  address = '',
  avatar_url = '',
  phone = '',
) {
  const token = yield select(getAccessToken);
  return yield axios.post(
    `company`,
    {
      name,
      description,
      address,
      avatar_url,
      phone,
      slug
    },
    {
      baseURL: globalThis.config.API_ADMIN,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const create_project: PostProjectArguments = function*(
  name,
  description,
  company_id,
  slug,
  hash_id_length = 8,
  load_balancing_group = '',
  schedules_enabled = false,
  setpoints_enabled = false,
  setpoints_min_priority = 1,
  avatar_url = ''
) {
  const token = yield select(getAccessToken);
  return yield axios.post(
    `company/project`,
    {
      name,
      description,
      slug: slug,
      hash_id_length: hash_id_length ? hash_id_length : undefined,
      load_balancing_group: load_balancing_group
        ? load_balancing_group
        : undefined,
      schedules_enabled: schedules_enabled ? schedules_enabled : undefined,
      setpoints_enabled: setpoints_enabled ? setpoints_enabled : undefined,
      setpoints_min_priority: setpoints_min_priority
        ? setpoints_min_priority
        : undefined,
      avatar_url: avatar_url ? avatar_url : undefined,
    },
    {
      baseURL: globalThis.config.API_ADMIN,
      params: {
        company_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const create_user: PostUserArguments = function*(
  email,
  firstName,
  lastName,
  password,
  company_id,
  active = true,
  address = '',
  avatar_url = '',
  company_roles = [],
  phone = '',
  project_roles = []
) {
  const token = yield select(getAccessToken);
  return yield axios.post(
    `company/user`,
    {
      firstName,
      lastName,
      email,
      password,
      avatar_url: avatar_url ? avatar_url : undefined,
      active,
      address,
      company_roles,
      project_roles,
      phone,
    },
    {
      baseURL: globalThis.config.API_ADMIN,
      params: {
        company_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

const create_initialization: CreateInitializationArguments = function*(
  initializer: INITIALIZERS,
  company_id?: null,
  project_id?: null,
) {
  const token = yield select(getAccessToken);
  return yield axios.post(
    `initializers`, {
      company_id,
      project_id,
    },
    {
      baseURL: globalThis.config.API_ADMIN,
      params: {
        initializer,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const put_company: PutCompanyArguments = function*(
  company_id,
  name = '',
  description = '',
  address = '',
  avatar_url = '',
  phone = ''
) {
  const token = yield select(getAccessToken);
  return yield axios.put(
    `company/${company_id}`,
    {
      name,
      description,
      address,
      avatar_url,
      phone,
    },
    {
      baseURL: globalThis.config.API_ADMIN,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const put_project: PutProjectArguments = function*(
  name,
  description,
  company_id,
  project_id,
  hash_id_length,
  load_balancing_group,
  schedules_enabled,
  setpoints_enabled,
  setpoints_min_priority,
  avatar_url
) {
  const token = yield select(getAccessToken);
  return yield axios.put(
    `project/${project_id}`,
    {
      name,
      description,
      company_id,
      hash_id_length,
      load_balancing_group,
      schedules_enabled,
      setpoints_enabled,
      setpoints_min_priority,
      avatar_url,
    },
    {
      baseURL: globalThis.config.API_ADMIN,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const put_user: PutUserArguments = function*(
  id,
  email,
  firstName,
  lastName,
  password,
  active,
  address,
  avatar_url,
  company_id,
  company_roles,
  phone,
  project_roles
) {
  const token = yield select(getAccessToken);
  return yield axios.put(
    `user/${id}`,
    {
      email,
      firstName,
      lastName,
      company_id,
      password: password,
      active,
      address,
      avatar_url,
      company_roles,
      project_roles,
      phone,
    },
    {
      baseURL: globalThis.config.API_ADMIN,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const delete_company: DeleteCompanyArguments = function*(company_id) {
  const token = yield select(getAccessToken);
  return yield axios.delete(`company/${company_id}`, {
    baseURL: globalThis.config.API_ADMIN,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const delete_project: DeleteProjectArguments = function*(project_id) {
  const token = yield select(getAccessToken);
  return yield axios.delete(`project/${project_id}`, {
    baseURL: globalThis.config.API_ADMIN,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const delete_user: DeleteUserArguments = function*(user_id) {
  const token = yield select(getAccessToken);
  return yield axios.delete(`company/user/${user_id}`, {
    baseURL: globalThis.config.API_ADMIN,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export {
  get_companyroles,
  get_projectroles,
  get_companies,
  get_projects,
  get_company,
  get_project,
  create_company,
  create_project,
  put_company,
  put_project,
  delete_company,
  delete_project,
  get_user,
  get_users,
  create_user,
  delete_user,
  put_user,
  create_initialization,
};
