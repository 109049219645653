import {
  PaginationState,
  PaginationActionTypes,
  SELECT_PAGE,
  PREV_PAGE,
  NEXT_PAGE,
  GET_PAGINATION_CONTEXT_PENDING,
  GET_PAGINATION_CONTEXT_FULFILLED,
  REMOVE_PAGINATION_CONTEXT,
} from 'store/pagination/types';

const initialState: PaginationState = {
  currentPage: 1,
  totalPages: 1,
  loading: false,
};

export default function(state = initialState, action: PaginationActionTypes) {
  switch (action.type) {
    case GET_PAGINATION_CONTEXT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_PAGINATION_CONTEXT_FULFILLED:
      return {
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        contextIdentifier: action.contextIdentifier,
        loading: false,
      };
    case SELECT_PAGE:
      if (action.pageNumber <= state.totalPages && action.pageNumber > 0) {
        return { ...state, currentPage: action.pageNumber };
      }
      return state;
    case PREV_PAGE:
      const prevPage = state.currentPage - 1;
      if (prevPage > 0) {
        return { ...state, currentPage: prevPage };
      }
      return state;
    case NEXT_PAGE:
      const nextPage = state.currentPage + 1;
      if (nextPage <= state.totalPages) {
        return { ...state, currentPage: nextPage };
      }
      return state;
    case REMOVE_PAGINATION_CONTEXT:
      return { currentPage: 1, totalPages: 1, loading: false };
    default:
      return state;
  }
}
