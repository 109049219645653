import { InitializerState, InitializerActionTypes, CREATE_INITIALIZER_PENDING, CREATE_INITIALIZER_FULFILLED } from "./types";

const initialState: InitializerState = {
    loading: false,
}

export default (state = initialState, action: InitializerActionTypes) => {
    switch(action.type) {
        case CREATE_INITIALIZER_PENDING:
            return {
                ...state,
                loading: true,
            }
        case CREATE_INITIALIZER_FULFILLED:
            return {
                ...state,
                loading: false,
                initializationResponse: action.response,
            }
        default:
            return state;
    }
}