import { omitBy, omit } from 'lodash';

import {
  GET_COMPANY_ROLES_PENDING,
  GET_COMPANY_ROLES_FULFILLED,
  GET_PROJECT_ROLES_PENDING,
  GET_PROJECT_ROLES_FULFILLED,
  RolesState,
  RolesActionTypes,
  CREATE_ROLE_FULFILLED,
  DELETE_COMPANY_ROLE_FULFILLED,
  SAVE_COMPANY_ROLE_FULFILLED,
} from 'store/roles/types';

import {
  CREATE_PROJECT_FULFILLED,
  DELETE_PROJECT_FULFILLED,
} from 'store/project/types';

const initialState: RolesState = {
  project_roles_loading: false,
  company_roles_loading: false,
};

// UserActionTypes
export default (state = initialState, action: RolesActionTypes) => {
  switch (action.type) {
    case GET_COMPANY_ROLES_PENDING:
      return {
        ...state,
        company_roles_loading: true,
      };
    case GET_COMPANY_ROLES_FULFILLED:
      return {
        ...state,
        company_roles_loading: false,
        company: action.data,
      };
    case GET_PROJECT_ROLES_PENDING:
      return {
        ...state,
        project_roles_loading: true,
      };
    case GET_PROJECT_ROLES_FULFILLED:
      return {
        ...state,
        project_roles_loading: false,
        project: action.data,
      };
    case CREATE_ROLE_FULFILLED:
      return {
        ...state,
        company: { ...state.company, [action.data.id]: action.data },
      };
    case CREATE_PROJECT_FULFILLED:
      return {
        ...state,
        project: { ...state.project, [action.data.role.id]: action.data.role },
      };
    case DELETE_PROJECT_FULFILLED:
      // we need to remove the project role ... immediately
      return {
        ...state,
        project: omitBy(
          state.project,
          // @ts-ignore
          value => value.project_id === action.data.id
        ),
      };
    case DELETE_COMPANY_ROLE_FULFILLED:
      return {
        ...state,
        company: omit(state.company, action.data.id),
      };
    case SAVE_COMPANY_ROLE_FULFILLED:
      return {
        ...state,
        company: {
          ...state.company,
          [action.data.id]: action.data,
        },
      };
    default:
      return state;
  }
};
