import styled from 'styled-components';

export const StyledBreadcrumbs = styled.span`
  font-size: ${({ theme }) => theme.fontSize1};
  font-family: ${({ theme }) => theme.fontFamilyBold};
  flex: 1;
  display: flex;
  align-self: center;
  justify-content: flex-start;
`;
