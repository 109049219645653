import styled from 'styled-components';

export const StyledModalContainer = styled.div`
  display: none;
  position: fixed;
  z-index: 7000;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.--opened {
    display: block;
  }

  & > #modal__container-content {
    display: flex;
    flex-direction: row-reverse;
  }

  .modal__child {
    display: none;
  }

  .modal__header {
    margin-bottom: 25px;
  }

  .modal__body {
    flex: 1;
    height: calc(100vh - 180px);
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 0 !important;
    }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  .modal__footer {
    padding: 20px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    bottom: 0px;
    background: ${({ theme }) => theme.purple1};
    display: flex;
    flex-direction: row-reverse;
  }

  &.--box.--opened #modal__container-content {
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .modal__child {
      border-radius: 5px;
      padding: 40px 30px;
      max-width: 550px;
      height: auto;
      right: auto;

      .modal__body {
        height: auto;
      }

      .modal__footer {
        padding: 10px 10px 0px 10px;
        position: static;
      }
    }
  }
`;

export const StyledModal = styled.div`
  display: none;
  flex: 1;
  max-width: 800px;
  padding: 20px;
  background: ${({ theme }) => theme.purple1};
  height: 100vh;
  position: relative;

  h1 {
    font-family: ${({ theme }) => theme.fontFamilyBold};
    margin: 0;

    svg {
      margin-right: 10px;
    }
  }
`;
