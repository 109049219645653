import React, { FC } from 'react';
import { reduce } from 'lodash';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { ModalBody, ModalFooter, ModalHeader } from 'components/ui/Modal/Modal';

import Field, {
  StyledReduxFormSelect,
  FieldRow,
  FormSeperator,
} from 'components/ui/Form/Form';
import { Button, Variant } from 'components/ui/Button/Button';
import { useSelector } from 'react-redux';
import { StateType } from 'src/store/types';
import { useIsEqualForm, useLoading } from 'utils/hooks';
import { CREATE_USER } from 'store/user/types';

// -- TODO --
// Remove the key index signature type. It´s just there to prevent an failure with the
// validate utils function
interface CreateUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  address?: string;
  phone?: string;
  [key: string]: string | number;
}

const CreateUserForm: FC<InjectedFormProps<CreateUserFormData>> = ({
  submitting,
  pristine,
  reset,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const { company: companyRoles } = useSelector(
    (state: StateType) => state.roles
  );

  const { company: companyProjects } = useSelector(
    (state: StateType) => state.projects
  );

  pristine = useIsEqualForm('user-create');

  const [loading, createUserLoading] = useLoading(CREATE_USER);

  const companyRolesOptions = reduce(
    companyRoles,
    (acc, value) => {
      return [...acc, { value: value['id'], label: value['name'] }];
    },
    []
  );

  const companyProjectsOptions = reduce(
    companyProjects,
    (acc, value) => {
      return [...acc, { value: value['id'], label: value['name'] }];
    },
    []
  );

  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader>
        <h1>
          <FontAwesomeIcon icon={['fal', 'user-plus']} fixedWidth />
          {t('common:modal.create_new_user')}
        </h1>
      </ModalHeader>
      <ModalBody>
        <FieldRow>
          <Field
            name="firstName"
            type="text"
            label={t('common:fields.firstName')}
          />
          <Field
            name="lastName"
            type="text"
            label={t('common:fields.lastName')}
          />
        </FieldRow>
        <FieldRow>
          <Field name="email" type="text" label={t('common:fields.email')} />
        </FieldRow>
        <FieldRow>
          <Field
            name="password"
            type="password"
            label={t('common:fields.password')}
          />
          <Field
            name="passwordConfirmation"
            type="password"
            label={t('common:fields.passwordConfirmation')}
          />
        </FieldRow>
        <FieldRow>
          <Field
            name="address"
            type="text"
            label={t('common:fields.address')}
          />
        </FieldRow>
        <FieldRow>
          <Field name="phone" type="text" label={t('common:fields.phone')} />
        </FieldRow>
        <FormSeperator />
        <FieldRow>
          <Field
            label={t('common:modal.company_roles')}
            name="roles"
            component={StyledReduxFormSelect}
            options={companyRolesOptions}
          />
        </FieldRow>
        <FieldRow>
          <Field
            label={t('common:modal.assign_to_projects')}
            name="projects"
            component={StyledReduxFormSelect}
            options={companyProjectsOptions}
          />
        </FieldRow>
      </ModalBody>
      {!pristine && (
        <ModalFooter>
          <Button
            type="submit"
            variant={Variant.Success}
            disabled={!(!pristine || submitting)}
            loading={createUserLoading}
          >
            {t('common:modal.save_user')}
          </Button>
          <Button
            type="button"
            variant={Variant.Default}
            disabled={pristine || submitting}
            onClick={reset}
          >
            {t('common:button.cancel')}
          </Button>
        </ModalFooter>
      )}
    </form>
  );
};

export default reduxForm<CreateUserFormData>({
  form: 'user-create',
  enableReinitialize: true,
})(CreateUserForm);
