import { ADCompany } from 'store/companies/types';

/*
 * Action Types
 * */
export const GET_ADCOMPANYROLES = 'GET_ADCOMPANYROLES';
export const GET_ADCOMPANYROLES_PENDING = 'GET_ADCOMPANYROLES_PENDING';
export const GET_ADCOMPANYROLES_FULFILLED = 'GET_ADCOMPANYROLES_FULFILLED';
export const GET_ADCOMPANYROLES_REJECTED = 'GET_ADCOMPANYROLES_REJECTED';

/*
 * Models
 * */
export interface ADCompanyrole {
  id: number;
  name: string;
  description: string;
  endpoints: Array<number>;
  protected: boolean;
  company: ADCompany;
}

/*
 * State Models
 * */
export interface ADCompanyrolesState {
  readonly loading: boolean;
  readonly roles: Array<ADCompanyrole>;
}

/*
 * Actions
 * */
interface GetADCompanyrolesAction {
  type: typeof GET_ADCOMPANYROLES;
  data: number; // company_id
}

interface GetADCompanyrolesPendingAction {
  type: typeof GET_ADCOMPANYROLES_PENDING;
}

interface GetADCompanyrolesFulfilledAction {
  type: typeof GET_ADCOMPANYROLES_FULFILLED;
  data: Array<ADCompanyrole>;
}

interface GetADCompanyrolesRejected {
  type: typeof GET_ADCOMPANYROLES_REJECTED;
}

/*
 * Export Types
 * */
export type ADCompanyroleActionTypes =
  | GetADCompanyrolesAction
  | GetADCompanyrolesPendingAction
  | GetADCompanyrolesFulfilledAction
  | GetADCompanyrolesRejected;
