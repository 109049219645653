import React, { FC } from 'react';
import {
  StyledListItem,
  StyledList,
} from 'components/ui/List/styles/ListStyles';

interface Props {
  media?: string;
  label?: string;
}

export const ListItem: FC<Props> = ({ media, label, children = null }) => (
  <StyledListItem>
    <div className="list-item__media">
      {media && <img src={media} alt="" />}
    </div>
    <div className="list-item__content">
      <div>{label}</div>
      {children && <div>{children}</div>}
    </div>
  </StyledListItem>
);

const StyledListContainer = ({ children }) => (
  <StyledList>{children}</StyledList>
);

export default StyledListContainer;
