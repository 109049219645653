import React, {useContext} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SelectCompanyForm from 'container/Users/Forms/SelectCompanyForm';
import {
  ADUser,
  CREATE_ADUSER,
  DELETE_ADUSER,
  EDIT_ADUSER,
  GET_ADUSERS,
  SAVE_ADUSER,
} from 'store/adusers/types';
import { isArray } from 'lodash';
import StyledSpinner from 'components/ui/Spinner/StyledSpinner';
import { ClassicSpinner } from 'react-spinners-kit';
import { StateType } from 'store/types';
import { CardWrapper } from 'components/ui/Card/styles/CardStyles';
import {
  Card,
  CardActions,
  CardBody,
  CardFooter,
  CardHeader,
  EmptyCard,
} from 'components/ui/Card/Card';
import Tag from 'components/ui/Tag/Tag';
import { showNotification } from 'store/notification/actions';
import ModalContainer from 'container/CompanyDetails/ModalContainer';
import ConfirmModalForm from 'container/Forms/ConfirmModalForm';
import { validateWithCallback } from 'utils/form';
import CreateUser from 'container/Forms/CreateADUserForm';
import { get } from 'lodash';
import { destroy } from 'redux-form';
import { __RouterContext } from 'react-router';

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector((state: StateType) => state.modal);
  const { loading, users } = useSelector((state: StateType) => state.adusers);
  const user = useSelector((state: StateType) => state.aduser);
  const companies_s = useSelector((state: StateType) => state.adcompanies);
  const { history } = useContext(__RouterContext);

  const fetchUsers = value => {
    const ids =
      value.companies && isArray(value.companies) && value.companies.length > 0
        ? value.companies.map(comp => comp.value)
        : [];

    dispatch({
      type: GET_ADUSERS,
      data: ids,
    });
  };

  const deleteUser: any = (id: number) => {
    dispatch({
      type: DELETE_ADUSER,
      data: id,
    });
  };

  return (
    <>
      <h1>{t('users:index.title')}</h1>
      <SelectCompanyForm
        onChange={value => {
          fetchUsers(value);
        }}
      ></SelectCompanyForm>
      <h2>{t('users:overview.available_users')}</h2>
      {loading && (
        <StyledSpinner>
          <ClassicSpinner size={40} />
        </StyledSpinner>
      )}
      <CardWrapper>
        {users && !loading
          ? users.map((user_: ADUser) => {
            return (
              <Card key={user_.id} type="avatar" alignment="center">
                <CardHeader
                  src={
                    user_.avatar_url && user_.avatar_url !== ''
                      ? user_.avatar_url
                      : 'https://via.placeholder.com/150x150'
                  }
                />
                <CardBody>
                  {user && user_.id === get(user, 'user.user.id') && (
                    <div className="tags__wrapper --with-tags">
                      <Tag isRole>{t('users:overview.new')}</Tag>
                    </div>
                  )}
                  <h3>
                      {user_.firstName} {user_.lastName}
                  </h3>
                  <p>{user_.email}</p>
                  <CardActions
                    header={t('users:overview.actions')}
                    items={[
                      {
                        label: t('users:overview.delete'),
                        dispatch: () => {
                          deleteUser(user_.id);
                        },
                      },
                    ]}
                  />
                </CardBody>
                <CardFooter
                  label={t('users:overview.edit')}
                  as="button"
                  iconprefix="fal"
                  icon="user-plus"
                  displayRightIcon={false}
                  onClick={ev => {
                    ev.preventDefault();
                    dispatch({
                      type: EDIT_ADUSER,
                      data: user_.id,
                    });
                  }}
                />
              </Card>
            );
          })
          : null}
        <EmptyCard
          icon="user-plus"
          iconprefix="fal"
          label={t('common:modal.create_new_user')}
          onClick={event => {
            dispatch(destroy('user-create'));
            if (companies_s.companies && companies_s.companies.length > 0) {
              dispatch({
                type: 'TOGGLE_MODAL',
                selected: 'user',
              });
            } else {
              showNotification({
                identifier: 'users-fetch-notification',
                type: 'error',
                text: t('companies:notification.no_companies'),
                autoClose: 2000,
              });
            }
            event.stopPropagation();
            event.preventDefault();
          }}
        />
      </CardWrapper>
      <ModalContainer
        identifier={'confirm'}
        Component={ConfirmModalForm}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              delete: 'required|equals:DELETE',
            },
            () => dispatch({ type: 'CONFIRM_DELETE' })
          )
        }
      />
      <ModalContainer
        identifier={'user'}
        Component={CreateUser}
        isEdit={modal.edit}
        onSubmit={values =>
          validateWithCallback(
            values,
            {
              firstName: 'required|string',
              lastName: 'required|string',
              phone: 'string',
              address: 'string',
              'active.ACTIVE': 'boolean',
              avatar_url: 'string',
              company_id: 'required|object',
              email: 'required',
              password: modal.edit ? 'string|min:8' : 'required|string|min:8',
              password_repeat: 'requiredIf:password|string|min:8|same:password',
              project_roles: 'array',
              company_roles: 'array',
            },
            () => {
              if (modal.edit) {
                dispatch({ type: SAVE_ADUSER, data: values });
              } else {
                dispatch({
                  type: CREATE_ADUSER,
                  data: values,
                  his: history,
                });
              }
            }
          )
        }
      />
    </>
  );
};
