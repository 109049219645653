import React, { FC, SyntheticEvent } from 'react';
import { modularScale, cover } from 'polished';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ClassicSpinner } from 'react-spinners-kit';

export enum Size {
  XS = 'xs',
  SM = 'sm',
  LG = 'lg',
  XL = 'xl',
}

export enum Variant {
  Confirmation = 'confirmation',
  Success = 'success',
  Default = 'default',
  Warning = 'warning',
  Light = 'light',
  Error = 'error',
  Outlined = 'outlined',
}

export enum Alignment {
  Left = 'left',
  Right = 'right',
}

interface Props {
  width?: string;
  variant?: Variant;
  alignment?: Alignment;
  scaleSize?: Size;
  padding?: string;
  shadow?: boolean;
  round?: boolean;
  border?: boolean;
  loading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  disabled?: HTMLButtonElement['disabled'];
  icon?: IconProp;
  onClick?: (
    event:
    | SyntheticEvent<HTMLButtonElement, Event>
    | SyntheticEvent<HTMLAnchorElement, Event>
  ) => void;
  href?: string;
  target?: HTMLAnchorElement['target'];
  style?: any;
}

/**
 * Return the background color
 * Maybe with a gradient if more then one color is given
 *
 * @param {(string|string[])} color- Valid css color format
 * @returns {string} Backgroug color or image as template literal
 */
const setBackgroundColor = (color: string | string[]) => {
  return Array.isArray(color) && color.length > 1
    ? `background-image: linear-gradient(to right, ${color[0]}, ${color[1]})`
    : `background-color: ${color}`;
};

/**
 * Calculate and return the scaled padding as valid css
 *
 * @param {string} padding - Actual size value(s) e.g. 10px
 * @param {Size} [scaleSize] - Scale size
 * @returns {string} Padding as template literal
 */
const setPadding = (padding: string, scaleSize?: Size) => {
  let multipler = parseFloat(modularScale(0));
  switch (scaleSize) {
    case 'xs':
      multipler = parseFloat(modularScale(-1));
      break;
    case 'sm':
      multipler = parseFloat(modularScale(-0.5));
      break;
    case 'lg':
      multipler = parseFloat(modularScale(1.25));
      break;
    case 'xl':
      multipler = parseFloat(modularScale(1.75));
      break;
    default:
      break;
  }
  const calculatedPadding = padding
    .split(' ')
    .map(prop => `${parseFloat(prop) * multipler}${prop.replace(/\d+/g, '')}`);
  return calculatedPadding.join(' ');
};

/**
 * Create and return the button apperiance as valid css
 *
 * @param {string} fontColor - Valid css color format as string
 * @returns {string} Button apperiance as template literal
 */
const setColorVariant = (fontColor: string) => `
  color: ${fontColor};
  
  > a {
    color: ${fontColor};
  }
  
  &:hover,
  &:active,
  &:focus {
    outline: none;
    &:after {
      background: rgba(0, 0, 0, .1);
    }
  }
`;

// ${({ width }: { width?: string }) => (width ? `width: ${width}` : ``)};
// is width used?
const StyledButton = styled.button`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  text-decoration: none;

  transition: ${props =>
    props.disabled
      ? 'none'
      : 'transform 0.3s, box-shadow 0.3s, background 0.3s'};

  opacity: ${props => (props.disabled ? '0.5' : '1.0')};

  > span {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
  }

  padding: ${({ theme }) => setPadding(theme.button.padding)};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  font-size: ${({ theme }) => theme.button.fontSize};
  border: ${({ theme }) => theme.button.border};

  &:after {
    content: '';
    transition: background 0.3s;
    background: transparent;
    ${cover()};
  }

  ${({ theme, variant = 'default' }: { theme: any; variant?: string }) =>
    setBackgroundColor(theme.button.variants[variant].background)};
  ${() => setColorVariant('#fff')};

  float: ${({ theme, alignment }: { theme: any; alignment?: string }) =>
    alignment};

  ${({
    theme,
    border = false,
    variant = 'default',
  }: {
    theme: any;
    border?: boolean;
    variant?: string;
  }) =>
    border && `border: 1px dashed ${theme.button.variants[variant].border};`};

  & > div {
    display: inline-flex;
    margin-right: 10px;
  }

  & > svg {
    margin-right: 10px;
    color: ${({
    theme,
    variant = 'default',
  }: {
    theme: any;
    variant?: string;
  }) => theme.button.variants[variant].border};
  }
`;

// use a tag instead of button/input
const StyledLink = StyledButton.withComponent('a');

/**
 * Styled button component
 *
 * @param {Object} props - Children, styling and an action
 * @returns {ReactNodeArray}  Styled button with wrapped children
 */
export const Button: FC<Props> = props => {
  const { children, icon, href, type, loading, disabled } = props;

  const content = (
    <>
      {icon && <FontAwesomeIcon icon={icon} fixedWidth />}
      {loading && <ClassicSpinner size={10} />} {children}
    </>
  );

  return href ? (
    <StyledLink {...props}>{content}</StyledLink>
  ) : (
    <StyledButton
      {...props}
      type={type ? type : 'button'}
      disabled={loading || disabled}
    >
      {content}
    </StyledButton>
  );
};
