import React, { FC, ReactNode } from 'react';
import { StyledTag } from 'components/ui/Tag/styles/TagStyles';

interface Props {
  isRole?: boolean;
  children?: string | ReactNode;
}

const Tag: FC<Props> = props => (
  <StyledTag {...props}>{props.children}</StyledTag>
);

export default Tag;
