import styled from 'styled-components';
import { rgba } from 'polished';

export const StyledTable = styled.div`
  .ReactTable {
    border: 0;
    background: ${({ theme }) => theme.purple2};

    *:focus {
      outline: none;
    }

    .rt-noData {
      display: none;
    }

    .rt-thead {
      background: rgba(0, 0, 0, 0.1);
      border-top-left-radius: ${({ theme }) => theme.table.borderRadius};
      border-top-right-radius: ${({ theme }) => theme.table.borderRadius};
      box-shadow: none !important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .rt-th {
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 1.5px;
        padding: 15px 20px;
        text-align: left;
        color: ${({ theme }) => rgba(theme.purple6, 0.6)};

        &.-sort-asc {
          box-shadow: inset 0 3px 0 0 ${({ theme }) => theme.purple6};
        }

        &.-sort-desc {
          box-shadow: inset 0 -3px 0 0 ${({ theme }) => theme.purple6};
        }
      }
    }

    .rt-tbody {
      border-bottom-left-radius: ${({ theme }) => theme.table.borderRadius};
      border-bottom-right-radius: ${({ theme }) => theme.table.borderRadius};

      .rt-tr {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      }

      .rt-td {
        padding: 15px 20px;
        font-size: 14px;
      }

      .rt-tr-group:last-child > .rt-tr {
        border-bottom: 0;
      }
    }

    .-pagination {
      box-shadow: none;
      border-top: 0;
      background: rgba(0, 0, 0, 0.1);
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 14px;

      .-previous,
      .-next {
        > button {
          color: #fff;
          background: transparent;
          &:hover {
            background: transparent;
          }
        }
      }

      .-next button {
        &:after {
          font-family: 'Font Awesome 5 Pro';
          font-weight: 300;
          content: '\\f340';
        }
      }

      .-previous button {
        &:after {
          font-family: 'Font Awesome 5 Pro';
          font-weight: 300;
          content: '\\f33e';
        }
      }

      .-pageJump input {
        background: rgba(255, 255, 255, 0.15);
        color: #fff;
        padding: 10px 3px 10px 13px;
        margin: 0 10px;
        border-radius: ${({ theme }) => theme.table.borderRadius};
      }

      select {
        background: rgba(255, 255, 255, 0.15);
        color: #fff;
      }
    }
  }

  & + button {
    margin-top: 25px;
  }
`;
