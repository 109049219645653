import React, { useLayoutEffect, FC } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Transition } from 'react-spring/renderprops.cjs';
import { isFunction } from 'lodash';

import { StateType } from 'store/types';
import {
  StyledModal,
  StyledModalContainer,
} from 'components/ui/Modal/styles/ModalStyles';

interface ModalProps {
  identifier: string;
}

const Modal: FC<ModalProps> = ({ children = null, identifier }) => {
  const modal = useSelector((state: StateType) => state.modal);

  const child = !isFunction(children) ? children : children(modal);

  return createPortal(
    <StyledModal className={`modal__child modal__identifier-${identifier}`}>
      {child}
    </StyledModal>,
    document.getElementById('modal__container-content')
  );
};

export const ModalContainer: FC = () => {
  const modal = useSelector((state: StateType) => state.modal);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    document.body.classList.remove('--modal-opened');
    if (modal.opened) {
      document.body.classList.add('--modal-opened');
    }
  }, [modal]);

  return (
    <StyledModalContainer
      id="modal__container"
      className={`${modal.opened && '--opened'} --${modal.style}`}
      onMouseDown={ev => {
        dispatch({ type: 'TOGGLE_MODAL', payload: { target: null } });
      }}
    >
      {modal.opened && (
        <>
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .modal__child.modal__identifier-${modal.selected} {
                display: block;
            }
        `,
            }}
          />
          <Transition
            items={modal}
            from={{ right: -800, opacity: 0.5 }}
            enter={{ right: 0, opacity: 1 }}
            leave={{ right: -800, opacity: 0.5 }}
          >
            {modal =>
              modal &&
              (props => (
                <style
                  dangerouslySetInnerHTML={{
                    __html: `.modal__child.modal__identifier-${modal.selected} { right: ${props.right}px; opacity: ${props.opacity} }`,
                  }}
                />
              ))
            }
          </Transition>
        </>
      )}
      <div id="modal__container-content" />
    </StyledModalContainer>
  );
};

export const ModalHeader: FC = ({ children }) => (
  <div className="modal__header">{children}</div>
);
export const ModalBody: FC = ({ children }) => (
  <div className="modal__body">{children}</div>
);
export const ModalFooter: FC = ({ children }) => (
  <div className="modal__footer">{children}</div>
);

// exports
export default Modal;
