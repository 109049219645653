import React, { Component } from 'react';
import { withTranslation, TransProps } from 'react-i18next';

import Background from 'components/ui/Background/Background';
import { connect } from 'react-redux';
import { StateType } from 'store/types';

interface State {
  hasError: boolean;
}

/**
 *
 */
class ErrorBoundary extends Component<
TransProps & {
    critical_error: boolean;
    critical_error_message?: string;
    critical_error_title?: string;
  },
State
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError || this.props.critical_error) {
      const { t } = this.props;
      return (
        <Background centerContent={true}>
          <h1>
            {this.props.critical_error_title
              ? this.props.critical_error_title
              : t('common:error_boundary:headline')}
          </h1>
          <p>
            {this.props.critical_error_message
              ? this.props.critical_error_message
              : t('common:error_boundary:message')}
          </p>
          <a href={`${window.location.origin}`}>
            {t('common:error_boundary:link')}
          </a>
        </Background>
      );
    }

    return this.props.children;
  }
}

export default connect((state: StateType) => ({
  critical_error: state.meta.critical_error,
  critical_error_message: state.meta.critical_error_message,
  critical_error_title: state.meta.critical_error_title,
}))(withTranslation()(ErrorBoundary));
