import {
  GET_ADPROJECTS,
  GET_ADPROJECTS_FULFILLED,
  GET_ADPROJECTS_PENDING,
  GET_ADPROJECTS_REJECTED,
  DELETE_ADPROJECT,
  GetADProjectsAction,
  DeleteADProjectAction,
  DELETE_ADPROJECT_PENDING,
  DELETE_ADPROJECT_FULFILLED,
  DELETE_ADPROJECT_REJECTED,
  CREATE_ADPROJECT_PENDING,
  CREATE_ADPROJECT_FULFILLED,
  CREATE_ADPROJECT_REJECTED,
  CREATE_ADPROJECT,
  EDIT_ADPROJECT_PENDING,
  EDIT_ADPROJECT_REJECTED,
  EDIT_ADPROJECT,
  INSPECT_ADPROJECT,
  SAVE_ADPROJECT,
  SAVE_ADPROJECT_PENDING,
  SAVE_ADPROJECT_FULFILLED,
  SAVE_ADPROJECT_REJECTED,
  QUICK_CREATE_PROJECT,
  EDIT_ADPROJECT_FULFILLED,
} from 'store/adprojects/types';

import {
  get_projects,
  get_project,
  put_project,
  create_project,
  delete_project
} from 'utils/adminAxiosInstance';

import { get } from 'lodash';

import { put, select, take, takeLatest } from '@redux-saga/core/effects';
import { showNotification } from 'store/notification/actions';
import i18next from 'i18next';
import { TOGGLE_MODAL } from 'store/modal/types';
import { change, destroy, initialize, reset } from 'redux-form';
import { StateType } from 'store/types';
import { finishStep } from 'store/flow/actions';
import { GET_ADPROJECTROLES } from '../adprojectroles/types';

export function* getADProjects(action: GetADProjectsAction) {
  const company_ids = action.data;

  yield put({ type: GET_ADPROJECTS_PENDING });
  const req = yield get_projects(company_ids);
  try {
    const res = yield req;
    let projectslist = [];
    res.map(response => {
      projectslist = projectslist.concat(response.data);
    });
    yield put({ type: GET_ADPROJECTS_FULFILLED, data: projectslist });
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'projects-fetch-notification',
        type: 'error',
        text: err.response.data.error, //i18next.t('companies:notification.company_fetch_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: GET_ADPROJECTS_REJECTED });
  }
}

export function* deleteADProject(action: DeleteADProjectAction) {
  const project_id = action.data;

  yield put({
    type: TOGGLE_MODAL,
    selected: 'confirm-delete-project',
    style: 'box',
  });
  yield take('CONFIRM_DELETE');
  yield put({ type: DELETE_ADPROJECT_PENDING });
  const req = yield delete_project(project_id);

  try {
    const res = yield req;
    yield put({ type: DELETE_ADPROJECT_FULFILLED, data: project_id });
    yield put(
      showNotification({
        identifier: 'project-delete-notification',
        type: 'success',
        text: i18next.t('projects:notification.project_delete_success'),
        autoClose: 2000,
      })
    );
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'project-delete-notification',
        type: 'error',
        text: err.response.data.error, //i18next.t('companies:notification.company_fetch_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: DELETE_ADPROJECT_REJECTED });
  }

  yield put(reset('confirm-delete'));
  yield put({ type: TOGGLE_MODAL, selected: 'confirm' });
}

export function* addADProject(action) {
  let { company_id } = action.data;
  const history = action.his;
  company_id = company_id.value;

  yield put({ type: CREATE_ADPROJECT_PENDING });

  const req = create_project(
    action.data.name,
    action.data.description,
    parseInt(company_id),
    action.data.slug,
    parseInt(action.data.hash_id_length),
    action.data.load_balancing_group,
    action.data.schedules_enabled,
    action.data.setpoints_enabled,
    parseInt(action.data.setpoints_min_priority),
    action.data.avatar_url
  );

  try {
    const res = yield req;
    yield put({
      type: CREATE_ADPROJECT_FULFILLED,
      data: res.data,
    });
    const currentFlow = yield select((state: StateType) => state.currentFlow);

    if (currentFlow.isActive) {
      yield put({ type: 'TOGGLE_MODAL', selected: 'project' });
      yield put(finishStep(currentFlow.flow.currentStep.id, history));
    } else {
      yield put(destroy('project-create'));
      yield put({ type: 'TOGGLE_MODAL', selected: 'project' });
      yield put({ type: INSPECT_ADPROJECT });
      yield put({ type: GET_ADPROJECTROLES });
    }

    yield put(
      showNotification({
        identifier: 'project-created-notification',
        type: 'success',
        text: i18next.t('projects:notification.project_created_success'),
        autoClose: 2000,
      })
    );
    yield put(
      showNotification({
        identifier: 'project-created-notification-additional',
        type: 'warning',
        text: i18next.t(
          'projects:notification.project_created_success_additional'
        ),
        autoClose: 6000,
      })
    );
  } catch (err) {
    const message: string = get(err, 'response.data.error', i18next.t(
      'projects:notification.project_created_failed'
    ) as string);
    yield put(
      showNotification({
        identifier: 'project-created-notification',
        type: 'error',
        text: message,
        autoClose: 10000,
      })
    );
    yield put({ type: CREATE_ADPROJECT_REJECTED });
  }
}

export function* getADProject(action) {
  const project_id = action.data;

  yield put({ type: EDIT_ADPROJECT_PENDING });
  const req = yield get_project(project_id);

  try {
    const res = yield req;
    const company = yield select((state: StateType) =>
      state.adcompanies.companies.find(
        company => company.id === res.data.company_id
      )
    );
    res.data.company_id = { label: company.name, value: company.id };
    yield put(initialize('project-create', res.data));
    yield put(change('project-create', 'company_id', res.company_id));
    yield put({ type: 'TOGGLE_MODAL', selected: 'project', edit: true });
    yield put({ type: EDIT_ADPROJECT_FULFILLED, data: res.data });
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'project-edit-notification',
        type: 'error',
        text: err.response.data.error, //i18next.t('companies:notification.company_fetch_failed'),
        autoClose: 2000,
      })
    );
    yield put({ type: EDIT_ADPROJECT_REJECTED });
  }
}

export function* inspectADProject(action) {
  const adproject = yield select((state: StateType) => state.adproject);

  if (adproject.project) {
    yield put(initialize('project-inspect', adproject.project));
    yield put({
      type: 'TOGGLE_MODAL',
      selected: 'project-data',
      edit: true,
    });
  } else {
    yield put(
      showNotification({
        identifier: 'project-inspect-notification',
        type: 'error',
        text: i18next.t('projects:overview.not_found'),
        autoClose: 2000,
      })
    );
  }
}

export function* saveADProject(action) {
  const values = yield select(state =>
    get(state, 'form.project-create.values', {})
  );
  const {
    id,
    name,
    description,
    slug,
    avatar_url,
    hash_id_length,
    load_balancing_group,
    schedules_enabled,
    setpoints_enabled,
    setpoints_min_priority,
  } = values;
  const company_id = values.company_id.value;
  yield put({ type: SAVE_ADPROJECT_PENDING });

  const req = yield put_project(
    name,
    description,
    company_id,
    parseInt(id),
    parseInt(hash_id_length),
    load_balancing_group,
    schedules_enabled,
    setpoints_enabled,
    parseInt(setpoints_min_priority),
    avatar_url
  );

  try {
    const res = yield req;
    yield put(
      showNotification({
        identifier: 'project-saved-notification',
        type: 'success',
        text: i18next.t('projects:notification.project_updated_success'),
        autoClose: 2000,
      })
    );
    yield put({ type: SAVE_ADPROJECT_FULFILLED, data: res.data });
    yield put(reset('project-create'));
    yield put({ type: 'TOGGLE_MODAL', selected: 'project' });
  } catch (err) {
    yield put(
      showNotification({
        identifier: 'project-saved-notification',
        type: 'error',
        text: err.response.data.error,
        autoClose: 2000,
      })
    );
    yield put({ type: SAVE_ADPROJECT_REJECTED });
  }
}

export function* redirectAndCreate(action) {
  const { history } = action.data;

  /**
   * Redirect to companies index-view
   */
  history.push('projects');

  /**
   * Clear the company-create form
   */
  yield put(destroy('project-create'));

  /**
   * Open the corresponding creation-modal
   */
  yield put({ type: TOGGLE_MODAL, selected: 'project', edit: false });
}

export default function* adprojectsSaga() {
  yield takeLatest(GET_ADPROJECTS, getADProjects);
  yield takeLatest(DELETE_ADPROJECT, deleteADProject);
  yield takeLatest(CREATE_ADPROJECT, addADProject);
  yield takeLatest(EDIT_ADPROJECT, getADProject);
  yield takeLatest(INSPECT_ADPROJECT, inspectADProject);
  yield takeLatest(SAVE_ADPROJECT, saveADProject);
  yield takeLatest(QUICK_CREATE_PROJECT, redirectAndCreate);
}
